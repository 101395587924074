import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../../store';
import { Box, Typography, Grid, Paper, useTheme, Accordion, AccordionSummary, AccordionDetails, Link, LinearProgress, Button, IconButton, Tooltip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { fetchContentOfCourse, setSelectedVideoDetails } from '../../../features/coursesSlice';
import { convertNumberToTime, handleTimes } from '../../searchComponents/UtilityFunctions';
import { useVideoProgress } from '../videoPlayer/VideoProgressContext';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

interface Video {
  id: string;
  subject: string;
  summary: string;
  length: string;
  startTime: number;
  endTime: number;
}

interface MainVideo extends Video {
  subVideos: Video[];
}

interface TableOContentProps {
  courseId: string;
  onMoviesLoaded: (movies: MainVideo[]) => void;
  finishedSubVideos: { [key: string]: string[] };
  onQuizButtonClick: (quizData: any) => void;
  onVideoButtonClick: (videoUrl: string, videoData: any) => void;
  setCurrentlyPlayingSubVideoFromLayout?: (movieId: string, subVideoId: string) => void;
}

const TableOContentComponent: React.FC<TableOContentProps> = ({ courseId, onMoviesLoaded, finishedSubVideos, onQuizButtonClick, onVideoButtonClick, setCurrentlyPlayingSubVideoFromLayout }) => {
  const dispatch = useDispatch<AppDispatch>();
  const contentOfCourse = useSelector((state: RootState) => state.courses.contentOfCourse);
  const theme = useTheme();
  const [expandedSummaries, setExpandedSummaries] = useState<{ [key: string]: boolean }>({});
  const { videoProgress, currentlyPlayingSubVideo, setCurrentlyPlayingSubVideo } = useVideoProgress();
  const [selectedQuizVideo, setSelectedQuizVideo] = useState<string | null>(null);
  const [expandedAccordion, setExpandedAccordion] = useState<string | false>(false);
  const activeVideoRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (courseId) {
      dispatch(fetchContentOfCourse(courseId));
    }
  }, [courseId]);

  useEffect(() => {
    if (contentOfCourse) {
      const sortedContentOfCourse = contentOfCourse ? [...contentOfCourse].sort((a, b) => a.subject.localeCompare(b.subject)) : [];
      const videos = sortedContentOfCourse.flatMap((mainVideo: any) =>
        mainVideo.content.map((subVideo: any) => ({
          ...subVideo,
        }))
      );
      onMoviesLoaded(videos);
    }
  }, [contentOfCourse]);

  useEffect(() => {
    if (currentlyPlayingSubVideo) {
      setExpandedAccordion(currentlyPlayingSubVideo.movieId);
      if (activeVideoRef.current) {
        activeVideoRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [currentlyPlayingSubVideo]);

  const handleVideoSelect = (subVideo: any) => {
    setSelectedQuizVideo(null);
    onVideoButtonClick(subVideo.url, subVideo);
  };

  const handleSubVideoSelect = (subVideo: any, movieId: string, id: string, event: React.MouseEvent) => {
    event.stopPropagation();
    handleVideoSelect(subVideo);
    dispatch(setSelectedVideoDetails({ movieId, id, courseId: courseId ? courseId : "" }));
  };

  const handleToggleSummary = (id: string) => {
    setExpandedSummaries(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  const handleQuizButtonClick = (quizData: any, movieId: string, id: string, event: React.MouseEvent) => {
    event.stopPropagation();
    dispatch(setSelectedVideoDetails({ movieId, id, courseId: courseId ? courseId : "" }));
    setCurrentlyPlayingSubVideo({ movieId: movieId, subVideoId: id });
    onQuizButtonClick(quizData);
    setSelectedQuizVideo(id);
  };

  const calculateMainMovieProgress = (mainVideo: any) => {
    const subVideos = mainVideo.content;
    const totalProgress = subVideos.reduce((acc: number, subVideo: any) => {
      const subVideoProgress = videoProgress[mainVideo.movieId]?.[subVideo.id] || 0;
      return acc + subVideoProgress;
    }, 0);
    return subVideos.length ? totalProgress / subVideos.length : 0;
  };

  const formatTimeOrPages = (subVideo: any) => {
    if (subVideo.startTime && subVideo.endTime) {
      return handleTimes(subVideo.startTime, subVideo.endTime, "-1");
    }
    else if (subVideo.startPageNumber && subVideo.endPageNumber) {
      const startToEnd = `${subVideo.startPageNumber} - ${subVideo.endPageNumber}`;
      const numberOfPages = subVideo.endPageNumber - subVideo.startPageNumber;
      return `pages from to:  ${startToEnd} | number of pages: ${numberOfPages + 1}`;
    }
    return '';
  };

  const isHebrew = (text: string) => /[\u0590-\u05FF\uFB1D-\uFB4F\uFB50-\uFDFF]/.test(text);
  const borderColor = theme.palette.mode === 'dark' ? 'white' : 'black';
  const sortedContentOfCourse = contentOfCourse ? [...contentOfCourse].sort((a, b) => a.subject.localeCompare(b.subject)) : [];

  return (
    <Box sx={{ flexGrow: 1, p: 1, marginTop: '2%', width: '92%', marginBottom: '10%' }}>
      <Grid container spacing={1}>
        {sortedContentOfCourse.map((mainVideo) => {
          const isCurrentlyPlayingMainMovie = mainVideo.content.some(
            (subVideo: any) =>
              currentlyPlayingSubVideo?.movieId === mainVideo.movieId &&
              currentlyPlayingSubVideo?.subVideoId === subVideo.id
          );

          const mainMovieProgress = calculateMainMovieProgress(mainVideo);

          return (
            <Grid item xs={12} key={mainVideo.movieId}>
              <Accordion
                expanded={expandedAccordion === mainVideo.movieId}
                onChange={() => setExpandedAccordion(expandedAccordion === mainVideo.movieId ? false : mainVideo.movieId)}
                sx={{ border: '1px solid', borderColor: borderColor }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: '97%',
                  }}
                >
                  <Paper
                    sx={{
                      width: '100%',
                      p: 1,
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      backgroundColor: isCurrentlyPlayingMainMovie ? 'lightgreen' : theme.palette.background.paper,
                    }}
                  >
                    <VideoLibraryIcon color="action" />
                    <Box sx={{ ml: 1, width: '100%' }}>
                      <Typography sx={{ fontSize: '0.9rem', direction: isHebrew(mainVideo.subject) ? 'rtl' : 'ltr' }} variant="body1">{mainVideo.subject}</Typography>
                      <Box sx={{}}>
                        <Typography
                          sx={{
                            fontSize: '0.7rem',
                            direction: isHebrew(mainVideo.summary) ? 'rtl' : 'ltr',
                            display: expandedSummaries[mainVideo.movieId] ? 'block' : '-webkit-box',
                            overflow: expandedSummaries[mainVideo.movieId] ? 'visible' : 'hidden',
                            WebkitLineClamp: expandedSummaries[mainVideo.movieId] ? 'none' : 3,
                            WebkitBoxOrient: 'vertical'
                          }}
                          variant="body2"
                          color="text.secondary"
                        >
                          {mainVideo.summary}
                        </Typography>
                        {!expandedSummaries[mainVideo.movieId] && (
                          <Link component="button" variant="body2" onClick={() => handleToggleSummary(mainVideo.movieId)}>
                            More
                          </Link>
                        )}
                        {expandedSummaries[mainVideo.movieId] && (
                          <Link component="button" variant="body2" onClick={() => handleToggleSummary(mainVideo.movieId)}>
                            Less
                          </Link>
                        )}
                      </Box>
                      <Typography sx={{ fontWeight: 'bold', fontSize: '0.7rem' }} variant="body2" color="text.secondary">Length: {convertNumberToTime(mainVideo.length)}</Typography>
                      {Math.round(mainMovieProgress) === 100 && <CheckCircleIcon sx={{ color: 'green', marginLeft: '5px' }} />}
                      <Box sx={{ position: 'relative', marginTop: '5px', width: '100%' }}>
                        <LinearProgress
                          variant="determinate"
                          value={mainMovieProgress}
                          sx={{
                            height: '20px',
                            borderRadius: '5px',
                            '& .MuiLinearProgress-bar': {
                              borderRadius: '5px',
                            }
                          }}
                        />
                        <Typography
                          variant="body2"
                          sx={{
                            position: 'absolute',
                            top: 0,
                            left: '50%',
                            transform: 'translateX(-50%)',
                            color: 'white',
                            fontWeight: 'bold',
                            fontSize: '0.75rem'
                          }}
                        >
                          {`${Math.round(mainMovieProgress)}%`}
                        </Typography>
                      </Box>
                    </Box>
                  </Paper>
                </AccordionSummary>
                <AccordionDetails key={mainVideo.movieId}>
                  <Box sx={{ width: '100%' }}>
                    {mainVideo.content.map((subVideo: any) => (
                      <Paper
                        key={mainVideo.movieId + "-" + subVideo.id + subVideo.numberOfPages ? subVideo.numberOfPages : ""}
                        sx={{
                          width: '100%',
                          p: 1,
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                          my: 1,
                          ':hover': { transform: 'translateX(10px)', transition: 'transform 0.3s ease-in-out' },
                          backgroundColor: currentlyPlayingSubVideo?.movieId === mainVideo.movieId && currentlyPlayingSubVideo?.subVideoId === subVideo.id ? 'lightgreen' : theme.palette.background.default,
                          border: '1px solid',
                          borderColor: borderColor,
                        }}
                        onClick={(e) => handleSubVideoSelect(subVideo, mainVideo.movieId, subVideo.id, e)}
                        ref={currentlyPlayingSubVideo?.movieId === mainVideo.movieId && currentlyPlayingSubVideo?.subVideoId === subVideo.id ? activeVideoRef : null}
                      >
                        <VideoLibraryIcon color="action" />
                        <Box sx={{ ml: 1 }}>
                          <Typography sx={{ fontSize: '0.9rem', direction: isHebrew(subVideo.subject) ? 'rtl' : 'ltr' }} variant="body1">{subVideo.subject}</Typography>
                          <Typography sx={{ fontSize: '0.7rem', direction: isHebrew(subVideo.summary) ? 'rtl' : 'ltr' }} variant="body2" color="text.secondary">{subVideo.summary}</Typography>
                          <Box sx={{ margin: '10px' }}>
                            {subVideo.subSubject && subVideo.subSubject.split(',').map((sentence: string, sentenceIndex: number) => (
                              <Typography
                                key={sentenceIndex}
                                variant="subtitle2"
                                sx={{
                                  direction: isHebrew(sentence) ? 'rtl' : 'ltr',
                                  marginLeft: isHebrew(sentence) ? '' : '20px',
                                  marginRight: isHebrew(sentence) ? '20px' : '',
                                }}
                              >
                                {`${sentenceIndex + 1}. ${sentence.trim()}`}
                              </Typography>
                            ))}
                          </Box>
                          <Typography sx={{ fontWeight: 'bold', fontSize: '0.7rem' }} variant="body2" color="text.secondary">{formatTimeOrPages(subVideo)} </Typography>
                          {finishedSubVideos[mainVideo.movieId]?.includes(subVideo.id) && <CheckCircleIcon sx={{ color: 'green', marginLeft: '5px' }} />}
                          <Box sx={{ position: 'relative', marginTop: '5px' }}>
                            <LinearProgress
                              variant="determinate"
                              value={videoProgress[mainVideo.movieId]?.[subVideo.id] || 0}
                              sx={{
                                height: '20px',
                                borderRadius: '5px',
                                '& .MuiLinearProgress-bar': {
                                  borderRadius: '5px',
                                }
                              }}
                            />
                            <Typography
                              variant="body2"
                              sx={{
                                position: 'absolute',
                                top: 0,
                                left: '50%',
                                transform: 'translateX(-50%)',
                                color: 'white',
                                fontWeight: 'bold',
                                fontSize: '0.75rem'
                              }}
                            >
                              {`${Math.round(videoProgress[mainVideo.movieId]?.[subVideo.id] || 0)}%`}
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Tooltip title="Play Video">
                              <IconButton onClick={(e) => handleVideoSelect(subVideo)}>
                                <PlayArrowIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Take Quiz">
                              <IconButton onClick={(e) => handleQuizButtonClick({ ...subVideo, articleId: mainVideo.movieId, topicNumber: subVideo.id }, mainVideo.movieId, subVideo.id, e)}>
                                <AssignmentIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Box>
                      </Paper>
                    ))}
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default TableOContentComponent;
