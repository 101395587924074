import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, Paper, Button } from '@mui/material';
import CourseDropdown from './CourseDropdown';
import TableOfContentData from './TableOfContentData';
import { Course, Topic } from './tableTypes';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { fetchCourseTOCManagementData } from '../../../features/coursesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../../store';

const mockCourses: Course[] = [
  {
    courseId: 'Disinformation',
    courseName: 'Disinformation',
    courseDescription: 'Disinformation',
    courseImage: 'Disinformation',
  },
  {
    courseId: 'test-course-python-full-1',
    courseName: 'test-course-python-full-1',
    courseDescription: 'Disinformation',
    courseImage: 'Disinformation',
  },
];

const ManagementTocPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const tocManagementData = useSelector((state: RootState) => state.courses.tocManagementData);
  const [selectedCourseId, setSelectedCourseId] = useState<string>('');
  const [movies, setMovies] = useState<any[]>([]);
  const [changes, setChanges] = useState<any>({});

  useEffect(() => {
    dispatch(fetchCourseTOCManagementData(selectedCourseId));
  }, [dispatch, selectedCourseId]);

  useEffect(() => {
    setMovies(tocManagementData);
  }, [tocManagementData]);

  const handleCourseChange = (courseId: string) => {
    setSelectedCourseId(courseId);
  };

  const updateChanges = (movieId: string, topicNumber: number, key: string, value: any) => {
    setChanges((prevChanges: any) => {
      const movieChanges = prevChanges[movieId] || {};
      const topicChanges = movieChanges[topicNumber] || {};
      return {
        ...prevChanges,
        [movieId]: {
          ...movieChanges,
          [topicNumber]: {
            ...topicChanges,
            [key]: value,
          },
        },
      };
    });
  };

  const handleTopicNameChange = (movieId: string, topicNumber: number, newName: string) => {
    setMovies((prevMovies) =>
      prevMovies.map((movie) =>
        movie.movieId === movieId
          ? {
            ...movie,
            mediaTopicBreakdown: movie.mediaTopicBreakdown.map((topic: any) =>
              topic.topicNumber === topicNumber ? { ...topic, topic: newName } : topic
            ),
          }
          : movie
      )
    );
    updateChanges(movieId, topicNumber, 'topic', newName);
  };

  const handleSummaryChange = (movieId: string, topicNumber: number, newSummary: string) => {
    setMovies((prevMovies) =>
      prevMovies.map((movie) =>
        movie.movieId === movieId
          ? {
            ...movie,
            mediaTopicBreakdown: movie.mediaTopicBreakdown.map((topic: any) =>
              topic.topicNumber === topicNumber ? { ...topic, summary: newSummary } : topic
            ),
          }
          : movie
      )
    );
    updateChanges(movieId, topicNumber, 'summary', newSummary);
  };

  const handleKeyPointChange = (movieId: string, topicNumber: number, index: number, newKeyPoint: string) => {
    setMovies((prevMovies) =>
      prevMovies.map((movie) =>
        movie.movieId === movieId
          ? {
            ...movie,
            mediaTopicBreakdown: movie.mediaTopicBreakdown.map((topic: any) =>
              topic.topicNumber === topicNumber
                ? {
                  ...topic,
                  keyPoints: topic.keyPoints.map((kp: any, i: number) =>
                    i === index ? { ...kp, keyPoint: newKeyPoint } : kp
                  ),
                }
                : topic
            ),
          }
          : movie
      )
    );
    updateChanges(movieId, topicNumber, `keyPoints.${index}`, newKeyPoint);
  };

  const handleKeyPointTopicNumberChange = (movieId: string, oldTopicNumber: number, index: number, newTopicNumber: number) => {
    setMovies((prevMovies) => {
      const movieIndex = prevMovies.findIndex((movie) => movie.movieId === movieId);
      if (movieIndex === -1) return prevMovies;

      const movie = prevMovies[movieIndex];
      const sourceTopicIndex = movie.mediaTopicBreakdown.findIndex((topic: any) => topic.topicNumber === oldTopicNumber);
      const targetTopicIndex = movie.mediaTopicBreakdown.findIndex((topic: any) => topic.topicNumber === newTopicNumber);

      if (sourceTopicIndex !== -1 && targetTopicIndex !== -1) {
        const sourceTopic = movie.mediaTopicBreakdown[sourceTopicIndex];
        const targetTopic = movie.mediaTopicBreakdown[targetTopicIndex];

        const keyPoint = sourceTopic.keyPoints[index];

        // Remove key point from source topic
        const updatedSourceKeyPoints = sourceTopic.keyPoints.filter((_: any, i: number) => i !== index);

        // Add key point to target topic
        const updatedTargetKeyPoints = [...targetTopic.keyPoints, { ...keyPoint, topicNumber: newTopicNumber }];

        const updatedMediaTopicBreakdown = [...movie.mediaTopicBreakdown];
        updatedMediaTopicBreakdown[sourceTopicIndex] = { ...sourceTopic, keyPoints: updatedSourceKeyPoints };
        updatedMediaTopicBreakdown[targetTopicIndex] = { ...targetTopic, keyPoints: updatedTargetKeyPoints };

        const updatedMovies = [...prevMovies];
        updatedMovies[movieIndex] = { ...movie, mediaTopicBreakdown: updatedMediaTopicBreakdown };

        updateChanges(movieId, oldTopicNumber, 'keyPoints', updatedSourceKeyPoints);
        updateChanges(movieId, newTopicNumber, 'keyPoints', updatedTargetKeyPoints);

        return updatedMovies;
      }
      return prevMovies;
    });
  };

  const moveSentence = (movieId: string, sentenceId: string, targetTopicNumber: number) => {
    const sentence = findSentenceById(movieId, sentenceId);
    if (sentence) {
      setMovies((prevMovies) => {
        const movieIndex = prevMovies.findIndex((movie) => movie.movieId === movieId);
        if (movieIndex === -1) return prevMovies;

        const movie = prevMovies[movieIndex];
        const sourceTopicIndex = movie.mediaTopicBreakdown.findIndex((topic: any) =>
          topic.Sentence.find((s: any) => s.id === sentenceId)
        );
        const targetTopicIndex = movie.mediaTopicBreakdown.findIndex((topic: any) => topic.topicNumber === targetTopicNumber);

        if (sourceTopicIndex === targetTopicIndex) return prevMovies;
        if (sourceTopicIndex !== -1 && targetTopicIndex !== -1) {
          const sourceTopic = movie.mediaTopicBreakdown[sourceTopicIndex];
          const targetTopic = movie.mediaTopicBreakdown[targetTopicIndex];

          // Remove sentence from source topic
          const updatedSourceSentences = sourceTopic.Sentence.filter((s: any) => s.id !== sentenceId);

          // Add sentence to target topic
          const updatedTargetSentences = [...targetTopic.Sentence, sentence];

          // Sort sentences by start time
          const sortedSourceSentences = updatedSourceSentences.sort((a: any, b: any) => parseFloat(a.timeStart) - parseFloat(b.timeStart));
          const sortedTargetSentences = updatedTargetSentences.sort((a: any, b: any) => parseFloat(a.timeStart) - parseFloat(b.timeStart));

          // Recalculate start and end times for source topic
          const sourceStartTopicTime = sortedSourceSentences.length > 0
            ? Math.min(...sortedSourceSentences.map((s: any) => parseFloat(s.timeStart))).toString()
            : '0';
          const sourceEndTopicTime = sortedSourceSentences.length > 0
            ? Math.max(...sortedSourceSentences.map((s: any) => parseFloat(s.timeEnd))).toString()
            : '0';

          // Recalculate start and end times for target topic
          const targetStartTopicTime = sortedTargetSentences.length > 0
            ? Math.min(...sortedTargetSentences.map((s: any) => parseFloat(s.timeStart))).toString()
            : '0';
          const targetEndTopicTime = sortedTargetSentences.length > 0
            ? Math.max(...sortedTargetSentences.map((s: any) => parseFloat(s.timeEnd))).toString()
            : '0';

          const updatedMediaTopicBreakdown = [...movie.mediaTopicBreakdown];
          updatedMediaTopicBreakdown[sourceTopicIndex] = {
            ...sourceTopic,
            Sentence: sortedSourceSentences,
            startTopicTime: sourceStartTopicTime,
            endTopicTime: sourceEndTopicTime,
          };
          updatedMediaTopicBreakdown[targetTopicIndex] = {
            ...targetTopic,
            Sentence: sortedTargetSentences,
            startTopicTime: targetStartTopicTime,
            endTopicTime: targetEndTopicTime,
          };

          const updatedMovies = [...prevMovies];
          updatedMovies[movieIndex] = { ...movie, mediaTopicBreakdown: updatedMediaTopicBreakdown };

          updateChanges(movieId, sourceTopic.topicNumber, 'startTopicTime', sourceStartTopicTime);
          updateChanges(movieId, sourceTopic.topicNumber, 'endTopicTime', sourceEndTopicTime);
          updateChanges(movieId, targetTopic.topicNumber, 'startTopicTime', targetStartTopicTime);
          updateChanges(movieId, targetTopic.topicNumber, 'endTopicTime', targetEndTopicTime);

          return updatedMovies;
        }
        return prevMovies;
      });
    }
  };

  const sortSentencesInRow = (movieId: string, topicNumber: number) => {
    setMovies((prevMovies) =>
      prevMovies.map((movie) => {
        if (movie.movieId === movieId) {
          const updatedMediaTopicBreakdown = movie.mediaTopicBreakdown.map((topic: any) => {
            if (topic.topicNumber === topicNumber) {
              const sortedSentences = topic.Sentence.sort((a: any, b: any) => parseFloat(a.timeStart) - parseFloat(b.timeStart));
              const startTopicTime = sortedSentences.length > 0
                ? Math.min(...sortedSentences.map((s: any) => parseFloat(s.timeStart))).toString()
                : '0';
              const endTopicTime = sortedSentences.length > 0
                ? Math.max(...sortedSentences.map((s: any) => parseFloat(s.timeEnd))).toString()
                : '0';
              return {
                ...topic,
                Sentence: sortedSentences,
                startTopicTime,
                endTopicTime,
              };
            }
            return topic;
          });
          updateChanges(movieId, topicNumber, 'startTopicTime', updatedMediaTopicBreakdown.find((t: any) => t.topicNumber === topicNumber).startTopicTime);
          updateChanges(movieId, topicNumber, 'endTopicTime', updatedMediaTopicBreakdown.find((t: any) => t.topicNumber === topicNumber).endTopicTime);
          return {
            ...movie,
            mediaTopicBreakdown: updatedMediaTopicBreakdown,
          };
        }
        return movie;
      })
    );
  };

  const reorderSentencesInRow = (movieId: string, topicNumber: number, dragIndex: number, hoverIndex: number) => {
    setMovies((prevMovies) =>
      prevMovies.map((movie) => {
        if (movie.movieId === movieId) {
          const updatedMediaTopicBreakdown = movie.mediaTopicBreakdown.map((topic: any) => {
            if (topic.topicNumber === topicNumber) {
              const updatedSentences = [...topic.Sentence];
              const [draggedSentence] = updatedSentences.splice(dragIndex, 1);
              updatedSentences.splice(hoverIndex, 0, draggedSentence);

              return {
                ...topic,
                Sentence: updatedSentences,
              };
            }
            return topic;
          });
          updateChanges(movieId, topicNumber, 'Sentence', updatedMediaTopicBreakdown.find((t: any) => t.topicNumber === topicNumber).Sentence);
          return {
            ...movie,
            mediaTopicBreakdown: updatedMediaTopicBreakdown,
          };
        }
        return movie;
      })
    );
  };

  const findSentenceById = (movieId: string, sentenceId: string) => {
    const movie = movies.find((movie) => movie.movieId === movieId);
    if (!movie) return null;

    for (const topic of movie.mediaTopicBreakdown) {
      const sentence = topic.Sentence.find((s: any) => s.id === sentenceId);
      if (sentence) {
        return sentence;
      }
    }
    return null;
  };

  const saveChanges = async () => {
    try {
      const response = await fetch('/api/save-changes', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ changes }),
      });
      const data = await response.json();
      console.log('Changes saved successfully:', data);
    } catch (error) {
      console.error('Error saving changes:', error);
    }
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Course Management
      </Typography>
      <CourseDropdown
        courses={mockCourses}
        selectedCourseId={selectedCourseId}
        onCourseChange={handleCourseChange}
      />
      <DndProvider backend={HTML5Backend}>
        {movies.map((movie) => (
          <Box key={movie.movieId} sx={{ display: 'flex', flexDirection: 'column', gap: '1px', width: '1400px', marginLeft: '-100px' }}>
            <Paper elevation={2} sx={{ padding: 2, marginBottom: 2 }}>
              <Typography variant="h6">{movie.name}</Typography>
              <Typography variant="subtitle1">{movie.subject}</Typography>
              <Typography variant="body1">{movie.summary}</Typography>
            </Paper>
            <TableOfContentData
              topics={movie.mediaTopicBreakdown}
              onTopicNameChange={(topicNumber, newName) => handleTopicNameChange(movie.movieId, topicNumber, newName)}
              onSummaryChange={(topicNumber, newSummary) => handleSummaryChange(movie.movieId, topicNumber, newSummary)}
              onKeyPointChange={(topicNumber, index, newKeyPoint) => handleKeyPointChange(movie.movieId, topicNumber, index, newKeyPoint)}
              onKeyPointTopicNumberChange={(oldTopicNumber, index, newTopicNumber) => handleKeyPointTopicNumberChange(movie.movieId, oldTopicNumber, index, newTopicNumber)}
              moveSentence={(sentenceId, targetTopicNumber) => moveSentence(movie.movieId, sentenceId, targetTopicNumber)}
              sortSentencesInRow={(topicNumber) => sortSentencesInRow(movie.movieId, topicNumber)}
              reorderSentencesInRow={(topicNumber, dragIndex, hoverIndex) => reorderSentencesInRow(movie.movieId, topicNumber, dragIndex, hoverIndex)}
            />
          </Box>
        ))}
      </DndProvider>
      <Button variant="contained" color="primary" onClick={saveChanges}>
        Save Changes
      </Button>
    </Container>
  );
};

export default ManagementTocPage;
