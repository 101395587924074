import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';

import { RootState } from '../../store'; // Ensure this import path is correct
import { useSelector } from 'react-redux';
import SearchSpinner from '../transcriptsComponents/searchComponentSpinner';
import { v4 as uuidv4 } from 'uuid';


import MainSearch from './MainSearch';
import SearchForm from './SearchForm';
import SearchResults from './SearchResults';

interface SearchComponentProps {
  triggerSearchTextFromGrandfatherPage: string;
  onSearch: (searchQuery: string, isImageSearch: boolean, audioName: string, mainCategory: string, addon: string) => void;
  onMediaInteraction: (item: any, movieData: any) => void;
  isSearchFormOn: boolean;
  boxName: string;
  addon: string;
}

interface SearchAnswerPair {
  sessionId: string;
  movieId: string;
  search: string;
  answer: string;
  movieUrl?: string;
  embedUrl?: string;
  movieName?: string;
  links?: string[];
  startTime?: string | undefined;
  endTime?: string | undefined;
  largeEndTime?: string;
  largeStartTime?: string;
  mainCategory?: string;
  pageImg?: string;
  subCategory1?: string;
  subCategory2?: string;
  videoAudioText?: string;
  videoFrameTime?: string;
  description?: string;
  audioLength?: string;
  largeDistance?: string;
  metaDistance?: string;
  maxDistance?: string;
  avgDistance?: string;
  focusDistance?: string;
  videoDistance?: string;
  pdfImagesAudioText: string;
  pdfImagesDistance: string;
  pdfImagesPageNumber: string;
  pdfImagesSectionNumber: string;
  pdfTablesAudioText: string;
  pdfTablesDistance: string;
  pdfTablesPageNumber: string;
  pdfTablesSectionNumber: string;
  pdfTextAudioText: string;
  pdfTextDistance: string;
  pdfTextPageNumber: string;
  pdfTextSectionNumber: string;
  elasticSearchResult?: Array<{
    articleId: string;
    audioFileName: string;
    channel: string;
    description: string;
    mainCategory: string;
    movieLink: string;
    pageImg: string;
    pageLink: string;
    published: string;
    sentence: string;
    subCategory1: string;
    subCategory2: string;
    endTime: string;
    startTime: string;
    title: string;
    embedUrl: string;
    audioLength: string;
    pdfImagesAudioText: string;
    pdfImagesDistance: string;
    pdfImagesPageNumber: string;
    pdfImagesSectionNumber: string;
    pdfTablesAudioText: string;
    pdfTablesDistance: string;
    pdfTablesPageNumber: string;
    pdfTablesSectionNumber: string;
    pdfTextAudioText: string;
    pdfTextDistance: string;
    pdfTextPageNumber: string;
    pdfTextSectionNumber: string;
  }>,
  semanticsSearchResults?: Array<{
    articleId: string;
    audioFileName: string;
    channel: string;
    description: string;
    mainCategory: string;
    movieLink: string;
    pageImg: string;
    pageLink: string;
    published: string;
    subCategory1: string;
    subCategory2: string;
    endTime: string;
    startTime: string;
    title: string;
    videoFrameTime: string;
    embedUrl: string;
    audioLength: string;
    focusDistance: string;
    videoDistance: string;
    largeStartTime: string,
    largeEndTime: string,
    largeDistance: string,
    pdfImagesAudioText: string;
    pdfImagesDistance: string;
    pdfImagesPageNumber: string;
    pdfImagesSectionNumber: string;
    pdfTablesAudioText: string;
    pdfTablesDistance: string;
    pdfTablesPageNumber: string;
    pdfTablesSectionNumber: string;
    pdfTextAudioText: string;
    pdfTextDistance: string;
    pdfTextPageNumber: string;
    pdfTextSectionNumber: string;
  }>;
}
const MainSearchComponent: React.FC<SearchComponentProps> = ({ onSearch, triggerSearchTextFromGrandfatherPage, onMediaInteraction, isSearchFormOn, boxName, addon }) => {
  const textState = useSelector((state: RootState) => state.searchMedia);

  const [qaPairs, setQaPairs] = useState<SearchAnswerPair[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [audioName, setAudioName] = useState('');
  const [mainCategory, setMainCategory] = useState('');


  useEffect(() => {
    if (triggerSearchTextFromGrandfatherPage === "trigger") {
      // setSearchQuery(triggerSearchTextFromGrandfatherPage);
      triggerSearchTextFromGrandfatherPage = "notTriggered";
      setQaPairs([]);
    }
  }, []);


  useEffect(() => {
    if (textState.error) {
      setErrorMessage('An error occurred while fetching data.');
      setIsLoading(false);
    }
    if (textState.searchResult.length === 0) {
      setQaPairs([]);
    }
    if (textState.searchResult && textState.searchResult.movieData) {
      setQaPairs(textState.searchResult.movieData.flatMap((movieItem: any) => {
        const mainMovie: SearchAnswerPair = {
          sessionId: textState.searchResult.sessionId,
          movieId: movieItem.movieId,
          search: movieItem.question,
          answer: movieItem.answer,
          movieUrl: movieItem.movieUrl,
          embedUrl: movieItem.embedUrl,
          movieName: movieItem.title,
          links: movieItem.links,
          startTime: movieItem.startTime,
          endTime: movieItem.endTime,
          largeEndTime: movieItem.largeEndTime,
          largeStartTime: movieItem.largeStartTime,
          mainCategory: movieItem.mainCategory,
          pageImg: movieItem.pageImg,
          subCategory1: movieItem.subCategory,
          subCategory2: movieItem.subCategory2,
          videoAudioText: movieItem.videoAudioText,
          videoFrameTime: movieItem.videoFrameTime,
          description: movieItem.description,
          audioLength: movieItem.audioLength,
          largeDistance: movieItem.largeDistance,
          metaDistance: movieItem.metaDistance,
          maxDistance: movieItem.maxDistance,
          avgDistance: movieItem.avgDistance,
          focusDistance: movieItem.focusDistance,
          videoDistance: movieItem.videoDistance,
          pdfImagesAudioText: movieItem.pdfImagesAudioText,
          pdfImagesDistance: movieItem.pdfImagesDistance,
          pdfImagesPageNumber: movieItem.pdfImagesPageNumber,
          pdfImagesSectionNumber: movieItem.pdfImagesSectionNumber,
          pdfTablesAudioText: movieItem.pdfTablesAudioText,
          pdfTablesDistance: movieItem.pdfTablesDistance,
          pdfTablesPageNumber: movieItem.pdfTablesPageNumber,
          pdfTablesSectionNumber: movieItem.pdfTablesSectionNumber,
          pdfTextAudioText: movieItem.pdfTextAudioText,
          pdfTextDistance: movieItem.pdfTextDistance,
          pdfTextPageNumber: movieItem.pdfTextPageNumber,
          pdfTextSectionNumber: movieItem.pdfTextSectionNumber,
          elasticSearchResult: movieItem.elasticSearchResult?.map((elasticResult: any) => ({
            articleId: elasticResult.articleId,
            audioFileName: elasticResult.audioFileName,
            channel: elasticResult.channel,
            description: elasticResult.description,
            mainCategory: elasticResult.mainCategory,
            movieLink: elasticResult.movieLink,
            pageImg: elasticResult.pageImg,
            pageLink: elasticResult.pageLink,
            published: elasticResult.published,
            sentence: elasticResult.sentence,
            subCategory1: elasticResult.subCategory1,
            subCategory2: elasticResult.subCategory2,
            endTime: elasticResult.timeEnd,
            startTime: elasticResult.timeStart,
            title: elasticResult.title,
            embedUrl: elasticResult.embedUrl,
            audioLength: elasticResult.audioLength,
            pdfImagesAudioText: elasticResult.pdfImagesAudioText,
            pdfImagesDistance: elasticResult.pdfImagesDistance,
            pdfImagesPageNumber: elasticResult.pdfImagesPageNumber,
            pdfImagesSectionNumber: elasticResult.pdfImagesSectionNumber,
            pdfTablesAudioText: elasticResult.pdfTablesAudioText,
            pdfTablesDistance: elasticResult.pdfTablesDistance,
            pdfTablesPageNumber: elasticResult.pdfTablesPageNumber,
            pdfTablesSectionNumber: elasticResult.pdfTablesSectionNumber,
            pdfTextAudioText: elasticResult.pdfTextAudioText,
            pdfTextDistance: elasticResult.pdfTextDistance,
            pdfTextPageNumber: elasticResult.pdfTextPageNumber,
            pdfTextSectionNumber: elasticResult.pdfTextSectionNumber,
          })),
          semanticsSearchResults: movieItem.semanticsSearchResults?.map((semanticsResult: any) => ({
            articleId: semanticsResult.articleId,
            audioFileName: semanticsResult.audioFileName,
            channel: semanticsResult.channel,
            description: semanticsResult.description,
            mainCategory: semanticsResult.mainCategory,
            movieLink: semanticsResult.movieLink,
            pageImg: semanticsResult.pageImg,
            pageLink: semanticsResult.pageLink,
            published: semanticsResult.published,
            subCategory1: semanticsResult.subCategory1,
            subCategory2: semanticsResult.subCategory2,
            endTime: semanticsResult.largeEndTime,
            startTime: semanticsResult.largeStartTime,
            title: semanticsResult.title,
            videoFrameTime: semanticsResult.videoFrameTime,
            embedUrl: semanticsResult.embedUrl,
            audioLength: movieItem.audioLength,
            focusDistance: semanticsResult.focusDistance,
            videoDistance: semanticsResult.videoDistance,
            largeStartTime: semanticsResult.largeStartTime,
            largeEndTime: semanticsResult.largeEndTime,
            largeDistance: semanticsResult.largeDistance,
            pdfImagesAudioText: semanticsResult.pdfImagesAudioText,
            pdfImagesDistance: semanticsResult.pdfImagesDistance,
            pdfImagesPageNumber: semanticsResult.pdfImagesPageNumber,
            pdfImagesSectionNumber: semanticsResult.pdfImagesSectionNumber,
            pdfTablesAudioText: semanticsResult.pdfTablesAudioText,
            pdfTablesDistance: semanticsResult.pdfTablesDistance,
            pdfTablesPageNumber: semanticsResult.pdfTablesPageNumber,
            pdfTablesSectionNumber: semanticsResult.pdfTablesSectionNumber,
            pdfTextAudioText: semanticsResult.pdfTextAudioText,
            pdfTextDistance: semanticsResult.pdfTextDistance,
            pdfTextPageNumber: semanticsResult.pdfTextPageNumber,
            pdfTextSectionNumber: semanticsResult.pdfTextSectionNumber,

          }))
        };
        setIsLoading(false);
        setErrorMessage('');
        return mainMovie;
      }).reverse());
    }
  }, [textState.searchResult, textState.error]);




  //// css for search component
  const lightPurple = '#E6E0FA'; // Light purple color, close to white
  const customScrollStyles = {
    '&::-webkit-scrollbar': {
      width: '0px', // Hide scrollbar for Chrome, Safari, and Opera
    },
    scrollbarWidth: 'none', // Hide scrollbar for Firefox
    msOverflowStyle: 'none',  // Hide scrollbar for IE and Edge
  };
  const darkStyles = {
    box: {
      backgroundColor: '#121212', // Dark background for boxes
      color: '#fff', // White text color
    },
    typography: {
      color: lightPurple, // Light purple text color for Typography
    },
    link: {
      color: lightPurple, // Light purple text color for links
    },
    iframeBorder: {
      border: `2px solid ${lightPurple}`, // Light purple border for iframes
    },
    interactButton: {
      color: lightPurple, // Light purple for interact button
      '&:hover': {
        backgroundColor: '#D8D0E8', // Slightly darker light purple on hover
      },
    },
    resultsContainer: {
      overflowY: 'scroll',
      maxHeight: '60vh',
      width: '100%',
      padding: '1rem',
      ...customScrollStyles, // Apply the custom scroll styles
    }
  };
  return (
    <Box sx={{ ...darkStyles.box, display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '0rem', marginRight: '1rem', marginLeft: '1rem' }}>
      <Box sx={{ position: 'relative', top: 0, left: 0, right: 0, zIndex: 100, marginLeft: '0', width: '80%' }}>
        <MainSearch onSearch={onSearch} style={darkStyles} setIsLoading={setIsLoading} setErrorMessage={setErrorMessage} audioName={audioName} mainCategory={mainCategory} boxName={boxName} addon={addon} toUseSwitch={true} />
        {isSearchFormOn && <SearchForm setAudioName={setAudioName} setMainCategory={setMainCategory} />}
        {isLoading && <SearchSpinner />}
        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
      </Box>
      <Box sx={{ width: '100%', my: 0 }}  ><hr style={{ width: '100%' }} /></Box>
      <Box sx={darkStyles.resultsContainer}>
        {qaPairs.map((item, index) => (
          <SearchResults item={item} index={index} onMediaInteraction={onMediaInteraction} style={darkStyles} qaPair={qaPairs} key={index} />

        ))}
      </Box>

    </Box>
  );


};

export default MainSearchComponent;