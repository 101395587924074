
import React, { useRef, FC } from 'react';
import { Box, Link } from '@mui/material';
import { secondsToMinutes, convertToYoutubeTime } from './UtilityFunctions';
import PDFViewer from '../generalComponents/PDFViewer';
interface SearchResultMiddleProps {
  item: any,
}
const SearchResultMiddle: React.FC<SearchResultMiddleProps> = ({ item }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '0px',
        marginBottom: '10px',
        marginRight: '1rem',
      }}
    >
      <Box
        sx={{
          border: '2px solid blue',
          borderRadius: '10px',
          overflow: 'hidden',
          width: '500px',
          height: '250px',
        }}
      >
        {/* <iframe
          width="600"
          height="300"
          // src={`${item.embedUrl}?start=${secondsToMinutes(item.startTime)}&end=${secondsToMinutes(item.endTime)}`}
          src={`${item.movieUrl}#page=${item.pdfTextPageNumber}`}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
        ></iframe> */}
        {/* <PDFViewer fileUrl={item.movieUrl} /> */}
        <Link href={`${item.movieUrl}?t=${convertToYoutubeTime(secondsToMinutes(item.startTime))}` || '#'}
          key={item.movieUrl}
          target="_blank"
          rel="noopener noreferrer">
          <img
            src={`${item.pageImg}`}
            alt={`${item.title}`}
            width="500"
            height="250"
            style={{ display: 'block', width: '100%' }}
          />
        </Link>
      </Box>
    </Box>
  );
}

export default SearchResultMiddle;