
import React,{useState} from 'react';
import { makeStyles, IconButton, Menu, MenuItem,useTheme } from '@material-ui/core';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SmallMenu from './SmallMenu';
import { setSelectedSessionId } from '../../features/navbarSlice';
interface ArchiveComponentProps {
    selectedArchiveSessionId: string | null;
    archiveData: any;
    setSelectedSessionId:any;
    selectedSessionId:string|null,
    onArchiveItemClick: (sessionId: string) => void;
    //handleButtonClick:any
}

const ArchiveComponent:React.FC<ArchiveComponentProps>= ({selectedArchiveSessionId,archiveData,selectedSessionId,setSelectedSessionId,onArchiveItemClick}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [currentSessionId, setCurrentSessionId] = useState<string | null>(null);
  
    const classes = useStyles();
    const theme = useTheme();

    const handleArchiveItemClick = (sessionId:string) => {
        setSelectedSessionId(sessionId);
         onArchiveItemClick(sessionId); 
    };

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>, sessionId: string) => {
        setAnchorEl(event.currentTarget);
        setCurrentSessionId(sessionId);
    };

   

    const renderArchiveItems = () => {
        const categories = [
          { title: 'Today', items: archiveData.Today },
          { title: 'Yesterday', items: archiveData.Yesterday },
          { title: 'Last Week', items: archiveData.LastWeek },
          { title: 'Last Month', items: archiveData.LastMonth },
          { title: 'Last Year', items: archiveData.LastYear },
          { title: 'All Others', items: archiveData.AllOthers }     
        ];    
        return categories.map(category => {
          let categoryHasSelectedItem = category.items.some((item:any) => item.sessionId === selectedArchiveSessionId);          
          return (
            <div key={category.title}>
              {(categoryHasSelectedItem || !selectedArchiveSessionId) && (
                <h3 className={classes.archiveCategoryTitle}>{category.title}</h3>
              )}
              {category.items.map((item: any) => {
                if (!selectedArchiveSessionId || item.sessionId === selectedArchiveSessionId) {
                  return (
                    <div key={item.sessionId} onClick={() => handleArchiveItemClick(item.sessionId)} className={`${classes.archiveItem} ${item.sessionId === selectedSessionId ? 'selected' : ''}`}>
                      <span 
                        className={classes.archiveItemText}
                        onClick={() => handleArchiveItemClick(item.sessionId)}>
                        {item.answerTitle}
                      </span>
                      <IconButton
                        size="small"
                        onClick={(e) => handleMenuClick(e, item.sessionId)}
                        className={classes.actionButton}
                      >
                        <MoreVertIcon fontSize="small" />
                      </IconButton>
                      <SmallMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} currentSessionId={currentSessionId} setCurrentSessionId={setCurrentSessionId}  item={item}/>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          );
        });
      };

      return(
            <div className={classes.archiveList}>
                {renderArchiveItems()}
            </div>
      )
}
const useStyles = makeStyles((theme) => ({
  archiveList: {
    marginTop: '20px',
    color: 'white',
    overflowY: 'auto', // Enable vertical scrolling
    //maxHeight: 'calc(100vh - 150px)', // Adjust the height as needed
    height:'110vh',
    width: '100%',
    '&::-webkit-scrollbar': {
    width: '4px',
    },
      '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      backgroundColor: 'black',
      },
        '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'black',
        borderRadius: '10px',
        '&:hover': {
           backgroundColor: 'purple',
        },
    },
},
  archiveCategoryTitle: {
    color: '#d3d3d3', // Set to light gray
    fontSize: '13px',
    marginTop: '30px',
    marginBottom: '25px',
    marginRight: '50px',
  },
  iconText: {
    fontSize: '15px', // Adjust text size
    overflow: 'hidden', // Hide overflowing text
    
    maxWidth: '100%', // Limit text width
    marginLeft: '10px', // Add left margin
    [theme.breakpoints.down('sm')]: {
      display: 'none', // Hide text on small screens
    },
  },
 
  archiveItem: {
    cursor: 'pointer',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '210px',
    display: 'flex', // Align title and buttons in a row
    alignItems: 'center', 
    justifyContent: 'space-between',
    marginRight: '10px',
    maxWidth: 'calc(100% - 0px)',
    height: '30px',
    '&:hover': {      
      backgroundColor: '#8a8db7', // Darker purple on hover
      borderRadius: '5px', // Rounded corners
      marginRight: '10px',
      width: '210px',
      color: 'black',
      height: '30px',
      
    },
    '&.selected': {
      backgroundColor: '#5D61AA', // Darker purple on hover
      borderRadius: '5px', // Rounded corners
      marginRight: '10px',
      width: '210px',
      color: 'black',
      height: '30px',
    },
    marginBottom: theme.spacing(1.2),
  },
  archiveItemText: {
    marginLeft: theme.spacing(1), // Add left margin
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginRight: '0px', // Add right margin to separate from the menu icon
    maxWidth: 'calc(100% - 0px)', // Adjust this based on the width of the menu icon
    '&:hover': {
      color: 'black',
    },
  },
  actionButton: {
    marginLeft: theme.spacing(0), // Space between text and button
  },
  
}));

export default ArchiveComponent;