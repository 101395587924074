import React, { useState, useMemo, useEffect } from 'react';
import { Box, CssBaseline, Grid, Switch, FormControlLabel, ThemeProvider, createTheme, AppBar, Toolbar } from '@mui/material';
import Header from '../components/combinationSearchComponents/Header';
import { fetchCombinationSearch, getSearchByCourseId, getQuestionArchiveByCourseId } from '../features/combinationSearchSlice';
import { fetchManualMovieData, fetchManualData, setSelectedVideoDetails } from '../features/manualSlice';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../store';
import BarLeftSide from '../components/combinationSearchComponents/barLeftSide/BarLeftSide';
import VideoSearchLayoutForManual from '../components/combinationSearchComponents/manualComponents/videoSearchLayoutForManual';

import getTheme from '../components/combinationSearchComponents/configuration/Themes';
import { useNavigate } from 'react-router-dom';
import useStyles from '../styles/useCoursePageStyles';



const ManualPage = () => {
  const [themeMode, setThemeMode] = useState<'light' | 'dark'>('light');
  const theme = useMemo(() => getTheme(themeMode), [themeMode]);
  const classes = useStyles(theme);
  const layoutsArray: any[] = [];
  const dispatch = useDispatch<AppDispatch>();

  const movieData = useSelector((state: RootState) => state.combinationSearch.data);
  const combinationSearchState = useSelector((state: RootState) => state.combinationSearch);

  const manualData = useSelector((state: RootState) => state.manual.manualData);
  const selectedVideo = useSelector((state: RootState) => state.courses.selectedVideo);


  const [layouts, setLayouts] = useState<any[]>([]);
  const [activeLayoutIndex, setActiveLayoutIndex] = useState(0);
  const [currentLevel, setCurrentLevel] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState('');
  const navigate = useNavigate();


  useEffect(() => {
    if (selectedVideo && selectedVideo.movieId) {
      localStorage.setItem('selectedVideo', JSON.stringify(selectedVideo));
      dispatch(fetchManualMovieData({ movieId: selectedVideo.movieId, id: selectedVideo.id }));
    }
    if (!selectedVideo) {
      const videosIds = localStorage.getItem('selectedVideo');
      if (videosIds) {
        const parsedVideosIds = JSON.parse(videosIds);
        setSelectedVideoDetails({ movieId: parsedVideosIds.movieId, id: parsedVideosIds.id, courseId: parsedVideosIds.courseId });
        dispatch(fetchManualMovieData({ movieId: parsedVideosIds.movieId, id: parsedVideosIds.id }));
      }
      if (!videosIds) {
        navigate(`/home`);
      }
    }
  }, [selectedVideo])

  useEffect(() => {
    if (combinationSearchState.loading === false) {
      setIsLoading(false);
    }
  }, [combinationSearchState.loading, combinationSearchState.error]);

  useEffect(() => {
    dispatch(fetchManualData());
    // dispatch(fetchContentOfManual());
    if (manualData && manualData.courseId) {
      localStorage.setItem('courseId', manualData.courseId);
    }
    if (manualData && manualData.noData) {
      navigate(`/home`);
    }
  }, [dispatch]);

  const getCourseId = () => {
    if (manualData && manualData.courseId) {
      return manualData.courseId;
    }
    if (manualData && manualData.noData) {
      navigate(`/home`);
    }
  }

  useEffect(() => {
    let sessionId = localStorage.getItem('courseSessionId');
    if (!sessionId) {
      sessionId = uuidv4();
      localStorage.setItem('courseSessionId', sessionId);
    }
    const searchLevels = JSON.parse(localStorage.getItem('searchLevels') || '[]');
    if (currentLevel === 0) {
      searchLevels[currentLevel] = "first0001";
    }
    const newSearch = searchLevels[currentLevel];
    dispatch(getSearchByCourseId({ courseId: getCourseId(), searchId: newSearch }));
  }, [manualData && manualData.courseId && dispatch]);

  useEffect(() => {
    if (manualData) {
      if (layouts.length === 0) {
        const newLayout = { searchData: movieData, videoData: manualData, videoUrl: manualData.movieLink, title: manualData.title, level: 0 };
        setLayouts([newLayout]);
        setActiveLayoutIndex(0);
        setCurrentLevel(0);
        setTitle(manualData.movieName ? manualData.movieName : manualData.title);
      }
      if (layouts.length > 0) {
        const currentLayout = layouts[activeLayoutIndex];
        currentLayout.videoData = manualData;
        currentLayout.videoUrl = manualData.movieLink;
        currentLayout.title = manualData.title;
        setTitle(manualData.movieName);
        setLayouts([...layouts]);
      }
    }
  }, [manualData]);


  useEffect(() => {
    if (!movieData) {
      setIsLoading(false);
      return;
    }
    const searchLevels = [];
    searchLevels[currentLevel] = movieData.searchId;
    localStorage.setItem('searchLevels', JSON.stringify(searchLevels));
    const currentLayout = layouts[activeLayoutIndex];
    if (!currentLayout) return;
    currentLayout.searchData = movieData;
    setLayouts([...layouts]);
    setIsLoading(false);
  }, [movieData]);

  // Toggle theme mode function
  const toggleTheme = () => {
    setThemeMode(prevMode => prevMode === 'light' ? 'dark' : 'light');
  };
  const breadcrumbs = layouts.map((layout, index) => ({
    title: layout.title,
    level: layout.level,
    onClick: () => setActiveLayoutIndex(index),
  }));
  const setTitleFromMovieData = (movieData: any) => {
    if (movieData && movieData.answerData[0] && movieData.answerData[0].movies[0]) {
      if (movieData.answerData[0].movies[0].semantic.length > 0) {
        setTitle(movieData.answerData[0].movies[0].semantic[0].mainCategory);
      }
      else if (movieData.answerData[0].movies[0].index.length > 0) {
        setTitle(movieData.answerData[0].movies[0].index[0].mainCategory);
      }
    }
    return '';
  }
  const handleVideoSelect = (videoUrl: any, searchData: any, videoData: any, title: string, level: number) => {
    const newLevel = currentLevel + 1;
    const newLayout = { videoUrl, searchData, videoData, title, level: newLevel };
    if (layouts.length > newLevel) {
      const newLayouts = layouts.slice(0, -1);
      setLayouts([...newLayouts, newLayout]);
    }
    else {
      setLayouts([...layouts, newLayout]);
      setCurrentLevel(newLevel); // Update the level state
    }
    setActiveLayoutIndex(layouts.length);
  };
  const handlePdfClick = (videoUrl: any, searchData: any, videoData: any, title: string, level: number) => {
    const currentLayout = layouts[level];
    currentLayout.videoData = videoData;
    currentLayout.videoUrl = videoUrl;
    currentLayout.title = title;
    currentLayout.searchData = searchData;
    setLayouts([...layouts]);

  }
  const onSearch = (searchTerm: string, time?: string, articleId?: string) => {
    const sessionId = localStorage.getItem('courseSessionId');
    const searchLevels = JSON.parse(localStorage.getItem('searchLevels') || '[]');
    const searchId = searchLevels[currentLevel];
    const courseId = getCourseId();
    const audioName = '';
    const onlySearch = '';
    const customer = '';
    const modelName = localStorage.getItem('modelName');
    const numberOfSearchResults = localStorage.getItem('numberOfSearchResults');
    const bestResultMethod = localStorage.getItem('bestResultMethod');
    const longContext = localStorage.getItem('longContext');
    const runType = "all";
    const answerDataId = uuidv4();
    dispatch(fetchCombinationSearch({ searchTerm, sessionId, courseId, audioName, onlySearch, searchId, time, articleId, customer, modelName, numberOfSearchResults, bestResultMethod, longContext, runType, answerDataId }));
  };
  const handleBreadcrumbSelect = (index: any) => {
    // This sets the layouts to include everything up to and including the selected index
    setLayouts(layouts.slice(0, index + 1));
    // This updates the active layout index to the selected index
    setActiveLayoutIndex(index);
    // Update the current level based on the selected breadcrumb
    setCurrentLevel(layouts[index].level);
  };
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* Outermost box to cover the full screen */}
      <Box className={classes.fixedBox}>
        <Header header={"Media-Morph Manuals"} themeMode={themeMode} toggleTheme={toggleTheme} title={title} />
      </Box>
      <Box className={classes.mainContentBox} >
        {/* <BarLeftSide items={questionArchive} onSelectItem={() => { }} /> */}

        <Box className={classes.layoutsBox}>
          {manualData && layouts.map((layout, index) => (
            <Box key={index} display={activeLayoutIndex === index ? 'block' : 'none'} >
              <VideoSearchLayoutForManual searchData={layout.searchData} videoData={layout.videoData} onSearch={onSearch} videoUrl={layout.videoUrl} theme={theme} onVideoSelect={handleVideoSelect} level={currentLevel} courseId={getCourseId()} setIsLoading={setIsLoading} isLoading={isLoading} breadcrumbs={breadcrumbs} handleBreadcrumbSelect={handleBreadcrumbSelect} setCurrentLevel={setCurrentLevel} currentLevel={currentLevel} handlePdfClick={handlePdfClick} />
            </Box>
          ))}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default ManualPage;
