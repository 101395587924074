
import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import logo from '../../images/logo1.png';


const LogoComponent: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const handleLogoClick = () => {
    window.location.href = '/home'; // Navigate to home screen
  };
  return (
    <div className={classes.logoContainer} onClick={handleLogoClick}>
      <img src={logo} alt="Logo" className={classes.logo} style={{ marginRight: '10px' }} />
    </div>
  )
}

export default LogoComponent;

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    width: '80%', // Full width of the navbar
    backgroundColor: 'black', // Same as navbar background color
    color: 'black',
    display: 'flex',
    justifyContent: 'center', // Center the logo
    padding: theme.spacing(1), // Add some padding around the logo
    marginBottom: '-100px',
    marginTop: '150px',
    marginLeft: '-10px',
    cursor: 'pointer', // Add a pointer on hover
  },
  logo: {
    maxWidth: '300px', // Adjust the size as needed
    maxHeight: '300px', // Adjust the height as needed
    // Add more styles if needed
  },
}));