import axiosInstance from "../interceptor";
import { CHATGPT_API_URL, GATEWAY_API_URL } from "../api";

//const api = '/api/v1/manuals';
const api = '/api/manualsService/api/v1/manuals';



// export const getManualMovieData = async (movieId: string, id: string) => {
//   return axiosInstance.get(`${CHATGPT_API_URL}${api}/getManualMovieData/${movieId}/${id}`);
// }

// export const getManualData = async () => {
//   return axiosInstance.get(`${CHATGPT_API_URL}${api}/getManualData`);
// }

export const getManualMovieData = async (movieId: string, id: string) => {
  return axiosInstance.get(`${GATEWAY_API_URL}${api}/getManualMovieData/${movieId}/${id}`);
}

export const getManualData = async () => {
  return axiosInstance.get(`${GATEWAY_API_URL}${api}/getManualData`);
}