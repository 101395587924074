import React, { useState } from 'react';
import { Box, AppBar, Toolbar, Button, Drawer, useTheme, Typography } from '@mui/material';
import MainVideoForManual from './MainVideoForManual';
import MainSearchForManual from './mainSearchForManual';
import TableOContentComponent from '../mainComponenets/TableOfContentComponent';
import SearchBox from '../SearchBox';
import DrawerComponent from '../mainComponenets/DrawerComponent';
import SearchSpinner from '../../transcriptsComponents/searchComponentSpinner';
import BreadcrumbsComponent from '../mainComponenets/BreadcrumbsComponent';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { VideoProgressProvider } from '../videoPlayer/VideoProgressContext';
interface VideoSearchLayoutProps {
  onSearch: (searchTerm: string, time?: string) => void;
  videoUrl: string;
  theme: any;
  searchData: any;
  videoData: any;
  onVideoSelect?: (videoUrl: string, searchData: any, videoData: any, title: string, level: number) => void;
  level: number;
  courseId: string;
  setIsLoading: any;
  isLoading: boolean;
  breadcrumbs: any;
  handleBreadcrumbSelect: any;
  setCurrentLevel: any;
  currentLevel: any;
  handlePdfClick: any;
}

const VideoSearchLayoutForManual: React.FC<VideoSearchLayoutProps> = ({ searchData, videoData, onSearch, videoUrl, theme, onVideoSelect, level, courseId, setIsLoading, isLoading, breadcrumbs, handleBreadcrumbSelect, setCurrentLevel, currentLevel, handlePdfClick }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const muiTheme = useTheme();


  const handleVideoSelection = (newVideoUrl: string, newSearchData: any, newVideoData: any, newTitle: string) => {
    if (onVideoSelect) {
      onVideoSelect(newVideoUrl, newSearchData, newVideoData, newTitle, level);
    }
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const customScrollbar = {
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: `inset 0 0 6px ${muiTheme.palette.action.disabledBackground}`,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: muiTheme.palette.primary.main,
      outline: '1px solid slategrey',
    },
  };

  return (
    <VideoProgressProvider>
      <Box sx={{ display: 'flex', width: '100%', height: '95vh', overflowY: 'hidden' }}>
        <Box sx={{ position: 'relative', top: 55, left: 5, width: '60%', zIndex: 10, ...customScrollbar, overflowY: 'auto' }}>
          <MainVideoForManual videoUrl={videoUrl} videoData={videoData} onSearch={onSearch} setIsLoading={setIsLoading} />
        </Box>
        <Box sx={{ flexGrow: 1, overflow: 'auto', padding: '20px', width: '37%', ...customScrollbar }}>
          <Box sx={{ position: 'fixed', paddingTop: '1rem', top: 40, right: 20, width: '38%', height: '4rem', background: theme.palette.background.default, verticalAlign: 'center', zIndex: 10, }}>
            <BreadcrumbsComponent breadcrumbs={breadcrumbs} onSelect={handleBreadcrumbSelect} setCurrentLevel={setCurrentLevel} currentLevel={currentLevel} />
          </Box>
          {searchData && <MainSearchForManual data={searchData} onSearch={onSearch} theme={theme} onVideoSelect={handleVideoSelection} level={level} handlePdfClick={handlePdfClick} />}
        </Box>
        <DrawerComponent isOpen={drawerOpen} toggleDrawer={toggleDrawer} title={"Manual content"} label={"Manual content"} topPosition='10%' Icon={PictureAsPdfIcon}>
          <TableOContentComponent courseId={courseId} key={level} onMoviesLoaded={() => { }} finishedSubVideos={{}} onQuizButtonClick={() => { }} onVideoButtonClick={() => { }} />
        </DrawerComponent>
        {/* Fixed Footer */}
        <AppBar position="fixed" color="default" sx={{ top: 'auto', bottom: 0, width: '80%', right: 150, background: theme.palette.background.default, ...customScrollbar }}>
          <Box sx={{ position: 'absolute', bottom: '1rem' }}>
            {isLoading && <SearchSpinner />}
          </Box>
          <SearchBox onSearch={onSearch} isLoading={isLoading} />
        </AppBar>
      </Box>
    </VideoProgressProvider>
  );
};

export default VideoSearchLayoutForManual;