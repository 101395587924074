import { createSlice, createAsyncThunk, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { bookChat, getBooksByUserId } from '../api/serverApis/bookApi';

interface chatGptState {
  text: string;
  loading: boolean;
  error: string | null | undefined;
}

export interface FetchResponsePayload {
  userId: string;
  text: string;
  apiKey: string;
}

const initialState: chatGptState = {
  text: '',
  loading: false,
  error: null,
};
interface CurrentBookState {
  bookId: string;
  prompt: string;
}


export const fetchBookThunk = createAsyncThunk('book/fetchBookChatGpt', async ({ userId, text, apiKey }: { userId: string, text: string, apiKey: string }) => {
  const response = await bookChat(userId, text, apiKey);
  return response.data;
});
export const fetchBookByUserIdThunk = createAsyncThunk('book/fetchBookById', async ({ id }: { id: string }) => {
  const response = await getBooksByUserId({ id });
  return response.data;
});

const handleChatState = (action: any) => {
  return (builder: any) => {
    builder
      .addCase(action.pending, (state: any) => {
        state.loading = true;
      })
      .addCase(action.fulfilled, (state: any, action: PayloadAction<string>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(action.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
}

const chatGptSlice = createSlice({
  name: 'chatGpt',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    handleChatState(fetchBookThunk)(builder);
    handleChatState(fetchBookByUserIdThunk)(builder);
  },
});

export default chatGptSlice.reducer;