

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { combinationSearch, CombinationSearchSendData, searchBySessionId, searchByCourseId, getQuestionArchive, deleteMovie, deleteSearch, deleteAnswerAndQuestionByQuestionId, searchByCourseIdWithAnswerDataId } from '../api/serverApis/combinationSearchApi';

export interface TabContent {
  [key: string]: any[];
}

export interface combinationSearchState {
  questionsArchive: any;
  data2: any;
  data: any;
  loading: boolean;
  error: string | null | undefined;
  tabsContent: TabContent;
}

const initialState: combinationSearchState = {
  questionsArchive: null,
  data2: null,
  data: null,
  loading: false,
  error: null,
  tabsContent: {},
};

export const fetchCombinationSearch = createAsyncThunk('combinationSearch/fetchCombinationSearch', async ({ searchTerm, sessionId, courseId, audioName, onlySearch, searchId, time, articleId, customer, modelName, numberOfSearchResults, bestResultMethod, longContext, runType, answerDataId }: { searchTerm: string, sessionId: string | null, courseId: string | undefined, audioName: string | undefined, onlySearch: string | undefined, searchId: string | undefined, time: string | undefined, articleId: string | undefined, customer: string | null, modelName: string | null, numberOfSearchResults: string | null, bestResultMethod: string | null, longContext: string | null, runType: string, answerDataId: string }) => {
  const sendData: CombinationSearchSendData = {
    searchTerm,
    sessionId,
    courseId,
    audioName,
    onlySearch,
    searchId,
    time,
    articleId,
    customer,
    modelName,
    numberOfSearchResults,
    bestResultMethod,
    longContext,
    runType,
    answerDataId
  };
  const response = await combinationSearch(sendData);
  return response.data;
});

export const fetchQuestionDelete = createAsyncThunk('combinationSearch/fetchQuestionDelete', async ({ courseId, searchId, questionId }: { searchId: string, courseId: string, questionId: string }) => {
  const response = await deleteAnswerAndQuestionByQuestionId(courseId, searchId, questionId);
  return response.data;
});
export const fetchSearchDeleteMovie = createAsyncThunk('combinationSearch/fetchSearchDeleteMovie', async ({ searchId, courseId, answerDataId }: { searchId: string, courseId: string, answerDataId: string | null }) => {
  const response = await deleteSearch(courseId, searchId, answerDataId);
  return response.data;

});
export const fetchMovieDeleteMovie = createAsyncThunk('combinationSearch/fetchMovieDeleteMovie', async ({ courseId, searchId, searchIdMovie, articleId }: { searchId: string, searchIdMovie: string, articleId: string, courseId: string }) => {
  const response = await deleteMovie(courseId, searchId, searchIdMovie, articleId);
  return response.data;

});
// export const getAllArchivesSearchForUser = createAsyncThunk('combinationSearch/getAllArchivesSearchForUser', async (userId: string) => {
//   const response = await combinationSearch({ userId });
//   return response.data;
// });
export const getSearchBySessionId = createAsyncThunk('combinationSearch/getSearchBySessionId', async (sessionId: string) => {
  const response = await searchBySessionId(sessionId);
  return response.data;
});
export const getSearchByCourseId = createAsyncThunk('combinationSearch/getSearchByCourseId', async ({ courseId, searchId }: { courseId: string, searchId: string }) => {
  const response = await searchByCourseId(courseId, searchId);
  return response.data;
});
export const getSearchByCourseIdAndAnswerDataId = createAsyncThunk('combinationSearch/getSearchByCourseIdAndAnswerDataId', async ({ courseId, searchId, answerDataId }: { courseId: string, searchId: string, answerDataId: string }) => {
  const response = await searchByCourseIdWithAnswerDataId(courseId, searchId, answerDataId);
  return response.data;
});
export const getQuestionArchiveByCourseId = createAsyncThunk('combinationSearch/getQuestionArchiveByCourseId', async (courseId: string) => {
  const response = await getQuestionArchive(courseId);
  return response.data;
});
const handleState = (action: any) => {
  return (builder: any) => {
    builder.
      addCase(action.pending, (state: any) => {
        state.loading = true;
      })
      .addCase(action.fulfilled, (state: any, action: PayloadAction<any>) => {
        state.loading = false;
        if (action.type === 'combinationSearch/fetchCombinationSearch/fulfilled') {
          state.data = action.payload;
        }
        if (action.type === 'combinationSearch/fetchCombinationSearch2/fulfilled') {
          state.data2 = action.payload;
        }
        if (action.type === 'combinationSearch/getSearchBySessionId/fulfilled') {
          state.data = action.payload;
        }
        if (action.type === 'combinationSearch/getSearchByCourseId/fulfilled') {
          state.data = action.payload;
        }
        if (action.type === 'combinationSearch/getSearchByCourseIdAndAnswerDataId/fulfilled') {
          state.data = action.payload;
        }
        if (action.type === 'combinationSearch/getQuestionArchiveByCourseId/fulfilled') {
          state.questionsArchive = action.payload;
        }
        if (action.type === 'combinationSearch/fetchSearchDeleteMovie/fulfilled') {
          state.data = action.payload;
        }
        if (action.type === 'combinationSearch/fetchMovieDeleteMovie/fulfilled') {
          state.data = action.payload;
        }
        if (action.type === 'combinationSearch/fetchQuestionDelete/fulfilled') {
          state.data = action.payload;
        }
      })
      .addCase(action.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.error.message;
      });
  };
}

const combinationSearchSlice = createSlice({
  name: 'combinationSearch',
  initialState: initialState,
  reducers: {
    updateTabContent: (state, action: PayloadAction<{ key: string; content: any[] }>) => {
      const { key, content } = action.payload;
      state.tabsContent[key] = content;
    },
    // Action to remove a tab from a specific key
    removeTabContent: (state, action: PayloadAction<{ key: string; tabIndex: number }>) => {
      const { key, tabIndex } = action.payload;
      const updatedTabs = state.tabsContent[key].filter((_, index) => index !== tabIndex);
      if (updatedTabs.length > 0) {
        state.tabsContent[key] = updatedTabs;
      } else {
        delete state.tabsContent[key]; // Remove the key if no tabs left
      }
    },
    resetCombinationData: (state) => {
      state.data = [];
    },
    resetQuestionsArchive: (state) => {
      state.questionsArchive = null;
    }
  },
  extraReducers: (builder) => {
    handleState(fetchCombinationSearch)(builder);
    handleState(getSearchBySessionId)(builder);
    handleState(getSearchByCourseId)(builder);
    handleState(getSearchByCourseIdAndAnswerDataId)(builder);
    handleState(getQuestionArchiveByCourseId)(builder);
    handleState(fetchSearchDeleteMovie)(builder);
    handleState(fetchMovieDeleteMovie)(builder);
    handleState(fetchQuestionDelete)(builder);
  }
});
export const { updateTabContent, removeTabContent, resetCombinationData, resetQuestionsArchive } = combinationSearchSlice.actions;
export default combinationSearchSlice.reducer;