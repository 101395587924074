import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { generateText, generateTextFromVideoUrl, generateTextFromFile } from '../api/serverApis/audioApi';

interface AudioState {
  data: string;
  loading: boolean;
  error: string | null | undefined;
  text: string;
  transcript: string;
}

const initialState: AudioState = {
  data: '',
  loading: false,
  error: null,
  text: '',
  transcript: '',
};

interface UploadAudioArguments {
  userId: string;
  apiKey: string;
  audioChunk: any;
}

export const uploadAudio = createAsyncThunk('audio/uploadAudio', async ({ audio, sessionId, rawNumber, recordName }: { audio: Blob[], sessionId: string, rawNumber: number, recordName: string }) => {
  const audioBlob = new Blob(audio, { type: 'audio/webm;codecs=opus' });
  const formData = new FormData();
  formData.append('audio', audioBlob);
  formData.append('sessionId', sessionId);
  formData.append('apiKey', localStorage.getItem('apiKey') as string);
  formData.append('rawNumber', rawNumber.toString());
  formData.append('fileName', recordName);
  const response = await generateText(formData);
  return response.data;
});

export const uploadAudioFromFile = createAsyncThunk('audio/uploadAudioFromFile', async ({ audio, sessionId }: { audio: Blob[], sessionId: string }) => {
  const audioBlob = new Blob(audio, { type: 'audio/webm;codecs=opus' });
  const formData = new FormData();
  formData.append('audio', audioBlob);
  formData.append('sessionId', sessionId);
  formData.append('apiKey', localStorage.getItem('apiKey') as string);
  const response = await generateTextFromFile(formData);
  return response.data;
});
export interface MovieData {
  url: string;
  name: string;
  additionalInfo: string;
  links: string[];
  isPrivate: boolean;
}
export const uploadVideoUrl = createAsyncThunk('audio/uploadVideoUrl', async ({ videoUrl }: { videoUrl: MovieData[] }) => {
  const response = await generateTextFromVideoUrl(videoUrl);
  return response.data;
});

const handleChatState = (action: any) => {
  return (builder: any) => {
    builder
      .addCase(action.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(action.fulfilled, (state: any, action: PayloadAction<string>) => {
        state.loading = false;
        state.text = action.payload;
      })
      .addCase(action.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
}

const audioSlice = createSlice({
  name: 'audio',
  initialState,
  reducers: {
    setTranscript: (state, action: PayloadAction<string>) => {
      state.transcript = action.payload;
    }
  },
  extraReducers: (builder) => {
    handleChatState(uploadAudio)(builder);
    handleChatState(uploadVideoUrl)(builder);
  },
});
export const { setTranscript } = audioSlice.actions;
export default audioSlice.reducer;

