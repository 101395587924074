import React from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import videoImage from '../images/video.png';
import logoImage from '../images/logo1.png';
import managerImage from '../images/manager.png';
import course from '../images/course.png';
import manual from '../images/manual.png';

interface FeatureProps {
  imageUrl: string;
  buttonText: string;
  buttonDestination: string;
}

const Feature: React.FC<FeatureProps> = ({ imageUrl, buttonText, buttonDestination }) => {
  return (
    <Grid item xs={12} sm={6} md={3} sx={{ textAlign: 'center', border: '1px solid black', marginRight: '10px' }}>
      <img src={imageUrl} alt="Feature" style={{ maxWidth: '100%', height: '150px', marginBottom: '20px' }} />
      <Button
        variant="contained"
        color="primary"
        onClick={() => window.location.href = buttonDestination}
        sx={{ marginBottom: 2 }}
      >
        {buttonText}
      </Button>
    </Grid>
  );
};

const HomePage: React.FC = () => {
  return (
    <Box sx={{ width: '100%', p: 2 }}>
      <Typography variant="h3" component="h1" gutterBottom textAlign="center">
        Welcome to Media-Morph
      </Typography>
      <img src={logoImage} alt="Media-Morph Header" style={{ display: 'block', maxWidth: '10%', height: 'auto', marginBottom: '20px', marginLeft: 'auto', marginRight: 'auto' }} />

      <Grid container spacing={2} justifyContent="center" alignItems="center" >
        <Feature imageUrl={videoImage} buttonText="Go to Video Search" buttonDestination="/videossearch" />
        <Feature imageUrl={course} buttonText="Go to Course Page" buttonDestination="/courseseleted" />
        <Feature imageUrl={managerImage} buttonText="Go to Manager Page" buttonDestination="/manager" />
        <Feature imageUrl={manual} buttonText="Go to Manuals Page" buttonDestination="/manual" />

        {/* Add more features as needed */}
      </Grid>
    </Box>
  );
};

export default HomePage;
