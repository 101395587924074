
import React, { useState, useEffect } from 'react';
import { Autocomplete, Box, TextField } from '@mui/material';


interface AutocompleteComponentProps {
    setMainCategory: any,
    setAudioName: any,
    apiCall: any
    anyColor?: string
}
const AutocompleteComponent: React.FC<AutocompleteComponentProps> = ({ setMainCategory, setAudioName, apiCall, anyColor }) => {
    const [options, setOptions] = useState([]);
    const [itemSearch, setItemSearch] = useState('');


    useEffect(() => {
        // Fetch suggestions when the search term changes
        const fetchSuggestions = async () => {
            if (itemSearch.length >= 3) {
                try {
                    const response = await apiCall(itemSearch);
                    setOptions(response.data);
                } catch (error) {
                    console.error('Error fetching search suggestions:', error);
                }
            }
        };

        const handler = setTimeout(fetchSuggestions, 300); // Debounce the search
        return () => clearTimeout(handler);
    }, [itemSearch]);

    return (
        <Box sx={{
            width: '80%',
            marginTop: '0px', // Adjust this value to move closer to the radio buttons
            marginLeft: '0px', // Centers the box
            marginRight: 'auto'
        }}>
            <Autocomplete
                freeSolo
                options={options.map((option) => option)} // Assuming 'option' has a 'title' field
                inputValue={itemSearch}
                onInputChange={(event, newInputValue) => {
                    setItemSearch(newInputValue);
                    setAudioName(newInputValue);
                    setMainCategory(newInputValue);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Search Single Movie"
                        variant="outlined"
                        size="small"
                        fullWidth
                        sx={{
                            '.MuiOutlinedInput-root': {
                                borderRadius: '20px', // Adds rounded edges                               
                                color: anyColor || 'white', // Text color

                            }
                        }}
                    />
                )}

            />
        </Box>
    )
}

export default AutocompleteComponent;
