import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getManualMovieData, getManualData } from '../api/serverApis/manualsApi';

export interface combinationSearchState {
  data: any;
  manuals: any[];
  contentOfManual: any[];
  selectedVideo: any;
  manualData: any;
  selectedManual: any;
  loading: boolean;
  error: string | null | undefined;
}

const initialState: combinationSearchState = {
  data: null,
  contentOfManual: [],
  selectedVideo: null,
  selectedManual: null,
  manualData: null,
  manuals: [],
  loading: false,
  error: null,
};


export const fetchManualMovieData = createAsyncThunk('manual/fetchManualMovieData', async ({ movieId, id }: { movieId: string, id: string }) => {
  const response = await getManualMovieData(movieId, id); //i have userId get customerId sends is all manuals
  return response.data;
});

export const fetchManualData = createAsyncThunk('manual/fetchManualData', async () => {
  const response = await getManualData(); //i have userId get customerId sends is all manuals
  return response.data;
});

const handleState = (action: any) => {
  return (builder: any) => {
    builder.
      addCase(action.pending, (state: any) => {
        state.loading = true;
      })
      .addCase(action.fulfilled, (state: any, action: PayloadAction<any>) => {
        state.loading = false;
        if (action.type === 'manual/fetchManualMovieData/fulfilled') {
          state.manualData = action.payload;
        }
        if (action.type === 'manual/fetchManualData/fulfilled') {
          state.manualData = action.payload;
        }
      })
      .addCase(action.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.error.message;
      });
  };
}

const manualsSlice = createSlice({
  name: 'manuals',
  initialState: initialState,
  reducers: {
    setSelectedVideoDetails: (state, action: PayloadAction<{ movieId: string, id: string, courseId: string }>) => {
      state.selectedVideo = action.payload;
    },
  },
  extraReducers: (builder) => {
    handleState(fetchManualMovieData)(builder);
    handleState(fetchManualData)(builder);

  }
});
export const { setSelectedVideoDetails } = manualsSlice.actions;
export default manualsSlice.reducer;