import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import logo from '../../images/logo1.png';
import { useTheme } from '@mui/material/styles';
const SearchSpinner = () => {
  const text = "MEDIA - MORPH IS SEARCHING FOR YOUR MEDIA";
  const charWidth = 13; // Approximate width of each character in pixels
  const logoSize = 65; // Height and width of the logo in pixels
  const peakBounceHeight = 30; // Peak height of the bounce
  const letterTopPosition = -2; // Top position above the letter
  const [filter, setFilter] = useState('');
  const [filledLetters, setFilledLetters] = useState(0);
  const initialLeftPosition = -(logoSize / 2) + (charWidth / 2); // Centered over the first letter
  const [logoPosition, setLogoPosition] = useState({ top: -40, left: initialLeftPosition });
  const theme = useTheme();

  const letterColor = theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000';
  const letters = text.split("").map((letter, index) => (
    <span key={index} style={{ color: index < filledLetters ? letterColor : 'transparent' }}>
      {letter}
    </span>
  ));

  useEffect(() => {
    // Update the logo color
    const colorTimer = setInterval(() => {
      setFilter(`hue-rotate(${Math.floor(Math.random() * 180)}deg)`);
    }, 500);

    // Update the filled letters and logo position
    let bounceProgress = 0;
    const textTimer = setInterval(() => {
      setFilledLetters((prev) => {
        const newFilled = prev < text.length ? prev + 1 : 0;
        bounceProgress = 0; // Reset bounce progress
        return newFilled;
      });
    }, 500); // Adjust time as needed

    // Handle the bouncing animation
    const bounceTimer = setInterval(() => {
      if (bounceProgress < 1) {
        bounceProgress += 0.1; // Increment progress
        const bounceHeight = Math.sin(bounceProgress * Math.PI) * peakBounceHeight;
        setLogoPosition({
          top: letterTopPosition - bounceHeight, //+ Math.floor(Math.random() * 10),

          left: (filledLetters * charWidth) + initialLeftPosition
        });
      }
    }, 55); // Adjust time for smoother animation

    return () => {
      clearInterval(colorTimer);
      clearInterval(textTimer);
      clearInterval(bounceTimer);
    };
  }, [filledLetters, text.length]);

  return (
    <Box sx={{
      position: 'relative',
      width: 500, // Width adjusted to fit the entire text
      height: 100,
      textAlign: 'center'
    }}>
      <img
        src={logo}
        alt="Logo"
        style={{
          width: `${logoSize * 1.35}px`,
          height: `${logoSize * 1.1}px`,
          filter: filter,
          position: 'absolute',
          top: `${logoPosition.top}px`,
          left: `${logoPosition.left}px`,
          transition: 'left 0.5s' // Smooth horizontal transition
        }}
      />
      <Box sx={{
        position: 'absolute',
        bottom: '50px',
        left: '60%',
        transform: 'translateX(-50%)',
        fontSize: '20px',
        fontWeight: 'bold',
        whiteSpace: 'nowrap'
      }}>
        {letters}
      </Box>
    </Box>
  );
};

export default SearchSpinner;