import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { fetchTableFromManager } from '../../features/tableSlice';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

export interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'right' | 'left' | 'center';
  clickable?: boolean;
}

interface Row {
  [key: string]: any;
}

interface RowTransformationFunction {
  (row: any): any;
}

interface DynamicTableProps {
  columns: Column[];
  entityName: string;
  title: string;
  onColumnClick?: (id: string, value: any) => void;
  transformRow?: RowTransformationFunction;
}

const GenericTable: React.FC<DynamicTableProps> = ({ columns, entityName, title, onColumnClick, transformRow }) => {
  const dispatch = useDispatch<AppDispatch>();
  const data = useSelector((state: RootState) => state.manager.entities[entityName]);
  const rows = data || [];

  useEffect(() => {
    dispatch(fetchTableFromManager({ tableName: entityName }));
  }, [dispatch, entityName]);


  const transformedRows = transformRow ? rows.map(transformRow) : rows;

  return (
    <div>
      <h2>{title}</h2>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label={`${title} table`}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align || 'left'}
                  style={{ width: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {transformedRows.map((row: any, rowIndex: any) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={rowIndex}>
                {columns.map((column) => {
                  const value = row[column.id];
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      onClick={() => column.clickable && onColumnClick?.(column.id, value)}
                      style={{ cursor: column.clickable ? 'pointer' : 'default' }}
                    >
                      {value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default GenericTable;