import { Grid,TextField, Button, Box, Radio, RadioGroup, FormControl, FormControlLabel, FormLabel, InputAdornment, Typography, Link,IconButton,Switch } from '@mui/material';
import React, { useRef } from 'react';
import DirectionsIcon from '@mui/icons-material/Directions';

interface InteractMainButtonProps {
    item:any,
    onMediaInteraction : (item:any,movieData:any) => void;
    }

const InteractMainButton: React.FC<InteractMainButtonProps> = ({item,onMediaInteraction}) => {
    const handleInteractionMediaClick = (item:any,movieData:any) => {
        onMediaInteraction(item,movieData);
      }
    return (
        <Box sx={{ marginBottom: 2 }}>
          {/* Elliptical background box */}
            <Box 
             onClick={() => handleInteractionMediaClick(null,item)}
            sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 1, 
                backgroundColor: '#E6E6FA', // Lighter purple background
                borderRadius: '50px', // Elliptical shape
                marginRight: '25%',
                marginLeft: '20%',
                padding: '5px 10px', // Adjust padding as needed  
                cursor:'pointer',           
                '&:hover': {
                  backgroundColor: '#D8BFD8', // Slightly darker on hover
                }
              }}>
              <IconButton            
                sx={{ 
                  fontSize: '30px', // Smaller icon size
                  color: '#5D61AA',
                  backgroundColor: 'transparent',
                  padding: 0, // Remove padding from the icon button
                }}
              >
                <DirectionsIcon fontSize="inherit" />
              </IconButton>
              <Typography variant="subtitle1" sx={{ color: '#5D61AA', whiteSpace: 'nowrap',fontSize:'20px', fontWeight:'bold' ,cursor:'pointer'}}>
                Interact with the media
              </Typography>
            </Box>
          </Box>
      );
}

export default InteractMainButton;