import React, { useState, useEffect,useRef } from 'react';
import {Box ,Typography} from '@mui/material';

import { RootState } from '../../store'; // Ensure this import path is correct
import { useSelector } from 'react-redux';
import SearchSpinner from '../transcriptsComponents/searchComponentSpinner';
import { v4 as uuidv4 } from 'uuid';


import MainSearch from './MainSearch';
import QuestionAnswerSearchResult from './QuestionAnswerSearchResult';

interface SearchComponentProps {
  triggerSearchTextFromGrandfatherPage: string;
  onSearch: (searchQuery: string,isImageSearch:boolean,audioName:string,mainCategory:string,addon:string) => void;
  onMediaInteraction : (item:any,movieData:any) => void;
  isSearchFormOn:boolean;
  boxName:string;
  addon:string;
}

interface SearchAnswerPair {
  sessionId: string;
  answer: string;
  answerId:string;
  question:string;
  audioFileName:string;
  title:string;
  voiceLocation:string;
  search:string,

}
const MainSearchComponent: React.FC<SearchComponentProps> = ({ onSearch, triggerSearchTextFromGrandfatherPage,onMediaInteraction,isSearchFormOn,boxName,addon  }) => {
  const textState = useSelector((state: RootState) => state.searchMedia);
 
  const [qaPairs, setQaPairs] = useState<SearchAnswerPair[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [audioName, setAudioName] = useState('');
  const [mainCategory, setMainCategory] = useState('');
 

  useEffect(() => {
    if (triggerSearchTextFromGrandfatherPage==="trigger") {
     // setSearchQuery(triggerSearchTextFromGrandfatherPage);
     triggerSearchTextFromGrandfatherPage="notTriggered";
     setQaPairs([]);
    }
  }, []);


  useEffect(() => {
    if(textState.error){
      setErrorMessage('An error occurred while fetching data.');
      setIsLoading(false);
    }
    if(textState.searchSeinfeld.length===0){
      setQaPairs([]);
    }
    if (textState.searchSeinfeld && textState.searchSeinfeld.movieData) {
      setQaPairs(textState.searchSeinfeld.movieData.flatMap((movieItem: any) => {
        const mainMovie: SearchAnswerPair = {
          sessionId: textState.searchSeinfeld.sessionId,
          answer: movieItem.answer ,
          answerId:movieItem.answerId,
          question:movieItem.question,
          search:movieItem.question,
          audioFileName:movieItem.audioFileName,
          title:movieItem.title,                   
          voiceLocation:movieItem.voiceLocation,         
        };
        setIsLoading(false);
        setErrorMessage('');
        return mainMovie;
      }).reverse());
    }    
  }, [textState.searchSeinfeld, textState.error]);

  


  //// css for search component
  const lightPurple = '#E6E0FA'; // Light purple color, close to white
  const customScrollStyles = {
    '&::-webkit-scrollbar': {
      width: '0px', // Hide scrollbar for Chrome, Safari, and Opera
    },
    scrollbarWidth: 'none', // Hide scrollbar for Firefox
    msOverflowStyle: 'none',  // Hide scrollbar for IE and Edge
  };
  const darkStyles = {
    box: {
      backgroundColor: '#121212', // Dark background for boxes
      color: '#fff', // White text color
    },
    typography: {
      color: lightPurple, // Light purple text color for Typography
    },
    link: {
      color: lightPurple, // Light purple text color for links
    },
    iframeBorder: {
      border: `2px solid ${lightPurple}`, // Light purple border for iframes
    },
    interactButton: {
      color: lightPurple, // Light purple for interact button
      '&:hover': {
        backgroundColor: '#D8D0E8', // Slightly darker light purple on hover
      },
    },
    resultsContainer: {
      overflowY: 'scroll',
      maxHeight: '60vh',
      width: '100%',
      padding: '1rem',
      ...customScrollStyles, // Apply the custom scroll styles
    }
  };
  return (
    <Box sx={{ ...darkStyles.box, display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '0rem', marginRight:'1rem', marginLeft:'1rem' }}>
      <Box sx={{ position: 'relative', top: 0, left: 0, right: 0, zIndex: 100,  marginLeft: '0',  width: '80%' }}>
        <MainSearch onSearch={onSearch} style={darkStyles} setIsLoading={setIsLoading} setErrorMessage={setErrorMessage} audioName={audioName} mainCategory={mainCategory} boxName={boxName} addon={addon} toUseSwitch={false}/>
        {isLoading && <SearchSpinner />}
      {errorMessage && <Typography color="error">{errorMessage}</Typography>}
      </Box>
      <Box sx={{ width: '100%', my: 0 }}  ><hr style={{ width: '100%' }} /></Box>     
      <Box sx={darkStyles.resultsContainer}>      
        {qaPairs.map((item, index) => (
          <Box>
          <audio controls src={item.voiceLocation}></audio>
          <QuestionAnswerSearchResult key={index} item={item}/>   
          </Box>      
        ))}
      </Box>
     
    </Box>
  );

  
};

export default MainSearchComponent;