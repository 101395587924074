import axiosInstance from "../interceptor";
import { CHATGPT_API_URL } from "../api";


const API = "/api/v1/transcript";
const uploadAPi = "/api/v1/upload";
export const generateText = async (formData: FormData) => {
  return axiosInstance.post(`${CHATGPT_API_URL}${API}/generateText`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
export interface MovieData {
  url: string;
  name: string;
  additionalInfo: string;
  links: string[];
  isPrivate: boolean;
}

// export const generateTextFromVideoUrl = async (videoUrl: string, sessionId: string, apiKey: string) => {
//   return axiosInstance.post(`${CHATGPT_API_URL}${API}/generateTextFromVideoUrl`, { videoUrl, sessionId, apiKey });
// }

export const generateTextFromVideoUrl = async (videoUrl: MovieData[]) => {
  return axiosInstance.post(`${CHATGPT_API_URL}${uploadAPi}/uploadVideoUrl`, { videoUrl });
}


export const generateTextFromFile = async (formData: FormData) => {
  return axiosInstance.post(`${CHATGPT_API_URL}${API}/generateTextFromFile`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
