import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import DirectionsIcon from '@mui/icons-material/Directions';

interface InteractWithMediaButtonProps {
    item:any,
    movieDetails:any,
    onMediaInteraction : (item:any,movieData:any) => void;
}
const InteractWithMediaButton:React.FC<InteractWithMediaButtonProps> = ({item,movieDetails,onMediaInteraction}) => {
    const handleInteractionMediaClick = (item:any,movieData:any) => {
        onMediaInteraction(item,movieData);
      }
    return (
        <Box 
        onClick={() => handleInteractionMediaClick(item, movieDetails)}
        sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 1, 
          backgroundColor: '#E6E6FA', // Lighter purple background
          borderRadius: '30px', // Smaller elliptical shape
          padding: '3px 6px', // Smaller padding
          cursor: 'pointer', 
          marginBottom: '5px',
          '&:hover': {
            backgroundColor: '#D8BFD8', // Slightly darker on hover
          },
          width: '85%',
        }}>
          <IconButton 
            sx={{ 
              fontSize: '20px', // Smaller icon size
              color: '#5D61AA',
              backgroundColor: 'transparent',
              padding: 0, // Remove padding from the icon button
            }}
           
          >
            <DirectionsIcon fontSize="inherit" />
          </IconButton>
          <Typography variant="subtitle2" sx={{ color: '#5D61AA', whiteSpace: 'nowrap', cursor: 'pointer' }}>
            Interact
          </Typography>
        </Box>
      );
}

export default InteractWithMediaButton;