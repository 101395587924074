import axiosInstance from "../interceptor";
import { CHATGPT_API_URL } from "../api";
import { FetchResponsePayload } from "../../features/chatGptSlice";

const API = "/api/v1/chatGpt";
export const chatGptChat = async ({ userId, question, apiKey }: FetchResponsePayload) => {
  return axiosInstance.get(`${CHATGPT_API_URL}${API}/response/${userId}/${question}/${apiKey}`);
};

export const getChatGptById = async ({ id }: { id: string }) => {
  return axiosInstance.get(`${CHATGPT_API_URL}${API}/${id}`);
}