import axiosInstance from "../interceptor";
import { AUTH_API_URL, GATEWAY_API_URL } from "../api";

//const API = "/api/v1/auth"; 
const API = '/api/authenticationService/api/v1/auth';

// export const checkAuthenticated = async () => {
//   return axiosInstance.get(`${AUTH_API_URL}${API}/isAuthenticated`);
// };

// export const signIn = async (user: { email: string, password: string, userName: string, firstName: string, lastName: string, phone: string, isBusinessUser: boolean }) => {
//   return axiosInstance.post(`${AUTH_API_URL}${API}/register`, {
//     user
//   });
// };
// export const login = async (email: string, password: string) => {
//   try {
//     return axiosInstance.post(`${AUTH_API_URL}${API}/login`, {
//       email,
//       password,
//     });
//   }
//   catch (error) {
//     console.error(error);
//     throw new Error('ServerSearchMediaService: [login]: ' + error);
//   }
// };
// export const permissions = async () => {
//   try {
//     return axiosInstance.get(`${AUTH_API_URL}${API}/permissions`);
//   }
//   catch (error) {
//     console.error(error);
//     throw new Error('ServerSearchMediaService: [login]: ' + error);
//   }
// }
// export const auth = async (token: string) => {
//   return axiosInstance.get(`${AUTH_API_URL}${API}/auth`);
// }

export const checkAuthenticated = async () => {
  return axiosInstance.get(`${GATEWAY_API_URL}${API}/isAuthenticated`);
};

export const signIn = async (user: { email: string, password: string, userName: string, firstName: string, lastName: string, phone: string, isBusinessUser: boolean }) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${API}/register`, {
    user
  });
};
export const login = async (email: string, password: string) => {
  try {
    return axiosInstance.post(`${GATEWAY_API_URL}${API}/login`, {
      email,
      password,
    });
  }
  catch (error) {
    console.error(error);
    throw new Error('ServerSearchMediaService: [login]: ' + error);
  }
};
export const permissions = async () => {
  try {
    return axiosInstance.get(`${GATEWAY_API_URL}${API}/permissions`);
  }
  catch (error) {
    console.error(error);
    throw new Error('ServerSearchMediaService: [login]: ' + error);
  }
}
export const auth = async (token: string) => {
  return axiosInstance.get(`${GATEWAY_API_URL}${API}/auth`);
}