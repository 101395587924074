import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, RadioGroup, FormControlLabel, Radio, FormControl, FormLabel, Box } from '@mui/material';
import { searchSpecificMovie } from '../../api/serverApis/searchMovieApi';
import { searchForMainCategory } from '../../api/serverApis/searchMovieApi';
import AutocompleteComponent from './AutocompleteComponent';
interface SearchFormProps {
  setAudioName:any,
  setMainCategory:any
}

const SearchForm: React.FC<SearchFormProps> = ({setAudioName,setMainCategory}) => {
    const [selectedOption, setSelectedOption] = useState('allMovies');
    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOption(event.target.value);
        if (event.target.value !== 'singleMovie') {
          setAudioName(''); // Clear the audio name if not 'singleMovie'
          setMainCategory('');
      }
    };
  
    return (
      <FormControl component="fieldset" sx={{ width: '100%', paddingLeft: '10rem' }}>
      <FormLabel component="legend">Movie Search Options:</FormLabel>
      <RadioGroup sx={{ paddingLeft: "2rem" }} row aria-label="movie-options" name="movie-options" value={selectedOption} onChange={handleRadioChange}>
            <FormControlLabel value="allMovies" control={<Radio />} label="All Movies" />
            <FormControlLabel value="mainCategory" control={<Radio />} label="Main Category" />
            <FormControlLabel value="singleMovie" control={<Radio />} label="Single Movie" />
           <FormControlLabel value="personalMovies" control={<Radio />} label="Personal Movies" />
      </RadioGroup>
      {selectedOption === 'singleMovie' && (<AutocompleteComponent setMainCategory = {()=>{}} setAudioName={setAudioName} apiCall={searchSpecificMovie}/>)}
      {selectedOption === 'mainCategory' && (<AutocompleteComponent setMainCategory={setMainCategory} setAudioName={()=>{}}apiCall={searchForMainCategory}/>)}
  </FormControl>
    );
};

export default SearchForm;