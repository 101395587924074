import { Grid, Box, Typography } from '@mui/material';
import React, { useRef } from 'react';
import InteractMainButton from './InteractMainButton';
import SearchResultMiddle from './SearchResultMiddle';
import SearchResultMiddleBottom from './SearchResultMiddleBottom';
import OtherSearchResultSection from './OtherSearchResultSection';
import SearchResultBottom from './SearchResultBottom';
import QuestionAnswerSearchResult from './QuestionAnswerSearchResult';
import DynamicTable from '../generalComponents/DynamicTable';
interface SearchResultsProps {
  item: any,
  index: number,
  onMediaInteraction: (item: any, movieData: any) => void;
  style: any,
  qaPair: any
}

const SearchResults: React.FC<SearchResultsProps> = ({ item, index, onMediaInteraction, style, qaPair }) => {
  const sectionRefs1 = useRef<React.RefObject<HTMLElement>[]>([]);
  const sectionRefs2 = useRef<React.RefObject<HTMLElement>[]>([]);

  return (
    <Box sx={{ marginBottom: '0rem', width: '100%' }} key={item.sessionId}>
      <Grid container spacing={2}>
        {/* Left Column: Question and Answer */}
        <Grid item xs={12} md={6}>
          <QuestionAnswerSearchResult item={item} />
          <DynamicTable data={item.pdfTablesAudioText} />
        </Grid>

        {/* Right Column: Main Video Section */}
        <Grid item xs={12} md={6}>
          <InteractMainButton item={item} onMediaInteraction={onMediaInteraction} />
          <SearchResultMiddle item={item} />
          <SearchResultMiddleBottom item={item} />
        </Grid>
      </Grid>

      {/* Other Search Results Sections */}
      <OtherSearchResultSection title={"Top Accurate Results"} item={item} otherSearchResult={item.semanticsSearchResults} index={index} sectionRef={sectionRefs1} style={style} onMediaInteraction={onMediaInteraction} />
      <OtherSearchResultSection title={"Additional Relevant Results"} item={item} otherSearchResult={item.elasticSearchResult} index={index} sectionRef={sectionRefs2} style={style} onMediaInteraction={onMediaInteraction} />
      <SearchResultBottom qaPair={qaPair} />
      <Box sx={{ width: '100%', my: 3 }}><hr style={{ width: '100%' }} /></Box>
    </Box>
  );
}
export default SearchResults;