import React, { useEffect, useState } from 'react';
import YouTube from 'react-youtube';
import { Box } from '@mui/material';
import { cleanTheDot, secondsToMinutes, roundUpTheTime } from '../../searchComponents/UtilityFunctions';

interface YouTubeComponentProps {
  videoUrl: string;
  startTime?: string;
  endTime?: string;
  frameTime?: string;
  movieLength: string
  toPause: boolean;
  searchTime?: any
}

const YouTubeComponent: React.FC<YouTubeComponentProps> = ({ videoUrl, startTime, endTime, frameTime, movieLength, toPause, searchTime }) => {
  const [loop, setLoop] = useState(false);
  const [currentTime, setCurrentTime] = useState(0); // Add state to track current time
  const [player, setPlayer] = useState<any>(null);
  const videoId = new URL(videoUrl).searchParams.get('v') || videoUrl.split('/').pop();

  const getVideoIdElement = () => {
    return "1111";
    // let starTimeIndex = "0";
    // let videoIdIndex = "0";
    // if (startTime) {
    //   starTimeIndex = startTime;
    // }
    // if (videoId) {
    //   videoIdIndex = videoId;
    // }
    // const videoIdElement = videoIdIndex + starTimeIndex;
    // return videoIdElement;
  }

  const getStatTime = () => {
    const frameTimeIndex = frameTime ? frameTime : '0';
    if (!startTime) {
      startTime = "0";
    }
    if (startTime.toString() === "-1") {
      const time = cleanTheDot(frameTimeIndex);
      return time;
    }
    else {
      const time = cleanTheDot(startTime);
      return time;
    }
  }

  const startTimeCurrent = getStatTime();
  const opts = {
    height: '550px', // Adjust based on your UI design
    width: '100%',  // Adjust based on your UI design
    playerVars: {
      control: 1,
      rel: 0,
      id: getVideoIdElement(),
      autoplay: 1,
      start: getStatTime(),
      end: endTime ? roundUpTheTime(endTime) : undefined,
      modestbranding: 1, // Limit YouTube logo - just shows on the control bar
      iv_load_policy: 3, // Hide video annotations
      origin: `https://youtube.com?${getStatTime()}`
    },
  };

  const onReady = (event: { target: any }) => {
    // if (toPause) {
    //   event.target.pauseVideo();
    // }
    // Access the player from the event
    setPlayer(event.target);
    if (event.target) {
      playFromHere(event.target);
      if (getStatTime()) {
        event.target.seekTo(parseInt(getStatTime(), 10));
      }
    }
    // Ensure the player starts at the specified start time
    // if (startTime) {
    //   const startSeconds = startTimeCurrent;
    //   event.target.seekTo(startSeconds, 10);
    // }
  };
  useEffect(() => {
    // Function to update the current time
    const updateTime = () => {
      if (player) {
        const time = player.getCurrentTime();
        setCurrentTime(time);
        if (searchTime) {
          searchTime(time);
        }
      }
    };
    const intervalId = setInterval(updateTime, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [player]);

  useEffect(() => {
    const checkTime = () => {
      // Ensure the video stops at the specified end time
      if (endTime && currentTime >= parseInt(cleanTheDot(endTime), 10)) {
        // Assuming you have access to the player object here
        // You might need to store the player object from onReady in state or useRef to use it here
      }
    };
    const intervalId = setInterval(checkTime, 1000); // Check every second
    return () => clearInterval(intervalId);
  }, [currentTime, endTime]);


  const onEnd = () => {
    setLoop(true);
  };

  const onStateChange = (event: any) => {
    if (event.target) {
      playFromHere(event.target);
    }
  };

  const playFromHere = (event: any) => {
    const startTime = getStatTime();
    if (startTime && endTime) {
      if (endTime.toString() === "-1") {
        endTime = (Number(startTime) + 10).toString();
      }
      const playerCurrentTime = event.getCurrentTime();
      if (playerCurrentTime >= Number(endTime)) {
        event.seekTo(startTime);
      }
      if (playerCurrentTime <= Number(startTimeCurrent)) {
        event.seekTo(startTime);
      }
    }
  }

  useEffect(() => {
    if (loop) {
      const iframe = document.getElementById(getVideoIdElement()) as HTMLIFrameElement;
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.postMessage('{"event":"command","func":"seekTo","args":[' + opts.playerVars.start + ', true]}', '*');
        setLoop(false);
      }
    }
  }, [loop, opts.playerVars.start]);

  useEffect(() => {
    const iframe = document.getElementById(getVideoIdElement()) as HTMLIFrameElement;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage('{"event":"command","func":"seekTo","args":[' + opts.playerVars.start + ', true]}', '*');
    }
  }, [opts.playerVars.start]);

  return (
    <Box>
      <YouTube videoId={videoId} opts={opts} onEnd={onEnd} onStateChange={onStateChange} onReady={onReady} id={getVideoIdElement()} />
    </Box>
  );
};

export default YouTubeComponent;
