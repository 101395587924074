import React, { useState, useEffect, useMemo } from 'react';
import { fetchQuestionMonitoring } from '../features/monitoringSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../store';
import { Box, Typography, Paper, Grid, MenuItem, Select, FormControl, InputLabel, TextField } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const MonitoringPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const monitoringTables = useSelector((state: RootState) => state.monitoring.data);
  const [selectedQuestionId, setSelectedQuestionId] = useState<string>('');
  const [selectedUserId, setSelectedUserId] = useState<string>('');
  const [questionFilter, setQuestionFilter] = useState<string>('');
  const [userFilter, setUserFilter] = useState<string>('');

  useEffect(() => {
    dispatch(fetchQuestionMonitoring());
  }, [dispatch]);

  const monitoringData = useMemo(() => monitoringTables?.monitoringData || [], [monitoringTables]);
  const monitoringUserData = useMemo(() => monitoringTables?.monitoringUserData || [], [monitoringTables]);

  const filteredMonitoringData = useMemo(() => {
    return monitoringData.filter((item: any) =>
      item.questionId.toLowerCase().includes(questionFilter.toLowerCase())
    );
  }, [monitoringData, questionFilter]);

  const filteredUserData = useMemo(() => {
    return monitoringUserData.filter((item: any) =>
      item.questionId.toLowerCase().includes(userFilter.toLowerCase())
    );
  }, [monitoringUserData, userFilter]);

  useEffect(() => {
    if (selectedQuestionId) {
      const questionStats = monitoringData.find(
        (question: any) => question.questionId === selectedQuestionId
      );
      if (questionStats) {
        setSelectedQuestionStats(calculateStatistics([questionStats], monitoringKeys));
      }
    }
  }, [monitoringData, selectedQuestionId]);

  useEffect(() => {
    if (selectedUserId) {
      const userStats = monitoringUserData.filter(
        (user: any) => user.userId === selectedUserId
      );
      if (userStats.length > 0) {
        setSelectedUserStats(calculateStatistics(userStats, userKeys));
      }
    }
  }, [monitoringUserData, selectedUserId]);

  const [selectedQuestionStats, setSelectedQuestionStats] = useState<any[]>([]);
  const [selectedUserStats, setSelectedUserStats] = useState<any[]>([]);

  const monitoringColumns: GridColDef[] = [
    { field: 'questionId', headerName: 'Question ID', width: 200 },
    { field: 'articleId', headerName: 'Article ID', width: 200 },
    { field: 'topicNumber', headerName: 'Topic Number', width: 150 },
    { field: 'dateTime', headerName: 'date', width: 150 },
    { field: 'problemVoteNumber', headerName: 'Problem Vote', width: 150 },
    { field: 'typoVoteNumber', headerName: 'Typo Vote', width: 150 },
    { field: 'answerNotGoodVoteNumber', headerName: 'Answer Not Good Vote', width: 200 },
    { field: 'questionNotGoodVoteNumber', headerName: 'Question Not Good Vote', width: 200 },
    { field: 'easyQuestionVoteNumber', headerName: 'Easy Question Vote', width: 200 },
    { field: 'mediumQuestionVoteNumber', headerName: 'Medium Question Vote', width: 200 },
    { field: 'hardQuestionVoteNumber', headerName: 'Hard Question Vote', width: 200 },
    { field: 'didntSolveVoteNumber', headerName: 'Didn\'t Solve Vote', width: 200 },
    { field: 'solvedVoteNumber', headerName: 'Solved Vote', width: 150 },

  ];

  const userColumns: GridColDef[] = [
    { field: 'questionId', headerName: 'Question ID', width: 200 },
    { field: 'articleId', headerName: 'Article ID', width: 200 },
    { field: 'topicNumber', headerName: 'Topic Number', width: 150 },
    { field: 'dateTime', headerName: 'date', width: 150 },
    { field: 'problemVote', headerName: 'Problem Vote', width: 150 },
    { field: 'typoVote', headerName: 'Typo Vote', width: 150 },
    { field: 'answerNotGoodVote', headerName: 'Answer Not Good Vote', width: 200 },
    { field: 'questionNotGoodVote', headerName: 'Question Not Good Vote', width: 200 },
    { field: 'easyQuestionVote', headerName: 'Easy Question Vote', width: 200 },
    { field: 'mediumQuestionVote', headerName: 'Medium Question Vote', width: 200 },
    { field: 'hardQuestionVote', headerName: 'Hard Question Vote', width: 200 },
    { field: 'didntSolveVote', headerName: 'Didn\'t Solve Vote', width: 200 },
    { field: 'solvedVote', headerName: 'Solved Vote', width: 150 },
  ];

  const calculateStatistics = (data: any[], keys: string[]) => {
    return keys.map((key) => ({
      name: key,
      value: data.reduce((acc: number, item: any) => acc + item[key], 0),
    }));
  };

  const monitoringKeys = [
    'problemVoteNumber',
    'typoVoteNumber',
    'answerNotGoodVoteNumber',
    'questionNotGoodVoteNumber',
    'easyQuestionVoteNumber',
    'mediumQuestionVoteNumber',
    'hardQuestionVoteNumber',
    'didntSolveVoteNumber',
    'solvedVoteNumber',
  ];

  const userKeys = [
    'problemVote',
    'typoVote',
    'answerNotGoodVote',
    'questionNotGoodVote',
    'easyQuestionVote',
    'mediumQuestionVote',
    'hardQuestionVote',
    'didntSolveVote',
    'solvedVote',
  ];

  const overallStatistics = useMemo(() => calculateStatistics(monitoringData, monitoringKeys), [monitoringData]);
  const userStatistics = useMemo(() => calculateStatistics(monitoringUserData, userKeys), [monitoringUserData]);

  const questionsWithIssues = useMemo(() => monitoringData.filter(
    (question: any) =>
      question.typoVoteNumber > 0 || question.answerNotGoodVoteNumber > 0 || question.questionNotGoodVoteNumber > 0
  ), [monitoringData]);

  return (
    <Box p={2}>
      <Typography variant="h4" gutterBottom>
        Monitoring Data
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            label="Filter Questions"
            variant="outlined"
            fullWidth
            value={questionFilter}
            onChange={(e) => setQuestionFilter(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={filteredMonitoringData}
              columns={monitoringColumns}
              pageSizeOptions={[5, 10, 20]}
              pagination
              checkboxSelection
              getRowId={(row) => row.questionId}
            />
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Filter Users"
            variant="outlined"
            fullWidth
            value={userFilter}
            onChange={(e) => setUserFilter(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={filteredUserData}
              columns={userColumns}
              pageSizeOptions={[5, 10, 20]}
              pagination
              checkboxSelection
              getRowId={(row) => row.questionId}
            />
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            Overall Statistics
          </Typography>
          <Paper style={{ padding: '16px' }}>
            <BarChart
              width={600}
              height={300}
              data={overallStatistics}
              margin={{
                top: 20, right: 30, left: 20, bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="value" fill="#8884d8" />
            </BarChart>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            User Statistics
          </Typography>
          <Paper style={{ padding: '16px' }}>
            <BarChart
              width={600}
              height={300}
              data={userStatistics}
              margin={{
                top: 20, right: 30, left: 20, bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="value" fill="#82ca9d" />
            </BarChart>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Select Question</InputLabel>
            <Select
              value={selectedQuestionId}
              onChange={(e) => setSelectedQuestionId(e.target.value as string)}
              label="Select Question"
            >
              {monitoringData.map((question: any) => (
                <MenuItem key={question.questionId} value={question.questionId}>
                  {question.questionId}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {selectedQuestionId && (
            <>
              <Typography variant="h5" gutterBottom>
                Statistics for Question ID: {selectedQuestionId}
              </Typography>
              <Paper style={{ padding: '16px', marginBottom: '16px' }}>
                <BarChart
                  width={600}
                  height={300}
                  data={selectedQuestionStats}
                  margin={{
                    top: 20, right: 30, left: 20, bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="value" fill="#8884d8" />
                </BarChart>
              </Paper>
            </>
          )}
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Select User</InputLabel>
            <Select
              value={selectedUserId}
              onChange={(e) => setSelectedUserId(e.target.value as string)}
              label="Select User"
            >
              {Array.from(new Set(monitoringUserData.map((user: any) => user.userId))).map((userId: any) => (
                <MenuItem key={userId} value={userId}>
                  {userId}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {selectedUserId && (
            <>
              <Typography variant="h5" gutterBottom>
                Statistics for User ID: {selectedUserId}
              </Typography>
              <Paper style={{ padding: '16px', marginBottom: '16px' }}>
                <BarChart
                  width={600}
                  height={300}
                  data={selectedUserStats}
                  margin={{
                    top: 20, right: 30, left: 20, bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="value" fill="#8884d8" />
                </BarChart>
              </Paper>
            </>
          )}
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            Questions with Issues
          </Typography>
          <Paper style={{ padding: '16px' }}>
            <ul>
              {questionsWithIssues.map((question: any) => (
                <li key={question.questionId}>
                  {`Question ID: ${question.questionId}, Typo Votes: ${question.typoVoteNumber}, Answer Not Good Votes: ${question.answerNotGoodVoteNumber}, Question Not Good Votes: ${question.questionNotGoodVoteNumber}`}
                </li>
              ))}
            </ul>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MonitoringPage;
