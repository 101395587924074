import React, { useEffect, useRef, useState } from 'react';
import { Box, CardContent, Typography, Stack, useTheme, Paper, Accordion, AccordionSummary, AccordionDetails, Button } from '@mui/material';
import { cleanTheDot, secondsToMinutes, roundUpTheTime } from '../../searchComponents/UtilityFunctions';
import YouTubeComponent from '../mainComponenets/YouTubeComponent';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PDFViewer from '../../generalComponents/PDFViewer';
interface MainVideoProps {
  videoUrl: string;
  videoData: any;
  onSearch: (searchTerm: string, time?: string, articleId?: string) => void;
  setIsLoading?: any;
}

const MainVideoForManual: React.FC<MainVideoProps> = ({ videoUrl, videoData, onSearch, setIsLoading }) => {
  const theme = useTheme();
  const audioRef = useRef<HTMLAudioElement>(null);
  const [audioTime, setAudioTime] = useState<number>(0);
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [loop, setLoop] = useState(false);
  const [searchTime, setSearchTime] = useState<string>("");
  const [iframeUrl, setIframeUrl] = useState('');
  useEffect(() => {
    const audioEl = new Audio(videoUrl);
    setAudio(audioEl);

    const updateAudioTime = () => setAudioTime(audioEl.currentTime);
    audioEl.addEventListener('timeupdate', updateAudioTime);

    return () => {
      audioEl.removeEventListener('timeupdate', updateAudioTime);
    };
  }, [videoUrl]);


  useEffect(() => {
    // This effect ensures that iframe URL is set only once when videoData is available
    if (videoData.startTime && videoData.startTime !== '-1') {
      setIframeUrl(`${videoUrl}#page=${videoData.startTime}`);
    }
    if (videoData.startTime === '-1' && videoData.pageNumber) {
      setIframeUrl(`${videoUrl}#page=${videoData.pageNumber}`);
    }
  }, [videoData.startTime, videoData.pageNumber]);
  const movieDetails = () => {
    return (
      <CardContent sx={{ overflow: 'hidden' }}>
        <Box sx={{
          marginBottom: '10rem',
          overflowY: 'auto',
          width: '50rem',
          backgroundColor: theme.palette.background.default,
        }}>
          <Stack direction="row" alignItems="flex-start"  >
            <Paper elevation={3} sx={{
              padding: theme.spacing(1),
              backgroundColor: theme.palette.background.default,
              border: `2px solid ${theme.palette.primary.light}`,
              borderRadius: theme.shape.borderRadius,

            }}>
              <Typography variant="body1" sx={{ fontSize: '0.9rem' }}>
                {videoData.summaryText}
              </Typography>
            </Paper>
          </Stack>
          {videoData.description && <Typography sx={{ marginTop: '2rem' }} variant="body2" color="text.secondary">Description: {videoData.description}</Typography>}
        </Box>
      </CardContent>
    )
  }
  const determineContentType = (url: string) => {
    if (!url) return 'website';
    if (url.includes('youtube.com') || url.includes('youtu.be')) {
      return 'youtube';
    }
    else if (url.includes('.mp3')) {
      return 'mp3';
    }
    else if (url.includes('.pdf')) {
      return 'pdf';
    }
    else {
      return 'website';
    }
  };
  const onSearchClick = () => {
    setIsLoading(true);
    onSearch("", searchTime, videoData.articleId);
  }
  const renderContent = () => {
    const contentType = determineContentType(videoUrl);
    let content;

    switch (contentType) {
      case 'pdf':
        if (videoData.startTime) {
          content = (
            <iframe
              key={iframeUrl}
              src={iframeUrl}
              onLoad={() => console.log('Iframe loaded successfully')}
              onError={() => console.log('Error loading the iframe')}
              frameBorder="0"
              allowFullScreen
              style={{ width: '100%', height: '100%' }}

            ></iframe>
            // <PDFViewer fileUrl={videoUrl} />
          );
        }
        // <PDFViewer fileUrl={videoUrl} />
        break;
      case 'youtube':
        content = <YouTubeComponent videoUrl={videoUrl} startTime={videoData.startTime} endTime={videoData.endTime} frameTime={videoData.frameTime} movieLength={videoData.audioLength} toPause={videoData.toPause} searchTime={setSearchTime} />
        break;
      case 'mp3':
        content = (
          <audio controls style={{ width: '100%' }}>
            <source src={videoUrl} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        );
        break;
      case 'website':
        content = (
          <iframe
            src={videoUrl}
            frameBorder="0"
            allowFullScreen
            style={{ width: '100%', height: '100%' }}
          ></iframe>
        );
        break;
      default:
        content = <Typography>Unsupported media type</Typography>;
    }

    return content;
  };
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ position: 'absolute', bottom: "17.5%", left: "40%", zIndex: '1500' }}>
        <Button sx={{ width: '100%' }} onClick={onSearchClick}> Explain This Section </Button>
      </Box>
      <Box sx={{ position: 'relative', height: '32.5rem', overflow: 'hidden', marginTop: '0.05rem', borderRadius: '10px' }}>
        {renderContent()}
      </Box>

      {/* Accordion for Movie Details */}
      <Accordion sx={{ mt: 1 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ height: '0.3rem', backgroundColor: theme.palette.background.default, }}

        >
          <Typography sx={{ fontSize: "0.8rem" }}>  {videoData.title}</Typography>
          <Typography sx={{ fontSize: "0.8rem", marginLeft: '75%' }}>  More Details </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ backgroundColor: theme.palette.background.default }}>
          {movieDetails()}
        </AccordionDetails>
      </Accordion>

    </Box>
  );
};

export default MainVideoForManual;