import axios from 'axios';
import { getToken } from '../storage';
import { AppDispatch } from '../store';
import { useDispatch } from 'react-redux';
import { logout } from '../features/userSlice';
const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(async (request) => {

  const addTokensToRequest = (tokenName: string, tokenValue: string) => {
    const tokenWithBearer = tokenValue.startsWith('Bearer ') ? tokenValue : `Bearer ${tokenValue}`;
    if (tokenName === 'accessToken') {
      request.headers.Authorization = tokenWithBearer;
    }
    if (tokenName === 'refreshToken') {
      request.headers.RefreshToken = tokenWithBearer;
    }
    localStorage.setItem(tokenName, tokenWithBearer);
  };
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');
  if (!accessToken) {
    return request;
  }
  addTokensToRequest('accessToken', accessToken as string);
  addTokensToRequest('refreshToken', refreshToken as string);
  // if (!token && request.url?.indexOf('/api/v1/auth/login') === -1 && request.url.indexOf('/api/v1/auth/register') === -1) {
  //   const dispatch = useDispatch<AppDispatch>();
  //   dispatch(logout());
  //   window.location.href = '/login';
  // }

  return request;
}
);

axiosInstance.interceptors.response.use((response) => {
  const storeTokenWithBearer = (tokenName: string, tokenValue: string) => {
    const tokenWithBearer = tokenValue.startsWith('Bearer ') ? tokenValue : `Bearer ${tokenValue}`;
    localStorage.setItem(tokenName, tokenWithBearer);
  };

  // Extracting tokens from response headers
  // const accessToken = response.headers['accesstoken'];
  // const refreshToken = response.headers['refreshtoken'];

  // if (accessToken) {
  //   storeTokenWithBearer('accessToken', accessToken);
  // }
  // if (refreshToken) {
  //   storeTokenWithBearer('refreshToken', refreshToken);
  // }
  return response;
},
  async (error) => {
    // if (error.response.status === 401 || error.response.status === 403 || error.response.status === 500 || error.response.status === 404) {
    //   localStorage.removeItem('token');
    //   const dispatch = useDispatch<AppDispatch>();
    //   dispatch(logout());
    //   window.location.href = '/login';
    // }
    if (error.response.status >= 400 && error.response.status < 500) {
      return Promise.reject(error.response.data.message);
    }
    else {
      return Promise.reject(error);
    }
  });

export default axiosInstance;