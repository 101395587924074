import React, { ChangeEvent, FC } from 'react';
import { Button, Box } from '@material-ui/core';

interface MP3FileUploadProps {
  onUpload: (file: File) => void;
}

export const MP3Upload: FC<MP3FileUploadProps> = ({ onUpload }) => {

  const handleUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      onUpload(file);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gridGap: 3 }}>
      <input
        accept="audio/*,video/*"
        id="contained-button-file"
        type="file"
        hidden
        onChange={handleUpload}
      />
      <label htmlFor="contained-button-file">
        <Button variant="contained" component="span" color="primary">
          Upload MP3
        </Button>
      </label>
    </Box>
  );
};
