

// getTheme.ts
import { createTheme } from '@mui/material/styles';

const getTheme = (mode: 'light' | 'dark') => {
  return createTheme({
    palette: {
      mode: mode,
      // You can customize your theme colors here
      primary: {
        main: mode === 'dark' ? '#90caf9' : '#1976d2',
      },
      secondary: {
        main: mode === 'dark' ? '#f48fb1' : '#d81b60',
      },
      background: {
        default: mode === 'dark' ? '#121212' : '#eeeeee',
        paper: mode === 'dark' ? '#424242' : '#ffffff',
      },
      text: {
        primary: mode === 'dark' ? '#ffffff' : '#000000',
      },
      // Add more customizations as needed
    },
    // You can also customize other theme aspects like typography, breakpoints, etc.
  });
};

export default getTheme;
