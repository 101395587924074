
import React, { useState } from 'react';
import { TextField, InputAdornment, useTheme, Box, IconButton, Autocomplete, Chip, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { styled } from '@mui/system';
interface SearchProps {
  onSearch: any;
  isLoading?: boolean;
  placeholder?: string;
}
const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused': {
      borderColor: 'transparent',
      boxShadow: 'none',
    },
    '&:hover': {
      borderColor: 'transparent',
    },
  },
  '& .MuiAutocomplete-inputRoot': {
    '&.Mui-focused': {
      borderColor: 'transparent',
      boxShadow: 'none',
    },
    '&:hover': {
      borderColor: 'transparent',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  '& .MuiOutlinedInput-input': {
    '&:hover': {
      cursor: 'default',
    },
  },
});
const SearchBox: React.FC<SearchProps> = ({ onSearch, isLoading, placeholder }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchHistory, setSearchHistory] = useState<string[]>([]);
  const theme = useTheme();
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = () => {

    onSearch(searchTerm);
    if (!searchHistory.includes(searchTerm)) {
      setSearchHistory([...searchHistory, searchTerm]); // Add to search history if not already present
    }
    setSearchTerm('');
  };
  const handleClearInput = () => {
    setSearchTerm(''); // Clear the input field
  };
  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); // Prevent the default action to stop from going to the next line
      handleSearchSubmit();
    } else if (event.key === 'Enter' && event.shiftKey) {
      // Allow Shift+Enter to go to the next line without triggering search
    }
  };
  const handleDeleteHistoryItem = (item: string) => () => {
    setSearchHistory(searchHistory.filter(historyItem => historyItem !== item));
  };

  const clearAllHistory = () => {
    setSearchHistory([]);
  };
  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Autocomplete
        spellCheck={true}
        freeSolo
        options={searchHistory}
        onInputChange={(_, newValue) => setSearchTerm(newValue)}
        inputValue={searchTerm}
        sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            fullWidth
            spellCheck={true}
            variant="outlined"
            placeholder={placeholder}
            onChange={handleSearchChange}
            onKeyPress={handleKeyPress}
            multiline
            minRows={1}
            maxRows={4}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  {searchTerm && (
                    <IconButton onClick={handleClearInput} aria-label="clear">
                      <ClearIcon />
                    </IconButton>
                  )}{isLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <IconButton onClick={handleSearchSubmit} aria-label="search">
                      <SearchIcon style={{ cursor: 'pointer' }} />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
              style: { borderRadius: 0, background: theme.palette.background.paper },
            }}
            sx={{ flexGrow: 1 }}
          />
        )}
        renderOption={(props, option) => (
          <li {...props}>
            <Chip
              label={option}
              onDelete={handleDeleteHistoryItem(option)}
              deleteIcon={<DeleteIcon />}
            />
          </li>
        )}
      />

    </Box>
  );
};

export default SearchBox;