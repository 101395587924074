import React, { useEffect, useState } from 'react';
import VideoSemantic from '../../VideoSemantic';
import VideoAnswer from '../../VideoAnswer';
import VideoIndex from '../../VideoIndex';
import { Box, Grid, Typography, AppBar, Toolbar, Slider, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import { Semantic, Index } from '../../configuration/Interface';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ContentCutIcon from '@mui/icons-material/ContentCut'; // Icon for Snipping Tool
import CopyAllIcon from '@mui/icons-material/ContentCopy'; // Icon for Copying all content
import QuillEditor from './QuillEditor';

interface SpreadTilesProps {
  data: any;
  theme: any;
}

const SpreadTilesComponent: React.FC<SpreadTilesProps> = ({ data, theme }) => {
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [tilesPerRow, setTilesPerRow] = useState(3); // Default to 2 tiles per row
  const [boxSize, setBoxSize] = useState(300); // Default box size
  const [openEditor, setOpenEditor] = useState(false); // State to handle the popup
  const [editorContent, setEditorContent] = useState<string>(() => localStorage.getItem('editorContent') || ''); // Load content from cache
  const isHebrew = (text: string) => /[\u0590-\u05FF\uFB1D-\uFB4F\uFB50-\uFDFF]/.test(text);
  const borderColor = theme.palette.mode === 'dark' ? 'white' : 'black';

  useEffect(() => {
    if (data && data.answerData) {
      const firstNonEmptySemanticIndex = data.answerData.findIndex((item: any) =>
        Array.isArray(item.movies) && item.movies.some((movie: any) => movie?.semantic && movie?.semantic.length > 0)
      );
      const firstAnswerIfEmptyIndex = data.answerData.findIndex((item: any) => item.answer && item.answer.answer);

      let filteredItems = [];

      if (firstNonEmptySemanticIndex !== -1) {
        filteredItems.push(data.answerData[firstNonEmptySemanticIndex]);
      } else if (firstAnswerIfEmptyIndex !== -1) {
        filteredItems.push(data.answerData[firstAnswerIfEmptyIndex]);
      }

      setFilteredData(filteredItems);
    }
  }, [data.answerData]);

  const handleTilesPerRowChange = (event: any, value: number | number[]) => {
    setTilesPerRow(value as number);
  };

  const handleBoxSizeChange = (increase: boolean) => {
    setBoxSize(prevSize => (increase ? prevSize + 20 : prevSize - 20));
  };

  const handleEditorContentChange = (content: string) => {
    setEditorContent(content);
    localStorage.setItem('editorContent', content); // Save content to cache
  };

  const handleSnipTool = () => {
    // Placeholder function for the snipping tool
    alert('Snipping tool clicked!');
  };

  const copyToEditor = () => {
    let content = '';

    filteredData.forEach((item: any) => {
      content += `<h2>Q: ${item.answer.question}</h2>`;

      item.movies.forEach((movie: any) => {
        if (movie.semantic) {
          movie.semantic.forEach((semantic: Semantic) => {
            content += `<h3>Answer: ${semantic.title}</h3>`;
            content += `<p>${semantic.description}</p>`;
            content += `<img src="${semantic.pageImage}" alt="${semantic.title}" style="max-width: 100%"/>`;
          });
        }
        if (movie.index) {
          movie.index.forEach((indexItem: Index) => {
            content += `<h3>Answer: ${indexItem.title}</h3>`;
            content += `<p>${indexItem.description}</p>`;
            content += `<img src="${indexItem.pageImage}" alt="${indexItem.title}" style="max-width: 100%"/>`;
          });
        }
      });

      content += `<h3>Answer Text</h3><p>${item.answer.answer}</p>`;
    });

    setEditorContent(content);
    setOpenEditor(true);
  };

  const renderTiles = () => {
    return filteredData.length > 0 ? filteredData.map((item: any, index: number) => (
      <React.Fragment key={index}>
        {/* Question as Header */}
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ fontWeight: 'bold', direction: isHebrew(item.answer.question) ? 'rtl' : 'ltr', marginBottom: '20px', textAlign: 'center' }}>
            Q: {item.answer.question}
          </Typography>
        </Grid>

        {/* VideoSemantic */}
        {item.movies && item.movies.map((movie: any, mIndex: number) => (
          <React.Fragment key={mIndex}>
            {movie.semantic && movie.semantic.map((semantic: Semantic, sIndex: number) => (
              <Grid item xs={12 / tilesPerRow} key={sIndex} display="flex" justifyContent="center">
                <Box sx={{ padding: '10px', border: '1px solid', borderColor: borderColor, borderRadius: '8px', marginBottom: '10px', backgroundColor: theme.palette.background.paper, width: '100%', maxWidth: `${boxSize}px` }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold', direction: isHebrew(item.answer.question) ? 'rtl' : 'ltr' }}>
                    {isHebrew(item.answer.question) ? `תימוכין ${sIndex + 1}:` : `Reference ${sIndex + 1}:`}
                  </Typography>
                  <VideoSemantic data={semantic} isOpen={false} />
                </Box>
              </Grid>
            ))}
          </React.Fragment>
        ))}

        {/* VideoIndex */}
        {item.movies && item.movies.map((movie: any, mIndex: number) => (
          <React.Fragment key={mIndex}>
            {movie.index && movie.index.map((indexItem: Index, iIndex: number) => (
              <Grid item xs={12 / tilesPerRow} key={iIndex} display="flex" justifyContent="center">
                <Box sx={{ padding: '10px', border: '1px solid', borderColor: borderColor, borderRadius: '8px', marginBottom: '10px', backgroundColor: theme.palette.background.paper, width: '100%', maxWidth: `${boxSize}px` }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold', direction: isHebrew(item.answer.question) ? 'rtl' : 'ltr' }}>
                    {isHebrew(item.answer.question) ? `תשובה ${iIndex + 1}:` : `Answer ${iIndex + 1}:`}
                  </Typography>
                  <VideoIndex question={item.answer.question} data={indexItem} isOpen={false} />
                </Box>
              </Grid>
            ))}
          </React.Fragment>
        ))}

        {/* VideoAnswer (Full-Width Row) */}
        <Grid item xs={12}>
          <Box sx={{ padding: '10px', border: '1px solid', borderColor: borderColor, borderRadius: '8px', marginBottom: '10px', backgroundColor: theme.palette.background.paper, width: '100%' }}>
            <VideoAnswer item={item.answer} data={data} />
          </Box>
        </Grid>
      </React.Fragment>
    )) : null;
  };

  return (
    <Box sx={{ position: 'relative', paddingBottom: '50px' }}>
      <Box sx={{ margin: 'auto', mt: 4, padding: '20px', overflowY: 'auto', maxWidth: '1000px' }}>
        <Grid container spacing={2} justifyContent="center">
          {renderTiles()}
        </Grid>
      </Box>

      {/* Fixed Bottom Menu */}
      <AppBar position="fixed" color="default" sx={{ top: 'auto', bottom: 0, boxShadow: '0px -2px 10px rgba(0,0,0,0.1)' }}>
        <Toolbar sx={{ backgroundColor: theme.palette.background.default }}>
          <Typography variant="caption" sx={{ marginRight: '10px' }}>Tiles per row:</Typography>
          <Slider
            value={tilesPerRow}
            onChange={handleTilesPerRowChange}
            step={1}
            marks
            min={1}
            max={8}
            valueLabelDisplay="auto"
            sx={{ width: '200px', marginRight: '20px' }}
          />
          <IconButton onClick={() => handleBoxSizeChange(false)} color="primary">
            <RemoveIcon />
          </IconButton>
          <Typography variant="caption">Size</Typography>
          <IconButton onClick={() => handleBoxSizeChange(true)} color="primary">
            <AddIcon />
          </IconButton>
          <IconButton onClick={handleSnipTool} color="secondary">
            <ContentCutIcon />
          </IconButton>
          <IconButton onClick={copyToEditor} color="secondary">
            <CopyAllIcon />
          </IconButton>
          <Button variant="contained" color="primary" onClick={() => setOpenEditor(true)} sx={{ marginLeft: '20px' }}>
            Open Text Editor
          </Button>
        </Toolbar>
      </AppBar>

      {/* Text Editor Popup */}
      <Dialog
        open={openEditor}
        onClose={() => setOpenEditor(false)}
        maxWidth="md"
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            width: '50%', // Make the dialog take up 50% of the screen width
            right: 0, // Position it on the right side
            margin: 0,
          },
        }}
      >
        <DialogTitle>Text Editor</DialogTitle>
        <DialogContent>
          <QuillEditor value={editorContent} onChange={handleEditorContentChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditor(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SpreadTilesComponent;
