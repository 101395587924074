import React from 'react';
import { Box, Slider, IconButton, Typography, Tooltip, MenuItem, Menu, ListItemText } from '@mui/material';
import { PlayArrow, Pause, Speed, VolumeOff, VolumeUp, Quiz, SkipNext, SkipPrevious, Menu as MenuIcon } from '@mui/icons-material';

interface PlayerControlsProps {
  playing: boolean;
  playedSeconds: number;
  startTimeCurrent: string;
  endTimeCurrent: string;
  duration: number;
  volume: number;
  muted: boolean;
  playbackRate: number;
  anchorEl: HTMLElement | null;
  menuAnchorEl: HTMLElement | null;
  hoveredTime: number | null;
  handlePlayPause: () => void;
  handleVolumeClick: () => void;
  handleVolumeChange: (event: Event, newValue: number | number[]) => void;
  handleSeekChange: (event: Event, newValue: number | number[]) => void;
  handleSliderHover: (event: React.MouseEvent<HTMLDivElement>) => void;
  handleSliderLeave: () => void;
  handleMenuClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleMenuClose: () => void;
  handlePlaybackRateChange: (rate: number) => void;
  handleMovieMenuClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleMovieMenuClose: () => void;
  handleNextVideo: () => void;
  handlePreviousVideo: () => void;
  handleTakeTest: () => void;
  handleMovieSelect: (movie: any) => void;
  movieList: any[];
}

const PlayerControls: React.FC<PlayerControlsProps> = ({
  playing,
  playedSeconds,
  startTimeCurrent,
  endTimeCurrent,
  duration,
  volume,
  muted,
  playbackRate,
  anchorEl,
  menuAnchorEl,
  hoveredTime,
  handlePlayPause,
  handleVolumeClick,
  handleVolumeChange,
  handleSeekChange,
  handleSliderHover,
  handleSliderLeave,
  handleMenuClick,
  handleMenuClose,
  handlePlaybackRateChange,
  handleMovieMenuClick,
  handleMovieMenuClose,
  handleNextVideo,
  handlePreviousVideo,
  handleTakeTest,
  handleMovieSelect,
  movieList,
}) => {
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  return (
    <Box sx={{ position: 'absolute', bottom: 0, width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.3)', color: 'white', padding: 1 }}>
      <Box
        onMouseMove={handleSliderHover}
        onMouseLeave={handleSliderLeave}
        sx={{ flexGrow: 1, mx: 2, position: 'relative', marginTop: '10px' }}
      >
        <Tooltip
          title={hoveredTime !== null ? formatTime(hoveredTime) : ''}
          open={hoveredTime !== null}
          arrow
          placement="top"
        >
          <Slider
            value={playedSeconds}
            min={parseInt(startTimeCurrent, 10)}
            max={endTimeCurrent ? parseInt(endTimeCurrent, 10) : duration}
            onChange={handleSeekChange}
            aria-labelledby="continuous-slider"
            sx={{
              color: 'white',
              '& .MuiSlider-thumb': {
                color: 'white',
              },
              '& .MuiSlider-track': {
                color: 'white',
              },
              '& .MuiSlider-rail': {
                color: 'white',
              },
            }}
          />
        </Tooltip>
      </Box>
      <Box display="flex" alignItems="center" gap={2}>
        <Tooltip title="Previous Video" arrow>
          <IconButton onClick={handlePreviousVideo} color="primary" size="small">
            <SkipPrevious fontSize="small" />
          </IconButton>
        </Tooltip>
        <IconButton onClick={(e) => { e.stopPropagation(); handlePlayPause(); }} color="primary" size="small">
          {playing ? <Pause fontSize="small" /> : <PlayArrow fontSize="small" />}
        </IconButton>
        <Tooltip title="Next Video" arrow>
          <IconButton onClick={handleNextVideo} color="primary" size="small">
            <SkipNext fontSize="small" />
          </IconButton>
        </Tooltip>
        <IconButton onClick={handleVolumeClick} color="primary" size="small">
          {muted ? <VolumeOff fontSize="small" /> : <VolumeUp fontSize="small" />}
        </IconButton>
        <Slider
          value={volume * 100}
          onChange={handleVolumeChange}
          aria-labelledby="volume-slider"
          sx={{
            width: 100,
            color: 'white',
            '& .MuiSlider-thumb': {
              color: 'white',
            },
            '& .MuiSlider-track': {
              color: 'white',
            },
            '& .MuiSlider-rail': {
              color: 'white',
            },
          }}
        />
        <Typography variant="body2" sx={{ color: 'white' }}>
          {formatTime(playedSeconds)} / {formatTime(parseInt(endTimeCurrent, 10))}
        </Typography>
        {/* <Tooltip title="Take a Test" arrow>
          <IconButton onClick={handleTakeTest} color="primary" size="small">
            <Quiz fontSize="small" />
          </IconButton>
        </Tooltip> */}
        <Tooltip title="Change Speed" arrow>
          <IconButton color="primary" size="small" onClick={handleMenuClick}>
            <Speed fontSize="small" />
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ height: '15rem', zIndex: '1502' }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem onClick={() => handlePlaybackRateChange(0.5)}>X0.5</MenuItem>
          <MenuItem onClick={() => handlePlaybackRateChange(0.7)}>X0.7</MenuItem>
          <MenuItem onClick={() => handlePlaybackRateChange(1)}>X1</MenuItem>
          <MenuItem onClick={() => handlePlaybackRateChange(1.2)}>X1.2</MenuItem>
          <MenuItem onClick={() => handlePlaybackRateChange(1.5)}>X1.5</MenuItem>
          <MenuItem onClick={() => handlePlaybackRateChange(1.7)}>X1.7</MenuItem>
          <MenuItem onClick={() => handlePlaybackRateChange(2)}>X2</MenuItem>
        </Menu>
        {/* <Tooltip title="Select Movie" arrow>
          <IconButton color="primary" size="small" onClick={handleMovieMenuClick}>
            <MenuIcon fontSize="small" />
          </IconButton>
        </Tooltip> */}
        <Menu
          sx={{ height: '15rem', zIndex: '1502' }}
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={handleMovieMenuClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {movieList.map((movie, index) => (
            <MenuItem key={index} onClick={() => handleMovieSelect(movie)}>
              <ListItemText primary={`${movie.subject}: ${movie.subSubject}`} />
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </Box>
  );
};

export default PlayerControls;
