import { Box, Link,Typography } from '@mui/material';
import React  from 'react';
import { secondsToMinutes,scoreOfDistance,createYoutubeLinkWithTime } from './UtilityFunctions';
import MetaDataMedia from './MetaDataMedia';
interface SearchResultMiddleBottomProps {
    item:any
}
const SearchResultMiddleBottom: React.FC<SearchResultMiddleBottomProps> = ({ item }) => {
  return (
      <Box sx={{ textAlign: 'center' }}> {/* Center alignment */}
          <Link href={createYoutubeLinkWithTime(item.movieUrl,item.startTime) || '#'} 
              key={item.movieUrl}
              target="_blank" 
              rel="noopener noreferrer" 
              sx={{ 
                textDecoration: 'none', 
                color: 'lightPurple', 
                display: 'block',
                textAlign: 'center',
                marginTop: '0',
                marginRight: '1rem',
              }}
            >
              {item.movieName}
              {secondsToMinutes(item.startTime) && secondsToMinutes(item.endTime) && <span> | Relevant Part: {secondsToMinutes(item.startTime)} - {secondsToMinutes(item.endTime)} </span>}
           </Link>
          {/* Other metadata */}
          <MetaDataMedia item={item}/>
          
      </Box>
  );
}


export default SearchResultMiddleBottom;