import React from 'react';
import { useDrop } from 'react-dnd';
import { Box } from '@mui/material';

interface DraggableItem {
  index: number;
  id: number;
  type: string;
}

const DropArea: React.FC<{ label: string; onDrop: (item: DraggableItem) => void; type: string }> = ({ label, onDrop, type }) => {
  const [, drop] = useDrop({
    accept: type,
    drop: (item: DraggableItem) => {
      onDrop(item);
    },
  });

  return (
    <Box ref={drop} sx={{ padding: '10px', margin: '10px', border: '2px dashed gray', textAlign: 'center', minHeight: '50px' }}>
      {label}
    </Box>
  );
};

export default DropArea;
