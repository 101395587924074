import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getAllQuizMonitoringDataAndQuizMonitoringUserData } from '../api/serverApis/monitoringApi';

export interface monitoringState {
  data: any;
  loading: boolean;
  error: string | null | undefined;
}

const initialState: monitoringState = {
  data: null,
  loading: false,
  error: null,
};

export const fetchQuestionMonitoring = createAsyncThunk('monitoring/fetchQuestionMonitoring', async () => {
  const response = await getAllQuizMonitoringDataAndQuizMonitoringUserData();
  return response.data;
});




const handleState = (action: any) => {
  return (builder: any) => {
    builder.
      addCase(action.pending, (state: any) => {
        state.loading = true;
      })
      .addCase(action.fulfilled, (state: any, action: PayloadAction<any>) => {
        state.loading = false;
        if (action.type === 'monitoring/fetchQuestionMonitoring/fulfilled') {
          state.data = action.payload;
        }
      })
      .addCase(action.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.error.message;
      });
  };
}

const coursesSlice = createSlice({
  name: 'courses',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    handleState(fetchQuestionMonitoring)(builder);
  }
});

export default coursesSlice.reducer;