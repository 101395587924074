import React from 'react';
import { AppBar, Toolbar, Typography, Box, Switch, FormControlLabel } from '@mui/material';
import logo from '../../images/logo1.png'; // Adjust the path as necessary

interface HeaderProps {
  themeMode: 'light' | 'dark';
  toggleTheme: () => void; // Function to toggle the theme
  header?: string,
  title?: string
}

const Header: React.FC<HeaderProps> = ({ themeMode, toggleTheme, header, title }) => {
  const handleLogoClick = () => {
    window.location.href = '/home'; // Redirect to the home page when the logo is clicked
  };
  return (
    // <AppBar position="static" sx={{
    //   height: '3rem',
    //   justifyContent: 'center', // This ensures the content is centered vertically
    //   alignItems: 'center', // Centers toolbar items vertically
    //   overflow: 'hidden' // Prevents content from exceeding the AppBar height


    // }}>
    <AppBar
      position="static"
      sx={{
        height: '3rem',
        justifyContent: 'center', // Centers content vertically
        alignItems: 'center',
        overflow: 'hidden',
        // Different background for light and dark mode
        backgroundColor: themeMode === 'dark' ? '#424242' : '#F0F8FF',

      }}
    >
      <Toolbar sx={{
        minHeight: '3rem !important', // Ensures the Toolbar respects the AppBar height, overriding default styles
        alignItems: 'center', // Ensure the content inside the Toolbar is centered vertically
        justifyContent: 'space-between', // Spaces out logo and switch
        width: '100%', // Ensures the Toolbar spans the entire width of the AppBar
      }}>
        <Box display="flex" alignItems="center" height="100%" onClick={handleLogoClick} sx={{ cursor: 'pointer' }}>
          <img src={logo} alt="Logo" style={{ marginTop: '0rem', marginRight: 10, width: "100px", height: 'auto' }} />
          <Typography
            fontSize="1rem"
            noWrap
            sx={{
              color: themeMode === 'dark' ? '#ffffff' : '#000000', // Lighter in dark mode, darker in light mode
            }}
          >
            {header}
          </Typography>
          {title && <Typography
            fontSize="1rem"
            noWrap
            sx={{
              color: themeMode === 'dark' ? '#ffffff' : '#000000', // Lighter in dark mode, darker in light mode
            }}
          >{title}</Typography>}
        </Box>
        <Box>
          <FormControlLabel
            sx={{
              color: themeMode === 'dark' ? '#ffffff' : '#000000', // Lighter in dark mode, darker in light mode
            }}
            control={<Switch checked={themeMode === 'dark'} onChange={toggleTheme} />}
            label={themeMode === 'dark' ? 'Dark Mode' : 'Light Mode'}
          />
        </Box>
      </Toolbar>
    </AppBar >
  );
};

export default Header;
