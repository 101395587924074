// SearchAndFilter.tsx
import React, { useState } from 'react';
import { Box, Switch, FormControlLabel, useTheme, styled, TextField, InputAdornment, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import RadioButtons from './RadioButtons'; // Adjust import paths as necessary

interface SearchAndFilterProps {
  onSearch: (searchTerm: string) => void;
}

const SearchAndFilter: React.FC<SearchAndFilterProps> = ({ onSearch }) => {
  const [showFilters, setShowFilters] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const theme = useTheme();

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = () => {
    onSearch(searchTerm);
  };

  return (
    <Box sx={{ width: '100%', padding: theme.spacing(2), display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
        <FormControlLabel
          control={<Switch checked={showFilters} onChange={() => setShowFilters(!showFilters)} />}
          label="Show Filters"
          sx={{ marginRight: theme.spacing(2) }}
        />
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
          onKeyPress={(e) => e.key === 'Enter' && handleSearchSubmit()}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon onClick={handleSearchSubmit} style={{ cursor: 'pointer' }} />
              </InputAdornment>
            ),
            style: { borderRadius: 20 }, // Rounded borders
          }}
          sx={{ flexGrow: 1 }}
        />
      </Box>
      {showFilters && (
        <Box sx={{ width: '100%', mt: 1 }}>
          <RadioButtons />
        </Box>
      )}
    </Box>
  );
};

export default SearchAndFilter;
