import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { TableRow, TableCell } from '@mui/material';
import { convertNumberToTime } from '../../searchComponents/UtilityFunctions';

interface DraggableItem {
  index: number;
  id: number;
  type: string;
}

interface Sentence {
  id: number;
  audioFileName: string;
  articleId: string;
  paragraphId: number;
  timeStart: string;
  timeEnd: string;
  sentence: string;
}

const DraggableSentence: React.FC<{ sentence: Sentence; index: number; moveSentence: (dragIndex: number, hoverIndex: number) => void; type: string }> = ({ sentence, index, moveSentence, type }) => {
  const ref = useRef<HTMLTableRowElement>(null);
  const [, drop] = useDrop({
    accept: type,
    hover(item: DraggableItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      if (!clientOffset) {
        return;
      }
      const hoverClientY = clientOffset?.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveSentence(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: type,
    item: { type: type, id: sentence.id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <TableRow
      ref={ref}
      style={{
        opacity: isDragging ? 0.5 : 1,
        backgroundColor: isDragging ? 'lightgreen' : 'inherit',
      }}
    >
      <TableCell>{index + 1}</TableCell>
      <TableCell>{sentence.audioFileName}</TableCell>
      <TableCell>{sentence.paragraphId}</TableCell>
      <TableCell>{sentence.sentence}</TableCell>
      <TableCell>{convertNumberToTime(Number.parseFloat(sentence.timeStart))}</TableCell>
      <TableCell>{convertNumberToTime(Number.parseFloat(sentence.timeEnd))}</TableCell>
    </TableRow>
  );
};

export default DraggableSentence;
