import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, TextField, Button, Box, OutlinedInput, Chip, SelectChangeEvent, Divider } from '@mui/material';

interface EntityTypes {
  entityTypes: string[];
  fieldEntity: { [key: string]: any[]; };
}

const GenericForms: React.FC<EntityTypes> = ({ entityTypes, fieldEntity }) => {
  const [selectedEntityTypes, setSelectedEntityTypes] = useState<string[]>([]);
  const [entitiesData, setEntitiesData] = useState<{ [key: string]: any[] }>({});

  const handleEntityTypeChange = (event: SelectChangeEvent<typeof selectedEntityTypes>) => {
    let value: string[] = [];
    if (typeof event.target.value === 'string') {
      value = event.target.value.split(','); // Splitting by comma for autofill or manual string value
    } else {
      value = event.target.value; // Directly using the array when it's already an array
    }
    setSelectedEntityTypes(value);
    // Initialize an empty form for newly selected entity types if they don't already have data
    const newEntitiesData = { ...entitiesData };
    value.forEach((entityType: any) => {
      if (!newEntitiesData[entityType]) {
        newEntitiesData[entityType] = [{}]; // Initialize with an empty object for the form
      }
    });
    setEntitiesData(newEntitiesData);
  };

  const handleInputChange = (entityType: string, index: number, field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedEntities = [...(entitiesData[entityType] || [])];
    const updatedEntity = { ...(updatedEntities[index] || {}), [field]: event.target.value };
    updatedEntities[index] = updatedEntity;

    setEntitiesData({ ...entitiesData, [entityType]: updatedEntities });
  };

  const handleAddEntity = (entityType: string) => {
    const updatedEntities = [...(entitiesData[entityType] || []), {}];
    setEntitiesData({ ...entitiesData, [entityType]: updatedEntities });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(entitiesData); // Here you would send entitiesData to your server
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, mt: 1 }}>
      <FormControl fullWidth margin="normal">
        <InputLabel id="entity-type-multi-select-label">Entity Types</InputLabel>
        <Select
          labelId="entity-type-multi-select-label"
          id="entity-type-multi-select"
          multiple
          value={selectedEntityTypes}
          onChange={handleEntityTypeChange}
          input={<OutlinedInput id="select-multiple-chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => <Chip key={value} label={value} />)}
            </Box>
          )}
        >
          {entityTypes.map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {/* Entity Forms */}
      {selectedEntityTypes.map((entityType, entityTypeIndex) => (
        <React.Fragment key={entityTypeIndex}>
          <Divider sx={{ my: 2 }}>Entity: {entityType}</Divider>
          {(entitiesData[entityType] || []).map((entityData, entityIndex) => (
            <Box key={entityIndex}>
              {entityIndex > 0 && <Divider sx={{ my: 1 }}>New {entityType} Form</Divider>}
              {fieldEntity[entityType].map((field) => (
                <TextField
                  key={field}
                  label={field}
                  value={entityData[field] || ''}
                  onChange={handleInputChange(entityType, entityIndex, field)}
                  margin="normal"
                  required
                  fullWidth
                />
              ))}
            </Box>
          ))}

          <Button variant="contained" onClick={() => handleAddEntity(entityType)} sx={{ mt: 3, mb: 2 }}>
            Add {entityType}
          </Button>
        </React.Fragment>
      ))}
      <Divider sx={{ my: 2 }}>Submit</Divider>
      <Button type="submit" variant="contained" color="primary" sx={{ mt: 4 }}>
        Submit All Entities
      </Button>
    </Box>
  );
};

export default GenericForms;
