// store/videoSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface VideoState {
  currentlyPlayingVideoId: string | null;
}

const initialState: VideoState = {
  currentlyPlayingVideoId: null,
};

const videoSlice = createSlice({
  name: 'video',
  initialState,
  reducers: {
    setCurrentlyPlayingVideo: (state, action: PayloadAction<string | null>) => {
      state.currentlyPlayingVideoId = action.payload;
    },
  },
});

export const { setCurrentlyPlayingVideo } = videoSlice.actions;
export default videoSlice.reducer;
