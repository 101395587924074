import React, { useState, useEffect } from 'react';
import { Grid, Paper, createTheme, ThemeProvider, GlobalStyles } from '@mui/material';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store';
import { RootState } from '../store';
import { useSelector } from 'react-redux';
// import {
//   handleButtonClick ,
//   setSelectedSessionId ,
//   setActiveComponent ,
//   setCurrentComponent,
//   setClickedIconBarState
// } from '../features/navbarSlice';
import { uploadAudio, uploadVideoUrl, uploadAudioFromFile } from '../features/audioSlice';
import { fetchArchiveSearch, fetchMediaSearch, fetchSearchBySessionId, fetchMediaSearchAzureMainCategory, fetchMediaForSeinfeld } from '../features/searchMediaSlice';
import { Recorder } from '../components/transcriptsComponents/Recorder';
import { MP3Upload } from '../components/transcriptsComponents/MP3FileUpload';
import { UrlUpload } from '../components/transcriptsComponents/UrlUpload';
//import SearchComponent from '../components/transcriptsComponents/SearchComponent';
import MainSearchComponent from '../components/searchComponents/MainSearchComponent';
import MainSearchSeinfeld from '../components/searchComponents/MainSearchSeinfeld';
//import NavBar from '../components/transcriptsComponents/transcriptsNavBar';
import MainNavBar from '../components/navbarComponents/MainNavBar';
import MediaInteract from '../components/transcriptsComponents/MediaInteract';
import { v4 as uuidv4 } from 'uuid';


const VideosSearchPage = () => {

  const [showSearchComponent, setShowSearchComponent] = useState(true);
  const [showRecorder, setShowRecorder] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [showUploadUrl, setShowUploadUrl] = useState(false);
  const [activeComponent, setActiveComponent] = useState('search');

  const [showInteractIcon, setShowInteractIcon] = useState(false);
  const [selectedArchiveSessionId, setSelectedArchiveSessionId] = useState<string | null>(null);
  const [archiveData, setArchiveData] = useState(null);
  const [selectedMovieData, setSelectedMovieData] = useState(null);
  const [addon, setAddon] = useState('');
  const dispatch = useDispatch<AppDispatch>();

  const archiveDataFromSelector = useSelector((state: RootState) => state.searchMedia);
  //const {activeComponent} = useSelector((state: RootState) => state.navbar);
  const [selectedAddonsOption, setSelectedAddonsOption] = useState('');

  const menuItems = ["AZURE", "Seinfeld"];
  let sessionId = '';

  interface MovieData {
    url: string;
    name: string;
    additionalInfo: string;
    links: string[];
    isPrivate: boolean;
  }

  useEffect(() => {
    dispatch(fetchArchiveSearch())
  }, [dispatch]);

  useEffect(() => {
    setArchiveData(archiveDataFromSelector.answerTitle);
  }, [archiveDataFromSelector.answerTitle]);

  const handleStartRecording = () => {
    sessionId = uuidv4();
    return sessionId;
  };

  const handleMediaInteraction = (item: any, movieData: any) => {
    if (!movieData.movieId) {
      movieData.sessionId = item.sessionId;
    }
    setSelectedMovieData(movieData);
    setActiveComponent('interact');
    setShowInteractIcon(true);
    setSelectedArchiveSessionId(movieData.sessionId);
    const components = [
      { component: <MainSearchComponent isSearchFormOn={false} onSearch={handleSearch} triggerSearchTextFromGrandfatherPage={'trigger'} onMediaInteraction={handleMediaInteraction} boxName={"Search Any Movie"} addon={""} key={0} />, show: showSearchComponent, title: "Let's find your media", name: "search" },
      { component: <UrlUpload onUploadUrl={handleUploadUrl} />, show: showUploadUrl, title: "Upload Movies From Url", name: "uploadFromUrl" },
      { component: <MP3Upload onUpload={handleUpload} />, show: showUpload, title: "Upload MP3", name: "upload" },
      { component: <Recorder onStartRecording={handleStartRecording} onStopRecording={handleStopRecording} />, show: showRecorder, title: "Recorder", name: "recorder" },
      { component: <MediaInteract movieData={movieData} />, show: MediaInteract, title: "Media Interact", name: "interact" }
    ];
    setComponents(components);
  };

  const handleStopRecording = (audio: Blob[], sessionId: string, rawNumber: number, recordName: string) => {
    dispatch(uploadAudio({ audio, sessionId, rawNumber, recordName }));
  };

  const handleUpload = (file: File) => {
    const sessionId = handleStartRecording();
    dispatch(uploadAudioFromFile({ audio: [file], sessionId }));
  };

  const setActiveComponentAndHideInteractIcon = (componentName: string) => {
    setActiveComponent(componentName);
    if (componentName !== 'interact') {
      setShowInteractIcon(false);
      setSelectedArchiveSessionId(null);
    }
  }
  const handleSearch = (searchQuery: string, isImageSearch: boolean, audioName: string, mainCategory: string, addon: string) => {
    const sessionId = localStorage.getItem('sessionSearchId') || '';
    switch (addon) {
      case 'AZURE':
        dispatch(fetchMediaSearchAzureMainCategory({ text: searchQuery, sessionId, isImageSearch, audioName, mainCategory }));
        break;
      case "Seinfeld":
        dispatch(fetchMediaForSeinfeld({ text: searchQuery, sessionId, isImageSearch, audioName, mainCategory }));
        break;
      default:
        dispatch(fetchMediaSearch({ text: searchQuery, sessionId, isImageSearch, audioName, mainCategory }));
    }
  };

  const handleUploadUrl = (movies: MovieData[]) => {
    const response = dispatch(uploadVideoUrl({ videoUrl: movies }));
    return response;
  };

  const getArchiveSearchBySessionId = (sessionId: string) => {
    setActiveComponent('search');
    setSelectedArchiveSessionId(null);
    setShowInteractIcon(false);
    dispatch(fetchSearchBySessionId({ sessionId }));
  }

  const handleChangesInAddons = (selectedOption: string) => {
    if (selectedOption === "") {
      setComponents(InitializeComponents);
    }
    if (selectedOption === "AZURE") {

      const components = [
        { component: <MainSearchComponent isSearchFormOn={false} onSearch={handleSearch} triggerSearchTextFromGrandfatherPage={'trigger'} onMediaInteraction={handleMediaInteraction} boxName={"Search in Azure"} addon={"AZURE"} key={1} />, show: showSearchComponent, title: "Let's find your media", name: "search" },
        { component: <UrlUpload onUploadUrl={handleUploadUrl} />, show: showUploadUrl, title: "Upload Movies From Url", name: "uploadFromUrl" },
        { component: <MP3Upload onUpload={handleUpload} />, show: showUpload, title: "Upload MP3", name: "upload" },
        { component: <Recorder onStartRecording={handleStartRecording} onStopRecording={handleStopRecording} />, show: showRecorder, title: "Recorder", name: "recorder" },
        { component: <MediaInteract movieData={selectedMovieData} />, show: MediaInteract, title: "Media Interact", name: "interact" }
      ];
      setAddon(selectedOption);
      setActiveComponent('search');
      setComponents(components);
      setSelectedArchiveSessionId(null);
    }
    if (selectedOption === "Seinfeld") {
      const components = [
        { component: <MainSearchSeinfeld isSearchFormOn={false} onSearch={handleSearch} triggerSearchTextFromGrandfatherPage={'trigger'} onMediaInteraction={handleMediaInteraction} boxName={"Search in Seinfeld"} addon={"Seinfeld"} key={3} />, show: showSearchComponent, title: "Let's find your media", name: "search" },
        { component: <UrlUpload onUploadUrl={handleUploadUrl} />, show: showUploadUrl, title: "Upload Movies From Url", name: "uploadFromUrl" },
        { component: <MP3Upload onUpload={handleUpload} />, show: showUpload, title: "Upload MP3", name: "upload" },
        { component: <Recorder onStartRecording={handleStartRecording} onStopRecording={handleStopRecording} />, show: showRecorder, title: "Recorder", name: "recorder" },
        { component: <MediaInteract movieData={selectedMovieData} />, show: MediaInteract, title: "Media Interact", name: "interact" }
      ];
      setAddon(selectedOption);
      setActiveComponent('search');
      setComponents(components);
      setSelectedArchiveSessionId(null);
    }
  }

  const renderNavBar = () => (
    <Grid item xs={12}>
      <MainNavBar
        onArchiveItemClick={getArchiveSearchBySessionId}
        archiveData={archiveData}
        setActiveComponent={setActiveComponentAndHideInteractIcon}
        showInteractIcon={showInteractIcon}
        selectedArchiveSessionId={selectedArchiveSessionId}
        selectedOption={selectedAddonsOption}
        setSelectedOption={setSelectedAddonsOption}
        items={menuItems}
        handleChangesInAddons={handleChangesInAddons}

      />
    </Grid>
  );

  const InitializeComponents = [
    { component: <MainSearchComponent isSearchFormOn={true} onSearch={handleSearch} triggerSearchTextFromGrandfatherPage={'trigger'} onMediaInteraction={handleMediaInteraction} boxName={"Search Any Movie"} addon={""} key={2} />, show: showSearchComponent, title: "Let's find your media", name: "search" },
    { component: <UrlUpload onUploadUrl={handleUploadUrl} />, show: showUploadUrl, title: "Upload Movies From Url", name: "uploadFromUrl" },
    { component: <MP3Upload onUpload={handleUpload} />, show: showUpload, title: "Upload MP3", name: "upload" },
    { component: <Recorder onStartRecording={handleStartRecording} onStopRecording={handleStopRecording} />, show: showRecorder, title: "Recorder", name: "recorder" },
    { component: <MediaInteract movieData={selectedMovieData} />, show: MediaInteract, title: "Media Interact", name: "interact" }
  ];
  const [components, setComponents] = useState(InitializeComponents);


  const renderComponents = () => {
    const visibleComponents = components.filter((comp: any) => comp.name === activeComponent); // Show only the active component   
    return visibleComponents.map((comp, index) => {
      return generalDynamicComponent(comp.component, comp.title, index);
    });
  }
  const generalDynamicComponent = (component: any, title: string, index: number) => {
    const containerWidth = '85%'; // or any fixed width that fits your design
    const titleStyle: React.CSSProperties = {
      color: 'white',
      fontFamily: 'CoolCleanFont, sans-serif',
    };

    return (
      <div key={index} style={{ ...containerStyle, flexShrink: 0, width: containerWidth, marginLeft: '12rem' }}>
        <h2 style={{ marginTop: '-30px', color: 'white', fontFamily: 'CoolCleanFont, sans-serif' }}>{title}</h2>
        {component}
      </div>
    );
  }
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
    // You can add other theme customizations here
  });
  const globalStyles = {
    body: {
      backgroundColor: darkTheme.palette.background.default,
      color: darkTheme.palette.text.primary,
    },
    // ... other global styles ...
  };
  const outerContainerStyles: React.CSSProperties = {

    marginLeft: '4rem',
    bottom: 0,
    left: 0,
    right: 0,
    height: '95vh',
    overflow: 'hidden',
    zIndex: 1000,
    backgroundColor: '#121212', // Dark background
  };

  const innerContainerStyles: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'hidden',
    overflowY: 'hidden',
  };

  const containerStyle: React.CSSProperties = {
    //backgroundColor: '#424242', // Darker shade for inner containers
    minHeight: '280px',
    padding: '1rem',
    margin: '1rem 0',
    color: '#fff', // Light text for readability
  };
  // Render
  return (
    <ThemeProvider theme={darkTheme}>
      <GlobalStyles styles={globalStyles} />
      <Grid container spacing={1}>
        <div id="search-root"></div>
        {renderNavBar()}
        <Grid item xs={12}>
          <div style={outerContainerStyles}>
            <div style={innerContainerStyles}>
              {renderComponents()}
            </div>
          </div>
        </Grid>
      </Grid>
    </ThemeProvider>
  );

};

export default VideosSearchPage;