import axiosInstance from "../interceptor";
import { CHATGPT_API_URL } from "../api";


const API = "/api/v1/picture";
export const pictureGenerate = async (userId: string, apiKey: string, bookId: string, page: number, pictureId: string, prompt: string) => {
  return axiosInstance.post(`${CHATGPT_API_URL}${API}/generatePicture`, {
    userId,
    apiKey,
    bookId,
    page,
    pictureId,
    prompt
  });
};

export const getPictureByUserId = async ({ id }: { id: string }) => {
  return axiosInstance.get(`${CHATGPT_API_URL}${API}/${id}`);
}

export const getPictureByUserIdAndBookId = async ({ userId, bookId }: { userId: string, bookId: string }) => {
  return axiosInstance.get(`${CHATGPT_API_URL}${API}/${userId}/${bookId}`);
}