import React, { useEffect, useRef, useState, useContext } from 'react';
import ReactPlayer from 'react-player';
import { Box, CircularProgress } from '@mui/material';
import { cleanTheDot } from '../../searchComponents/UtilityFunctions';
import { setSelectedVideoDetails } from '../../../features/coursesSlice';
import { AppDispatch } from '../../../store';
import { useDispatch } from 'react-redux';
import CountdownSnackbar from './CountdownSnackbar'; // Import the new component
import PlayerControls from './PlayerControls'; // Import the new PlayerControls component
import { setCurrentlyPlayingSubVideo } from '../../../features/PlayerTocSlice';

interface ReactPlayerComponentProps {
  videoUrl: string;
  startTime?: string;
  frameTime?: string;
  endTime?: string;
  onTakeTest: (quizData: any) => void;
  movieList: any[];
  videoData?: any;
  onVideoComplete?: (videoId: string, subVideoId: string) => void;
}

const ReactPlayerComponent: React.FC<ReactPlayerComponentProps> = ({
  videoUrl,
  startTime,
  frameTime,
  endTime,
  onTakeTest,
  videoData,
  movieList,
  onVideoComplete,
}) => {
  const playerRef = useRef<ReactPlayer>(null);
  const [playing, setPlaying] = useState(false);
  const [ready, setReady] = useState(false);
  const [open, setOpen] = useState(false);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [hoveredTime, setHoveredTime] = useState<number | null>(null);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [duration, setDuration] = useState(0);
  const [muted, setMuted] = useState(false);
  const [volume, setVolume] = useState(0.8);
  const [previousVolume, setPreviousVolume] = useState(volume);
  const [controlsVisible, setControlsVisible] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [perviousChapterToken, setPerviousChapterToken] = useState(false);
  const [showReplayIcon, setShowReplayIcon] = useState(true);
  const [showTakeTestIcon, setShowTakeTestIcon] = useState(true);
  const [showStartMovieIcon, setShowStartMovieIcon] = useState(true);
  const [showContinueIcon, setShowContinueIcon] = useState(true);
  const [countdownOutside, setCountdownOutside] = useState(10);
  const [loading, setLoading] = useState(true); // New loading state
  const [startTimeCurrent, setStartTimeCurrent] = useState<string>(cleanTheDot(startTime ? startTime : "0"));
  const [endTimeCurrent, setEndTimeCurrent] = useState<string>(cleanTheDot(endTime ? endTime : "0"));
  const [showPlayPauseIcon, setShowPlayPauseIcon] = useState<string | null>(null);
  const [blockEnd, setBlockEnd] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  //const { setVideoProgress, setCurrentlyPlayingSubVideo } = useVideoProgress();

  const getStartTime = () => {
    if (startTime && startTime !== "-1") {
      return cleanTheDot(startTime);
    } else if (frameTime) {
      return cleanTheDot(frameTime);
    } else {
      return "0";
    }
  };

  const getEndTime = () => {
    if (endTime && endTime !== "-1") {
      return cleanTheDot(endTime);
    } else {
      return duration.toString();
    }
  };
  const isHebrew = (text: string) => /[\u0590-\u05FF\uFB1D-\uFB4F\uFB50-\uFDFF]/.test(text);
  // const startTimeCurrent = getStartTime();
  // const endTimeCurrent = getEndTime();


  // useEffect(() => {
  //   setPlayedSeconds(parseInt(startTimeCurrent, 10));
  //   setStartTimeCurrent(getStartTime());
  //   setEndTimeCurrent(getEndTime());
  // }, [dispatch, startTime, endTime, frameTime, videoData]);



  // useEffect(() => {
  //   setStartTimeCurrent(getStartTime());
  //   setEndTimeCurrent(getEndTime());
  //   setPlayedSeconds(parseInt(startTimeCurrent, 10));
  //   onPlayingSubVideo && onPlayingSubVideo(videoData.movieId, videoData.id, 0);
  //   setPlayedSeconds(parseInt(startTimeCurrent, 10));

  //   playerRef.current?.seekTo(parseInt(startTimeCurrent, 10));
  //   if (ready && playerRef.current && startTimeCurrent && !snackbarOpen) {
  //     const seekToStartTime = async () => {
  //       await new Promise(resolve => setTimeout(resolve, 500));
  //       playerRef.current?.seekTo(parseInt(startTimeCurrent, 10));
  //       setPlaying(true);
  //       setPerviousChapterToken(false);
  //       setLoading(false); // Hide spinner when video starts playing
  //     };
  //     seekToStartTime();
  //   }
  //   setPlaying(true);
  // }, [videoData, startTime, endTime, frameTime, startTimeCurrent, dispatch]);

  useEffect(() => {
    setBlockEnd(true);
    setLoading(true);
    setPlaying(true);
    const start = getStartTime();
    const end = getEndTime();
    setStartTimeCurrent(start);
    setEndTimeCurrent(end);
    setPlayedSeconds(parseInt(start, 10));
    if (videoData) {
      dispatch(setCurrentlyPlayingSubVideo({ movieId: videoData.movieId, subVideoId: videoData.id }));
    }
    // if (videoData && setVideoProgress) {
    //   setVideoProgress(videoData.movieId, videoData.id, 0);
    //   setCurrentlyPlayingSubVideo({ movieId: videoData.movieId, subVideoId: videoData.id });
    // }

    const seekToStartTime = async () => {
      const start = getStartTime();
      await new Promise(resolve => setTimeout(resolve, 500));
      playerRef.current?.seekTo(parseInt(start, 10));
      setLoading(false);
      setPlaying(true);
      setBlockEnd(false);
    };

    if (ready && playerRef.current && start) {
      seekToStartTime();
      setPlayedSeconds(parseInt(start, 10));
      setLoading(false);
      setPlaying(true);

    }
  }, [videoData, startTime, endTime, frameTime, ready]);


  const handleProgress = (state: { playedSeconds: number }) => {
    //setPlayedSeconds(state.playedSeconds);
    const start = parseInt(startTimeCurrent, 10);
    const end = parseInt(endTimeCurrent, 10);
    const currentTime = state.playedSeconds;
    if (currentTime < start) {
      playerRef.current?.seekTo(start);
      return;
    }
    setPlayedSeconds(currentTime);
    let currentProgress = ((state.playedSeconds - start) / (end - start)) * 100;
    currentProgress = Math.min(Math.max(currentProgress, 0), 100);
    // if (videoData && setVideoProgress) {
    //   setVideoProgress(videoData.movieId, videoData.id, currentProgress);
    // }
    if (videoData) {
      dispatch(setCurrentlyPlayingSubVideo({ movieId: videoData.movieId, subVideoId: videoData.id }));
    }
    whenTheChapterEnded(currentTime);
  };

  const whenTheChapterEnded = (currentTime: number) => {
    const start = parseInt(startTimeCurrent, 10);
    const end = parseInt(endTimeCurrent, 10);
    // if (!snackbarOpen && currentTime >= end) {
    if (currentTime >= end && !blockEnd) {
      //setPlaying(false);
      // setSnackbarOpen(true);
      let movieIndex = movieList.findIndex(movie => movie.movieId === videoData.movieId && movie.id === videoData.id);
      if (movieIndex !== -1) {

        handleNextVideo();
        // const nextMovie = movieList[movieIndex + 1];
        // if (nextMovie) {
        //   const message = isHebrew(nextMovie.subject) ? `הפרק הבא: ${nextMovie.subject}: ${nextMovie.subSubject}` : `Next chapter: ${nextMovie.subject}: ${nextMovie.subSubject}`;
        //   setShowReplayIcon(true);
        //   setShowTakeTestIcon(true);
        //   setShowStartMovieIcon(false);
        //   setShowContinueIcon(true);
        //   setCountdownOutside(10);
        //   showSnackbar(message);
        //   // if (countdownOutside === 0) {
        //   //   dispatch(setSelectedVideoDetails({ movieId: nextMovie.movieId, id: nextMovie.id, courseId: nextMovie.courseId ? nextMovie.courseId : "" }));
        //   // }
        // }
      }
    }
  }
  const handleSeekChange = (event: Event, newValue: number | number[]) => {
    const newTime = Array.isArray(newValue) ? newValue[0] : newValue;
    playerRef.current?.seekTo(newTime);
    setPlayedSeconds(newTime);
  };

  const handlePlayPause = () => {
    //setPlaying(prev => !prev);
    // setPlaying((prev) => !prev);
    // playerRef.current?.getInternalPlayer().playVideo();
    if (playerRef.current && ready) {
      setPlaying(!playing); // Toggle play/pause
      setShowPlayPauseIcon(playing ? 'play' : 'pause'); // Show the relevant icon

      // // Hide the icon after 1 second
      // setTimeout(() => {
      //   setShowPlayPauseIcon(null);
      // }, 1000);
      if (playing) {
        playerRef.current?.getInternalPlayer().pauseVideo();
      } else {
        playerRef.current?.getInternalPlayer().playVideo();
      }
    }
  };

  const handleMute = () => {
    if (muted) {
      setVolume(previousVolume);
    } else {
      setPreviousVolume(volume);
      setVolume(0);
    }
    setMuted(prev => !prev);
  };

  const handleVolumeChange = (event: Event, newValue: number | number[]) => {
    const newVolume = Array.isArray(newValue) ? newValue[0] : newValue;
    setVolume(newVolume / 100);
    setMuted(newVolume === 0);
  };

  const handleVolumeClick = () => {
    handleMute();
  };
  const handleTakeTest = () => {
    setOpen(false);
    setPlaying(false);
    onTakeTest({
      articleId: videoData.movieId,
      topicNumber: videoData.id
    });
  };
  const handlePlaybackRateChange = (rate: number) => {
    setPlaybackRate(rate);
    setAnchorEl(null);
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  const handleSliderHover = (event: React.MouseEvent<HTMLDivElement>) => {
    const sliderWidth = event.currentTarget.clientWidth;
    const offsetX = event.nativeEvent.offsetX;
    const start = parseInt(startTimeCurrent, 10);
    const end = endTimeCurrent ? parseInt(endTimeCurrent, 10) : playerRef.current?.getDuration();
    if (end !== undefined) {
      const hoverTime = start + ((offsetX / sliderWidth) * (end - start));
      setHoveredTime(hoverTime);
    }
  };

  const handleSliderLeave = () => {
    setHoveredTime(null);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMovieMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMovieMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const showSnackbar = (message: string) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleNextVideo = () => {
    let movieIndex = movieList.findIndex(movie => movie.movieId === videoData.movieId && movie.id === videoData.id);
    if (movieIndex !== -1) {
      const nextMovie = movieList[movieIndex + 1];
      if (nextMovie) {
        // const message = isHebrew(nextMovie.subject) ? `הפרק הבא: ${nextMovie.subject}: ${nextMovie.subSubject}` : `Next chapter: ${nextMovie.subject}: ${nextMovie.subSubject}`;
        // showSnackbar(message);
        // setShowReplayIcon(false);
        // setShowTakeTestIcon(true);
        // setShowStartMovieIcon(true);
        // setShowContinueIcon(false);
        // setCountdownOutside(10);
        dispatch(setSelectedVideoDetails({ movieId: nextMovie.movieId, id: nextMovie.id, courseId: nextMovie.courseId ? nextMovie.courseId : "" }));
        setPlaying(true);
      }
    }
  };

  const handlePreviousVideo = () => {
    let movieIndex = movieList.findIndex(movie => movie.movieId === videoData.movieId && movie.id === videoData.id);
    if (movieIndex !== -1) {
      setPerviousChapterToken(true);
      const prevMovie = movieList[movieIndex - 1];
      if (prevMovie) {
        const message = isHebrew(prevMovie.subject) ? `הפרק הקודם: ${prevMovie.subject}: ${prevMovie.subSubject}` : `Previous chapter: ${prevMovie.subject}: ${prevMovie.subSubject}`;
        setShowReplayIcon(false);
        setShowTakeTestIcon(true);
        setShowStartMovieIcon(true);
        setShowContinueIcon(false);
        setCountdownOutside(10);
        showSnackbar(message);
        dispatch(setSelectedVideoDetails({ movieId: prevMovie.movieId, id: prevMovie.id, courseId: prevMovie.courseId ? prevMovie.courseId : "" }));
        setPlaying(true);
      }
    }
  };

  const handleMovieSelect = (movie: any) => {
    const message = isHebrew(movie.subject) ? `הפרק: ${movie.subject}: ${movie.subSubject}` : `chapter: ${movie.subject}: ${movie.subSubject}`;
    showSnackbar(message);
    setShowReplayIcon(false);
    setShowTakeTestIcon(true);
    setShowStartMovieIcon(true);
    setShowContinueIcon(false);
    setCountdownOutside(10);
    dispatch(setSelectedVideoDetails({ movieId: movie.movieId, id: movie.id, courseId: movie.courseId ? movie.courseId : "" }));
    setMenuAnchorEl(null);
  };

  const handleReplayChapter = () => {
    if (playerRef.current) {
      playerRef.current.seekTo(parseInt(startTimeCurrent, 10));
      setSnackbarOpen(false);
      setPlaying(true);
    }
  }
  const handleCountdownFinish = () => {
    setPlayedSeconds(startTimeCurrent ? parseInt(startTimeCurrent, 10) : 0);
    onVideoComplete && onVideoComplete(videoData.movieId, videoData.id);
    let movieIndex = movieList.findIndex(movie => movie.movieId === videoData.movieId && movie.id === videoData.id);
    if (movieIndex !== -1) {
      const nextMovie = movieList[movieIndex + 1];
      dispatch(setSelectedVideoDetails({ movieId: nextMovie.movieId, id: nextMovie.id, courseId: nextMovie.courseId ? nextMovie.courseId : "" }));
    }
    setSnackbarOpen(false);
    setPlaying(true);
  };
  const handleStartMovie = () => {
    setSnackbarOpen(false);
    setPlaying(true);

  };
  const handleTouch = () => {
    handlePlayPause(); // Trigger play/pause on touch event
  };
  return (
    <Box
      sx={{ position: 'relative', width: '100%' }}
      onMouseMove={() => setControlsVisible(true)}
      onMouseLeave={() => setControlsVisible(false)}
    // onClick={(e) => e.stopPropagation()}
    >
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <ReactPlayer
        ref={playerRef}
        url={videoUrl}
        playing={playing && !snackbarOpen} // Prevents playing while snackbar is open
        controls={false}
        width="100%"
        height="585px"
        onReady={() => setReady(true)}
        onProgress={handleProgress}
        onEnded={() => setSnackbarOpen(true)}
        playbackRate={playbackRate}
        muted={muted}
        volume={volume}
        onDuration={(duration) => setDuration(duration)}
        onPause={() => setPlaying(false)}

        onPlay={() => {
          setPlaying(true); // Ensure the video starts playing
          setLoading(false); // Hide the loading spinner
        }}// Hide spinner when video starts playing
        config={{
          youtube: {
            playerVars: {
              modestbranding: 1,  // No YouTube branding
              controls: 0,        // Disable YouTube native controls
              disablekb: 1        // Disable keyboard shortcuts
            }
          },
          vimeo: {
            playerOptions: { autoplay: true }
          }
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '80%',
          backgroundColor: 'transparent',
          zIndex: 2,
          pointerEvents: 'auto',// Blocks interaction with underlying player
          cursor: 'pointer'
        }}
        onClick={(e) => {
          e.stopPropagation();
          if (playerRef.current && ready) {
            setPlaying(!playing); // Toggle play/pause
            if (playing) {
              playerRef.current?.getInternalPlayer().pauseVideo();
            } else {
              playerRef.current?.getInternalPlayer().playVideo();
            }
          }
        }}
      />
      {controlsVisible && (
        <PlayerControls
          startTimeCurrent={startTimeCurrent}
          endTimeCurrent={endTimeCurrent}
          playing={playing}
          playedSeconds={playedSeconds}
          duration={duration}
          volume={volume}
          muted={muted}
          playbackRate={playbackRate}
          anchorEl={anchorEl}
          menuAnchorEl={menuAnchorEl}
          hoveredTime={hoveredTime}
          handlePlayPause={handlePlayPause}
          handleVolumeClick={handleVolumeClick}
          handleVolumeChange={handleVolumeChange}
          handleSeekChange={handleSeekChange}
          handleSliderHover={handleSliderHover}
          handleSliderLeave={handleSliderLeave}
          handleMenuClick={handleMenuClick}
          handleMenuClose={handleMenuClose}
          handlePlaybackRateChange={handlePlaybackRateChange}
          handleMovieMenuClick={handleMovieMenuClick}
          handleMovieMenuClose={handleMovieMenuClose}
          handleNextVideo={handleNextVideo}
          handlePreviousVideo={handlePreviousVideo}
          handleTakeTest={handleTakeTest}
          handleMovieSelect={handleMovieSelect}
          movieList={movieList}
        />
      )}
      {/* <CountdownSnackbar
          open={snackbarOpen}
          message={snackbarMessage}
          onClose={() => setSnackbarOpen(false)}
          onTakeTest={handleTakeTest}
          onReplayChapter={handleReplayChapter}
          onCountdownFinish={handleCountdownFinish}
          onStartMovie={handleStartMovie}
          showReplayIcon={showReplayIcon}
          showTakeTestIcon={showTakeTestIcon}
          showStartMovieIcon={showStartMovieIcon}
          showContinueIcon={showContinueIcon}
          countdownOutside={countdownOutside}
        /> */}
    </Box>
  );
};

export default ReactPlayerComponent;
