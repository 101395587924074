import React, { useEffect, useState } from 'react';

interface HtmlContentProps {
  html: string;
}

const HtmlContentComponent: React.FC<HtmlContentProps> = ({ html }) => {
  const [blobUrl, setBlobUrl] = useState<string | null>(null);

  useEffect(() => {
    const createBlobUrl = () => {
      const blob = new Blob([html], { type: 'text/html' });
      return URL.createObjectURL(blob);
    };

    const url = createBlobUrl();
    setBlobUrl(url);

    return () => {
      if (blobUrl) {
        URL.revokeObjectURL(blobUrl);
      }
    };
  }, [html]);

  return (
    <iframe
      src={blobUrl || ''}
      style={{ width: '100%', height: '100%', border: 'none' }}
      sandbox="allow-scripts allow-same-origin"
    ></iframe>
  );
};

export default HtmlContentComponent;
