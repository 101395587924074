import React, { useState, useEffect } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button } from '@mui/material';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DraggableSentence from './DraggableSentence';
import DropArea from './DropArea';
import DraggableBoxSentence from './DraggableBoxSentence';
import { convertNumberToTime, handleTimes } from '../../searchComponents/UtilityFunctions';
interface Sentence {
  id: number;
  audioFileName: string;
  articleId: string;
  paragraphId: number;
  timeStart: string;
  timeEnd: string;
  sentence: string;
}

interface KeyPoint {
  articleId: string;
  topicNumber: number;
  keyPoint: string;
}

interface TopicDetailsTableProps {
  details: {
    endTopicTime: string;
    getAllTopicFromMediaTopicKeyPoints: KeyPoint[];
    sentence: Sentence[];
    startTopicTime: string;
    summary: string;
    topic: string;
    topicNumber: number;
  } | null;
  onSaveChanges: (updatedDetails: any) => void; // Callback to handle saving changes
  movieId: string;
}

const TopicDetailsTable: React.FC<TopicDetailsTableProps> = ({ details, onSaveChanges, movieId }) => {
  const [editableDetails, setEditableDetails] = useState(details);
  const [originalDetails, setOriginalDetails] = useState(details);
  const [previousTopicSentences, setPreviousTopicSentences] = useState<Sentence[]>([]);
  const [nextTopicSentences, setNextTopicSentences] = useState<Sentence[]>([]);

  useEffect(() => {
    setEditableDetails(details);
    setOriginalDetails(details);
  }, [details]);

  if (!details) {
    return null;
  }

  const handleChange = (field: string, value: any) => {
    if (editableDetails) {
      setEditableDetails({
        ...editableDetails,
        [field]: value
      });
    }
  };

  const handleKeyPointChange = (index: number, value: string) => {
    if (editableDetails) {
      const updatedKeyPoints = [...editableDetails.getAllTopicFromMediaTopicKeyPoints];
      updatedKeyPoints[index] = { ...updatedKeyPoints[index], keyPoint: value };
      setEditableDetails({
        ...editableDetails,
        getAllTopicFromMediaTopicKeyPoints: updatedKeyPoints
      });
    }
  };

  const handleSave = () => {
    if (editableDetails) {
      const topicNumber = editableDetails.topicNumber;
      const updatedFields: any = { movieId, topicNumber };

      if (editableDetails.topic !== originalDetails?.topic) {
        updatedFields.topic = editableDetails.topic;
      }

      if (editableDetails.summary !== originalDetails?.summary) {
        updatedFields.summary = editableDetails.summary;
      }

      editableDetails.getAllTopicFromMediaTopicKeyPoints.forEach((keyPoint, index) => {
        if (originalDetails?.getAllTopicFromMediaTopicKeyPoints[index] && keyPoint.keyPoint !== originalDetails.getAllTopicFromMediaTopicKeyPoints[index].keyPoint) {
          if (!updatedFields.keyPoints) updatedFields.keyPoints = {};
          updatedFields.keyPoints[originalDetails?.getAllTopicFromMediaTopicKeyPoints[index].keyPoint] = keyPoint.keyPoint;
        }
      });

      onSaveChanges(updatedFields);
    }
  };

  const moveSentence = (dragIndex: number, hoverIndex: number) => {
    const newSentences = Array.from(editableDetails!.sentence);
    const [movedSentence] = newSentences.splice(dragIndex, 1);
    newSentences.splice(hoverIndex, 0, movedSentence);
    setEditableDetails({
      ...editableDetails!,
      sentence: newSentences,
    });
  };

  const handleDropToPrevious = (item: { index: number }) => {
    const movedSentence = editableDetails!.sentence[item.index];
    const newSentences = editableDetails!.sentence.filter((_, idx) => idx !== item.index);
    setPreviousTopicSentences([...previousTopicSentences, movedSentence]);
    setEditableDetails({
      ...editableDetails!,
      sentence: newSentences,
    });
  };

  const handleDropToNext = (item: { index: number }) => {
    const movedSentence = editableDetails!.sentence[item.index];
    const newSentences = editableDetails!.sentence.filter((_, idx) => idx !== item.index);
    setNextTopicSentences([...nextTopicSentences, movedSentence]);
    setEditableDetails({
      ...editableDetails!,
      sentence: newSentences,
    });
  };

  const handleReturnFromPrevious = (item: { index: number }) => {
    const movedSentence = previousTopicSentences[item.index];
    const newPreviousSentences = previousTopicSentences.filter((_, idx) => idx !== item.index);
    setPreviousTopicSentences(newPreviousSentences);
    setEditableDetails({
      ...editableDetails!,
      sentence: [...editableDetails!.sentence, movedSentence].sort((a, b) => Number(a.timeStart) - Number(b.timeStart)),
    });
  };

  const handleReturnFromNext = (item: { index: number }) => {
    const movedSentence = nextTopicSentences[item.index];
    const newNextSentences = nextTopicSentences.filter((_, idx) => idx !== item.index);
    setNextTopicSentences(newNextSentences);
    setEditableDetails({
      ...editableDetails!,
      sentence: [...editableDetails!.sentence, movedSentence].sort((a, b) => Number(a.timeStart) - Number(b.timeStart)),
    });
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Box sx={{ marginLeft: '2%', marginTop: '2%' }}>
        <Typography variant="h6">Video Details</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Topic</TableCell>
                <TableCell>Summary</TableCell>
                <TableCell>Start Time</TableCell>
                <TableCell>End Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ width: '25%' }}>
                  <TextField
                    multiline
                    value={editableDetails?.topic || ''}
                    onChange={(e) => handleChange('topic', e.target.value)}
                    fullWidth
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    multiline
                    value={editableDetails?.summary || ''}
                    onChange={(e) => handleChange('summary', e.target.value)}
                    fullWidth
                  />
                </TableCell>
                <TableCell sx={{ width: '5%' }}>{convertNumberToTime(Number.parseFloat(details.startTopicTime))}</TableCell>
                <TableCell sx={{ width: '5%' }}>{convertNumberToTime(Number.parseFloat(details.endTopicTime))}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Typography variant="h6" sx={{ marginTop: '2%' }}>Key Points</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Key Point</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {editableDetails?.getAllTopicFromMediaTopicKeyPoints && editableDetails?.getAllTopicFromMediaTopicKeyPoints.map((keyPoint, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <TextField
                      multiline
                      value={keyPoint.keyPoint}
                      onChange={(e) => handleKeyPointChange(index, e.target.value)}
                      fullWidth
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ marginTop: '2%' }}>
          <Typography variant="h6">Previous Topic Sentences</Typography>
          <Box sx={{ border: '1px solid gray', padding: '10px', margin: '10px 0' }}>
            {previousTopicSentences.map((sentence, index) => (
              <DraggableBoxSentence
                key={sentence.id}
                index={index}
                sentence={sentence}
                moveSentence={handleReturnFromPrevious}
                type="previousSentence"
              />
            ))}
          </Box>
        </Box>
        <DropArea label="Drop here to move to previous topic" onDrop={handleDropToPrevious} type="sentence" />
        <Typography variant="h6" sx={{ marginTop: '2%' }}>Sentences</Typography>
        <DropArea label="Drop here to return to main table" onDrop={handleReturnFromPrevious} type="previousSentence" />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Audio File</TableCell>
                <TableCell>Paragraph ID</TableCell>
                <TableCell>Sentence</TableCell>
                <TableCell>Time Start</TableCell>
                <TableCell>Time End</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {editableDetails?.sentence && editableDetails.sentence.map((sentence, index) => (
                <DraggableSentence
                  key={sentence.id}
                  index={index}
                  sentence={sentence}
                  moveSentence={moveSentence}
                  type="sentence"
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <DropArea label="Drop here to move to next topic" onDrop={handleDropToNext} type="sentence" />
        <Box sx={{ marginTop: '2%' }}>
          <Typography variant="h6">Next Topic Sentences</Typography>
          <Box sx={{ border: '1px solid gray', padding: '10px', margin: '10px 0' }}>
            {nextTopicSentences.map((sentence, index) => (
              <DraggableBoxSentence
                key={sentence.id}
                index={index}
                sentence={sentence}
                moveSentence={handleReturnFromNext}
                type="nextSentence"
              />
            ))}
          </Box>
        </Box>
        <DropArea label="Drop here to return to main table" onDrop={handleReturnFromNext} type="nextSentence" />
        <Button variant="contained" color="primary" onClick={handleSave} sx={{ marginTop: '2%' }}>
          Save Changes
        </Button>
      </Box>
    </DndProvider>
  );
};

export default TopicDetailsTable;
