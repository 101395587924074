import React from 'react';
import { Box, Link } from '@mui/material';
import { secondsToMinutes,convertToYoutubeTime,checkFromYoutube } from './UtilityFunctions';
import MovieLink from './MovieLink';
import RenderSnapshotImage from './RenderSnapshotImage';
import InteractWithMediaButton from './InteractWithMediaButton';

interface OtherSearchResultItemProps {
    item:any,
    result:any,
    onMediaInteraction : (item:any,movieData:any) => void;
    style:any
}
const OtherSearchResultItem:React.FC<OtherSearchResultItemProps> = ({item,result,onMediaInteraction,style}) => {
    const isYoutubeVideo = checkFromYoutube(result.pageLink);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: '10px',
            marginLeft: '1rem',
           
            
        }}>
            {/* {isYoutubeVideo ? renderYoutubeIframe(item,result) : renderSnapshotImage(item,result)} */} 
            <InteractWithMediaButton item={item} movieDetails={result}  onMediaInteraction={onMediaInteraction}/>
            <Link href={`${result.pageLink}&t=${convertToYoutubeTime(secondsToMinutes(result.startTime))}` || '#'} target="_blank" rel="noopener noreferrer" >                  
                <RenderSnapshotImage item={item} result={result} movieDetails={result} onMediaInteraction={onMediaInteraction}/>
            </Link>
            <MovieLink result={result} style={style}/>
        </Box>
    );
}

export default OtherSearchResultItem;