import axiosInstance from "../interceptor";
import { CHATGPT_API_URL } from "../api";


const API = "/api/v1/transcript";

export const generateTextFromAudioText = async (text: string, sessionId: string, apiKey: string) => {
  return axiosInstance.post(`${CHATGPT_API_URL}${API}/generateTextConclusion`, { text, sessionId, apiKey });
};
export interface SendData {
  sessionId: string;
  apiKey: string;
  text: string;
  slideNumber?: number;
}
export const generateAnswerFromTextQuestion = async (sendData: SendData) => {
  return axiosInstance.post(`${CHATGPT_API_URL}${API}/generateTextQuestion`, sendData);
}
export const generateQandA = async (sendData: SendData) => {
  return axiosInstance.post(`${CHATGPT_API_URL}${API}/generateQandA`, sendData);
}
export const generateResultFromSearch = async (sendData: SendData) => {
  return axiosInstance.post(`${CHATGPT_API_URL}${API}/searchAnswering`, sendData);
}
export const generatePresentationSlide = async (sendData: SendData) => {
  return axiosInstance.post(`${CHATGPT_API_URL}${API}/presentationSlide`, sendData);
}
export const generateTableSummary = async (sendData: SendData) => {
  return axiosInstance.post(`${CHATGPT_API_URL}${API}/tableSummary`, sendData);
}
