

import React, {useState,useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, useTheme,makeStyles,IconButton,Grid,Tooltip } from '@material-ui/core';
import Search from '@mui/icons-material/Search';
import Recorder from '@mui/icons-material/Mic';
import MP3Upload from '@mui/icons-material/UploadFile';
import UrlUpload from '@mui/icons-material/Link';
import DvrIcon from '@mui/icons-material/Dvr';
import { v4 as uuidv4 } from 'uuid';

import { AppDispatch } from '../../store';
import {clearSearchResult} from '../../features/searchMediaSlice';

interface MainIconBarProps {
    showInteractIcon : boolean;
    setActiveComponent: (componentName: string) => void;
    setSelectedSessionId:any;
    selectedSessionId:any;
  
}

const MainIconBar:React.FC<MainIconBarProps> = ({showInteractIcon,setActiveComponent,selectedSessionId,setSelectedSessionId}) => {

   
    
    const [activeComponent, setActiveComponentState] = useState(showInteractIcon ? 'interact' : 'search');
    
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch<AppDispatch>();

    const permanentRoutes   = [
      { label: <Search />, tooltip: "Search",name:"search" },
      { label: <Recorder />, tooltip: "Recorder",name:"recorder" },
      { label: <MP3Upload />, tooltip: "Upload",name:"upload" },
      { label: <UrlUpload />, tooltip: "URL",name:"uploadFromUrl"},
    ];
   
    const interactRoute = { label: <DvrIcon />, tooltip: "Interact", name: "interact" };
    const routes = showInteractIcon ? [...permanentRoutes, interactRoute] : permanentRoutes;

    const initialClickedStates = routes.map((route, index) => {
        if (showInteractIcon && route.name === 'interact') {
          return true; 
        }
        return  route.name === 'search'
    }); 

    const [clickedStates, setClickedStates] = useState(initialClickedStates);
   
    useEffect(() => {
        if (showInteractIcon) {
          selectInteractButton();
        }
        if(!showInteractIcon){
          handleButtonClick(0, 'search',selectedSessionId? selectedSessionId:undefined)
        }
      }, [showInteractIcon]);

   
  
    const handleButtonClick = (index: number, componentName: string,selectedSessionId?:string) => {
        let updatedClickedStates = clickedStates.map((state, idx) => idx === index);     
        if(componentName==='search'){
            const sessionSearchId = uuidv4();
            localStorage.setItem('sessionSearchId', sessionSearchId);       
            selectedSessionId? setSelectedSessionId(selectedSessionId):setSelectedSessionId(null);
            dispatch(clearSearchResult());
        }
        setClickedStates(updatedClickedStates);
        setActiveComponent(componentName);
        setActiveComponentState(componentName);   
    };

    const selectInteractButton = () => {
        const interactIndex = routes.findIndex(route => route.name === 'interact');
        if (interactIndex !== -1) {
          handleButtonClick(interactIndex, 'interact');
        }
      };

    return(
        <Box>
            {routes.map((route:any, index) => (
                <Grid item key={index} className={classes.gridItem}>
                  <Tooltip title={route.tooltip}>
                      <IconButton
                      color="inherit"                
                      onClick={() => handleButtonClick(index, route.name)}
                      className={activeComponent === route.name ? classes.clickedIcon : classes.iconButton}
                      //style={{ color: clickedStates[index] ? classes.clickedIcon : 'white' }}
                      >
                      <span className={classes.icon}> {route.label}</span>
                      <span className={classes.iconText}>{route.tooltip}</span>
                      </IconButton>
                  </Tooltip>
                </Grid>
            ))}
          </Box>
    )
}

export default MainIconBar;

const useStyles = makeStyles((theme) => ({
 
  gridItem: {
    // display: 'flex',
    height: '30px',
    marginTop:'10px',
    marginLeft: '0px',
   // whiteSpace: 'nowrap',   
    '&:hover': {      
      backgroundColor: '#dfd6d6',
      borderRadius: '5px', 
      color: 'black',  
      bottom:'40px'     
    },
  },
  clickedIcon: {
    backgroundColor: 'white', 
    borderRadius: '5px',
    width: '210px',
    justifyContent: 'left',
    height: '30px',    
  },
  iconButton: {    
    color: 'white',
    width: '210px',   
    height: '30px',
    justifyContent: 'left',
    '&:hover': {
      color: 'black',      
    }
  },
  iconText: {
    fontSize: '15px', 
    marginLeft: '10px',
    marginTop:'5px',
    [theme.breakpoints.down('sm')]: {
      display: 'none', // Hide text on small screens
    },
  },
  icon: {
    marginTop:'10px'
  },
}));
  