import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface VideoState {
  currentVideo: {
    movieId: string;
    subVideoId: string;
    pageNumber?: number; // Add pageNumber to track the current PDF page if applicable
  } | null;
}

const initialState: VideoState = {
  currentVideo: null,
};

const videoSlice = createSlice({
  name: 'playerToc',
  initialState,
  reducers: {
    setCurrentlyPlayingSubVideo: (
      state,
      action: PayloadAction<{ movieId: string; subVideoId: string; pageNumber?: number }>
    ) => {
      state.currentVideo = action.payload;
    },
  },
});

export const { setCurrentlyPlayingSubVideo } = videoSlice.actions;
export default videoSlice.reducer;
