

import React, { useEffect, useState } from 'react';
import { tableMetadata } from './configurationTable';
import GenericForms from '../genericComponents/GenericForms';
import { fetchAllTableNamesEntityTypes } from '../../features/tableSlice'
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../store';


const ManagerForms: React.FC = () => {

  const [entityTypes, setEntityTypes] = useState<string[]>(Object.keys(tableMetadata));
  const dispatch = useDispatch<AppDispatch>();
  const tableData = useSelector((state: RootState) => state.manager.entitiesKeys);

  useEffect(() => {
    dispatch(fetchAllTableNamesEntityTypes({ tablesNames: entityTypes }));
  }, []);


  return (
    <div>
      <GenericForms entityTypes={entityTypes} fieldEntity={tableData} />
    </div>
  );
}

export default ManagerForms;