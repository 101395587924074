import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getMermaidSvg } from '../api/serverApis/mermaidApi';

interface MermaidState {
  diagrams: string[];
  loading: boolean;
  error: string | null | undefined;
}

export interface GenerateSvgPayload {
  userId: string;
  diagramCode: string;
  chatDiagramId: string;
}

const initialState: MermaidState = {
  diagrams: [],
  loading: false,
  error: null,
};

export const getMermaidSvgThunk = createAsyncThunk('mermaid/generateSvg', async ({ userId, diagramCode, chatDiagramId }: GenerateSvgPayload) => {
  const response = await getMermaidSvg({ userId, diagramCode, chatDiagramId });
  return response.data;
}
);

const mermaidSlice = createSlice({
  name: 'mermaid',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMermaidSvgThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMermaidSvgThunk.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.diagrams.push(action.payload);
      })
      .addCase(getMermaidSvgThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default mermaidSlice.reducer;