import React, { useState } from 'react';
import { Typography, Box, useTheme, Paper, Stack, Divider } from '@mui/material';
import { Semantic, Index } from './configuration/Interface';


interface AnswerProps {
  data: Semantic | Index;
  title?: string;
}

const VideoText: React.FC<AnswerProps> = ({ data, title }) => {
  const theme = useTheme();




  return (
    <Paper elevation={3} sx={{ width: "100%", borderRadius: '20px', padding: theme.spacing(2), margin: 'auto', marginTop: theme.spacing(3) }}>
      {title && <Typography variant="body1" sx={{ fontSize: '0.8rem', textAlign: 'center', color: 'gray' }}>
        <Typography> {title}<Divider /></Typography>
      </Typography>}
      <Stack direction="row" alignItems="flex-start" spacing={2} sx={{ marginTop: theme.spacing(4) }}>
        <Typography variant="body1" sx={{ fontSize: '0.8rem' }}>
          {data.pdfText}
        </Typography>
      </Stack>
    </Paper>
  );
};

export default VideoText;
