



import axiosInstance from "../interceptor";
import { CHATGPT_API_URL, GATEWAY_API_URL } from "../api";

//const api = '/api/v1/courses';
const api = '/api/coursesService/api/v1/courses';

// export const getAllCourses = async () => {
//   return axiosInstance.get(`${CHATGPT_API_URL}${api}/getAllCourses`);
// }

// export const getCourseContent = async (courseId: string) => {
//   return axiosInstance.get(`${CHATGPT_API_URL}${api}/getCourseContent/${courseId}`);
// }

// export const getCourseMovieData = async (movieId: string, id: string) => {
//   if (!id) {
//     id = "-1";
//   }
//   return axiosInstance.get(`${CHATGPT_API_URL}${api}/getCourseMovieData/${movieId}/${id}`);
// }

export const getAllCourses = async () => {
  return axiosInstance.get(`${GATEWAY_API_URL}${api}/getAllCourses`);
}

export const getCourseContent = async (courseId: string) => {
  return axiosInstance.get(`${GATEWAY_API_URL}${api}/getCourseContent/${courseId}`);
}
export const getTocByCourseId = async (courseId: string) => {
  return axiosInstance.get(`${GATEWAY_API_URL}${api}/getTocByCourseId/${courseId}`);
}
export const getCourseMovieData = async (movieId: string, id: string) => {
  if (!id) {
    id = "-1";
  }
  return axiosInstance.get(`${GATEWAY_API_URL}${api}/getCourseMovieData/${movieId}/${id}`);
}
export const getCourseMovieDataWithNewBreakDown = async (movieId: string, id: string) => {
  if (!id) {
    id = "-1";
  }
  return axiosInstance.get(`${GATEWAY_API_URL}${api}/getCourseMovieDataWithNewBreakDown/${movieId}/${id}`);
}
// export const getAllManagementData = async (courseId: string) => {
//   return axiosInstance.get(`${GATEWAY_API_URL}${api}/getAllManagementData/${courseId}`);
// }
export const getAllTOCManagementData = async (courseId: string) => {
  courseId = "documentaries";
  return axiosInstance.get(`${GATEWAY_API_URL}${api}/getAllTOCManagementData/${courseId}`);
}

export const getCourseSpecificTopicsKeyPointsAndSentences = async (movieId: string, topicId: string) => {
  return axiosInstance.get(`${GATEWAY_API_URL}${api}/getCourseSpecificTopicsKeyPointsAndSentences/${movieId}/${topicId}`);
}