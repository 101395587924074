const authenticationServer = "localhost";
const authenticationServer_azure = "https://authentication-service.gentleplant-8c3ed038.australiaeast.azurecontainerapps.io";
const portAuth = "1111";
const AUTH_API_URL_LOCAL = `http://${authenticationServer}:${portAuth}`;
const AZURE_WINDOWS_SERVER = 'https://demo.xarcam.ai';
const AZURE_WINDOWS_SERVER_AND_PORT_AUTH = `${AZURE_WINDOWS_SERVER}:${portAuth}`;

const AUTH_API_URL_AZURE = `${authenticationServer_azure}`;
export const AUTH_API_URL = AZURE_WINDOWS_SERVER_AND_PORT_AUTH;//AUTH_API_URL_LOCAL////;

const chatGptServer = "localhost";
const thiredPartyServer_auzure = "https://thiredpartyservice.gentleplant-8c3ed038.australiaeast.azurecontainerapps.io";
const portChatGpt = "1112";

const CHATGPT_API_LOCAL = `http://${chatGptServer}:${portChatGpt}`;
const CHATGPT_API_AZURE = `${thiredPartyServer_auzure}`;
const AZURE_WINDOWS_SERVER_AND_PORT_THIRD = `${AZURE_WINDOWS_SERVER}:${portChatGpt}`;



export const CHATGPT_API_URL = AZURE_WINDOWS_SERVER_AND_PORT_THIRD;//CHATGPT_API_LOCAL//

const portGateway = "11100";
const localhost = "http://localhost";
// const LOCAL_GATEWAY = `${AZURE_WINDOWS_SERVER}:${portGateway}`;
const LOCAL_GATEWAY = `${AZURE_WINDOWS_SERVER}`;
export const GATEWAY_API_URL = LOCAL_GATEWAY;

