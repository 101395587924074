

import axiosInstance from "../interceptor";
import { CHATGPT_API_URL } from "../api";


const API = "/api/v1/searchSeinfeld";

export interface SearchSeinfeldSendData {
  sessionId: string;
  text: string;  
}


export const searchSeinfeld = async (sendData: SearchSeinfeldSendData) => {
    return axiosInstance.post(`${CHATGPT_API_URL}${API}/searchSeinfeld`,{sendData});
}