

import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

// Dummy data for the autocomplete suggestions. Replace this with your actual data source.
const suggestions = [
  { label: 'Course 1' },
  { label: 'Course 2' },
  { label: 'Video 1' },
  { label: 'Video 2' },
  // Add more suggestions here
];

const AutocompleteSearchBox = ({ onSearchSelect }: { onSearchSelect: (event: any, newValue: any) => void }) => {
  return (
    <Autocomplete
      disablePortal
      options={suggestions}
      onChange={onSearchSelect}
      renderInput={(params) => <TextField {...params} label="Search" />}
    />
  );
};

export default AutocompleteSearchBox;