import React, { useState, useEffect } from 'react';
import VideoSemanticPlaylist from './videoSematicPlaylist';
import VideoIndexPlaylist from './videoIndexPlaylist';

import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../../store';
import { Box } from '@mui/material';
import { Index, Semantic } from '../configuration/Interface';

interface MainSearchProps {
  data: any;
  theme: any;
  handlePlaylistSelectClick: any;
  solverSearchData: any;
  searchData: any;

}
const MainSearch: React.FC<MainSearchProps> = ({ data, theme, handlePlaylistSelectClick, solverSearchData, searchData }) => {



  const lightColors = ['#F0F0F0', '#E0E0E0']; // Softer shades for light theme
  const darkColors = ['#333333', '#404040']; // Deeper shades for dark theme
  const colors = theme.palette.mode === 'dark' ? darkColors : lightColors;
  const dispatch = useDispatch<AppDispatch>();

  const isHebrew = (text: string) => /[\u0590-\u05FF\uFB1D-\uFB4F\uFB50-\uFDFF]/.test(text);
  return (
    <Box sx={{ margin: 'auto', mt: 4, width: '98%', padding: '20px', marginTop: '-4%', marginLeft: '-1.5rem', marginBottom: '100px' }}>
      {data && data.semantic && data.semantic.map((semantic: Semantic, sIndex: number) => (
        <VideoSemanticPlaylist
          key={sIndex}
          data={semantic}
          isOpen={false} // Since we are using accordion, isOpen is controlled differently   
          handlePlaylistSelectClick={handlePlaylistSelectClick}
          solverSearchData={solverSearchData}
          searchData={searchData}
          playlistId={data.playListId}
        />
      ))}
      {data && data.index && data.index.map((indexItem: Index, iIndex: number) => (
        <VideoIndexPlaylist
          key={iIndex}
          question={"l"}
          data={indexItem}
          isOpen={false} // Since we are using accordion, isOpen is controlled differently         
          handlePlaylistSelectClick={handlePlaylistSelectClick}
          solverSearchData={solverSearchData}
          searchData={searchData}
        />
      ))}
    </Box>
  )

}

export default MainSearch;
