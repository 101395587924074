import React, { useState, useRef, useEffect } from 'react';
import { uploadAudio } from '../../features/audioSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { Button, TextField } from '@material-ui/core';

interface RecorderProps {
  onStartRecording: () => string;
  onStopRecording: (chunks: Blob[], sessionId: string, rawNumber: number, recordName: string) => void;
}

export const Recorder: React.FC<RecorderProps> = ({ onStartRecording, onStopRecording }) => {
  const sessionId: string = onStartRecording();
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const audioChunks = useRef<Blob[]>([]);
  const recordingInterval = useRef<NodeJS.Timeout | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const [recordName, setRecordName] = useState<string>("");

  const firstTime = true;
  let rawNumber = 0;
  const startRecording = async () => {
    rawNumber = 0;
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorder.current = new MediaRecorder(stream);

    mediaRecorder.current.addEventListener('dataavailable', event => {
      audioChunks.current.push(event.data);
    });

    recordingInterval.current = setInterval(() => {
      if (mediaRecorder.current?.state === 'recording') {
        mediaRecorder.current.stop();
        if (audioChunks.current.length > 0 && sessionId) {
          const audio: Blob[] = audioChunks.current;
          dispatch(uploadAudio({ audio, sessionId, rawNumber, recordName }));
        }
        rawNumber++;
        audioChunks.current = [];
        mediaRecorder.current.start();
      }
    }, 10000);
    mediaRecorder.current.start();
  };

  const stopRecording = () => {
    if (mediaRecorder.current?.state === 'recording') {
      mediaRecorder.current.stop();
      clearInterval(recordingInterval.current as NodeJS.Timeout);
      onStopRecording(audioChunks.current, sessionId, rawNumber, recordName);
    }
  };

  useEffect(() => {
    return () => {
      clearInterval(recordingInterval.current as NodeJS.Timeout);
    };
  }, []);

  return (
    <div style={{ width: '100%', paddingBottom: '50px', display: 'flex', alignItems: 'center', gap: '10px' }}>

      <TextField
        label="Record Name"
        value={recordName}
        onChange={(e) => setRecordName(e.target.value)}
        variant="outlined"
        style={{ width: '50%', marginBottom: '10px', fontSize: '12px' }} // Updated width to 50%
        InputProps={{
          style: { fontSize: '12px', padding: '5px 10px' }
        }}
        InputLabelProps={{
          style: { fontSize: '12px' }
        }}
      />

      <Button
        variant="contained"
        color="primary"
        onClick={startRecording}
        style={{ fontSize: '10px', padding: '5px 10px' }}
      >
        Start Recording
      </Button>

      <Button
        variant="contained"
        color="secondary"
        onClick={stopRecording}
        style={{ fontSize: '10px', padding: '5px 10px' }}
      >
        Stop Recording
      </Button>
    </div>
  );


};