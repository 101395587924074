

import axiosInstance from "../interceptor";
import { AUTH_API_URL, GATEWAY_API_URL } from "../api";

//const API = "/api/v1/tablesAndInformation";
const API = '/api/authenticationService/api/v1/tablesAndInformation';
// export interface TableSendData {
//   tableName?: string;
//   tablesNames?: string[];
// }

// export const getTable = async (sendData: TableSendData) => {
//   return axiosInstance.post(`${AUTH_API_URL}${API}/table`, sendData);
// }

// export const getAllUsersIsAndEmail = async (searchTerm: string) => {
//   return axiosInstance.post(`${AUTH_API_URL}${API}/allUsersFromSearch`, { params: { searchTerm } });
// }

// export const getRoleCustomerAndRuleDataFromUserEmail = async (userEmail: string) => {
//   return axiosInstance.post(`${AUTH_API_URL}${API}/getRoleCustomerAndRuleDataFromUserEmail`, { userEmail });
// }

// export const getFieldsFromTables = async (sendData: TableSendData) => {
//   return axiosInstance.post(`${AUTH_API_URL}${API}/fieldsFromTables`, sendData);
// }

export interface TableSendData {
  tableName?: string;
  tablesNames?: string[];
}

export const getTable = async (sendData: TableSendData) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${API}/table`, sendData);
}

export const getAllUsersIsAndEmail = async (searchTerm: string) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${API}/allUsersFromSearch`, { params: { searchTerm } });
}

export const getRoleCustomerAndRuleDataFromUserEmail = async (userEmail: string) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${API}/getRoleCustomerAndRuleDataFromUserEmail`, { userEmail });
}

export const getFieldsFromTables = async (sendData: TableSendData) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${API}/fieldsFromTables`, sendData);
}