import {Box, Typography, IconButton } from '@mui/material';
import React, {useState,useEffect} from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import OtherSearchResultItem from './OtherSearchResultItem';
import { v4 as uuidv4 } from 'uuid';

interface OtherSearchResultSectionProps {
    title:string,
    item:any,
    otherSearchResult:any,
    index:number,
    sectionRef:any,
    style:any,
    onMediaInteraction : (item:any,movieData:any) => void;
}

const OtherSearchResultSection:React.FC<OtherSearchResultSectionProps> = ({title,item,otherSearchResult,index,sectionRef,style,onMediaInteraction}) => {

    const getSectionRef = (sectionRefs:any, index: number) => {
        if (!sectionRefs.current[index]) {
          sectionRefs.current[index] = React.createRef();
        }
        return sectionRefs.current[index];
      };

    const section = getSectionRef(sectionRef, index);
    const [showScrollButtons, setShowScrollButtons] = useState(false);
    let scrollInterval:any;

    const startScrolling = (direction:any) => {
      const scrollStep = direction === 'left' ? -100 : 100;
      scrollInterval = setInterval(() => {
        if (section.current) {
          section.current.scrollLeft += scrollStep;
        }
      }, 100); // Adjust the timing as needed
    };
  
    const stopScrolling = () => {
      clearInterval(scrollInterval);
    };
    useEffect(() => {
      if (section.current) {
          const hasOverflow = section.current.scrollWidth > section.current.clientWidth;
          setShowScrollButtons(hasOverflow);
      }
  }, [otherSearchResult]);
    return (
      <Box key = {uuidv4()}>
        <Typography variant="h6" sx={{ marginLeft: '1rem', color: style.lightPurple, mt: 2 }}>
          {title}
        </Typography>
        <Box sx={{ position: 'relative' }}>
        {showScrollButtons && (<IconButton 
            onMouseDown={() => startScrolling('left')} 
            onMouseUp={stopScrolling} 
            onMouseLeave={stopScrolling}
            sx={{ 
              position: 'absolute', 
              left: 0, 
              top: '50%', 
              transform: 'translateY(-50%)', 
              zIndex: 100, 
              color: '#fff',
              backgroundColor: 'rgba(0, 0, 0, 0.7)', // Black with less transparency
              borderRadius: '50%', // Circular shape
              width: '40px', // Adjust size as needed
              height: '40px', // Adjust size as needed
              padding: '10px', // Padding around the icon
            }}
       >
        <ArrowBackIosIcon />
      </IconButton>)}
      {showScrollButtons && (<IconButton 
            onMouseDown={() => startScrolling('right')} 
            onMouseUp={stopScrolling} 
            onMouseLeave={stopScrolling}
            sx={{ 
              position: 'absolute', 
              right: 0, 
              top: '50%', 
              transform: 'translateY(-50%)', 
              zIndex: 100, 
              color: '#fff',
              backgroundColor: 'rgba(0, 0, 0, 0.7)', // Black with less transparency
              borderRadius: '50%', // Circular shape
              width: '40px', // Adjust size as needed
              height: '40px', // Adjust size as needed
              padding: '10px', // Padding around the icon
            }}
       >
          <ArrowForwardIosIcon/>
      </IconButton>)}
          <Box ref={section} sx={{ display: 'flex', flexDirection: 'row', overflowX: 'hidden', gap: '10px', padding: '10px 0' }}>
          {otherSearchResult && otherSearchResult.map((otherResult: any, otherIndex: any) => (
              <OtherSearchResultItem item={item} result={otherResult} onMediaInteraction={onMediaInteraction} style={style} key={otherIndex}/>
           ))}
          </Box>
        </Box>
      </Box>
    );
}

export default OtherSearchResultSection;