import React, { useState, FC } from 'react';
import { Button, TextField, Checkbox, FormControlLabel, IconButton,makeStyles,CircularProgress,Box, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'; // Import the close icon
import AddIcon from '@material-ui/icons/Add'; // Import the add icon

interface MovieData {
  url: string;
  name: string;
  additionalInfo: string;
  links: string[];
  isPrivate: boolean;
}

interface UrlUploadProps {
  onUploadUrl: (movies: MovieData[]) => any;
}

export const UrlUpload: FC<UrlUploadProps> = ({ onUploadUrl }) => {
  const [movies, setMovies] = useState<MovieData[]>([{ url: '', name: '', additionalInfo: '', links: [], isPrivate: false }]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const validateRequiredFieldsFilled = (updatedMovies: MovieData[]) => {
    return updatedMovies.every(movie => movie.url.trim() && movie.name.trim());
  };
 const handleMovieDataChange = (index: number, field: keyof MovieData, value: string | boolean) => {
    const updatedMovies = movies.map((movie, idx) => {
      if (idx === index) {
        return {
          ...movie,
          [field]: value,
        };
      }
      return movie;
    });
    setMovies(updatedMovies);

    // Remove error message if all required fields are filled
    if (validateRequiredFieldsFilled(updatedMovies)) {
      setError('');
    }
  };
  const handleLinkChange = (movieIndex: number, linkIndex: number, link: string) => {
    const updatedMovies = [...movies];
    updatedMovies[movieIndex].links[linkIndex] = link;
    setMovies(updatedMovies);
  };

  const addLink = (movieIndex: number) => {
    const updatedMovies = [...movies];
    updatedMovies[movieIndex].links.push('');
    setMovies(updatedMovies);
  };

  const removeLink = (movieIndex: number, linkIndex: number) => {
    const updatedMovies = [...movies];
    updatedMovies[movieIndex].links.splice(linkIndex, 1);
    setMovies(updatedMovies);
  };

  const handleDeleteMovie = (index: number) => {
    setMovies(movies => movies.filter((_, idx) => idx !== index));
  };

  const handleUploadClick = async () => {
    if (movies.some(movie => !movie.url.trim() || !movie.name.trim())) {
      setError('Please fill in all required fields. ( Video URL and Movie name )');
      return;
    }
    setIsLoading(true);
    setError('');
    setSuccessMessage('');
    try {
      const response = await onUploadUrl(movies);
      if(response.payload.message === 'success'){
        setMovies([{ url: '', name: '', additionalInfo: '', links: [], isPrivate: false }]);
        setSuccessMessage("All of your movies are digested. You can start searching for them.");
        setTimeout(() => {
          setSuccessMessage('');
        }, 5000);
      }
      if(response?.error?.name){
        setError('An error occurred, please try again.');
      }
    } catch (error) {
      setError('An error occurred, please try again.');
      console.error(error);
    }
    setIsLoading(false);
  };
 
   const renderRequiredLabel = (label: string, value: string) => {
    return (
      <>
        {label}
        {value.trim() === '' && <span className={classes.redDot}>•</span>}
      </>
    );
  };
  const addNewMovie = () => {
    setMovies([...movies, { url: '', name: '', additionalInfo: '', links: [], isPrivate: false }]);
  };
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {error && <div style={{ color: 'red', marginBottom: '10px' }}>{error}</div>}
      {successMessage && (
        <div style={{ color: 'green', marginBottom: '10px', fontSize: '18px', fontWeight: 'bold' }}>
          {successMessage}
        </div>
      )}
      {isLoading ? (
         <Box className={classes.loadingContainer}>
         <CircularProgress color="inherit" />
         <Typography variant="h6" color="inherit">
            we are digesting your movies... please be patient
         </Typography>
       </Box>
      ) : (
        <>
          {movies.map((movie, index) => (
            <div key={index} className={classes.movieContainer}>
              <TextField
               required
                variant="outlined"
                label={renderRequiredLabel("Video URL", movie.url)}
                placeholder="Video URL"
                value={movie.url}
                onChange={(e) => handleMovieDataChange(index, 'url', e.target.value)}               
              />
              <TextField
               required
                variant="outlined"
                label={renderRequiredLabel("Movie Name", movie.name)}
                placeholder="Movie Name"
                value={movie.name}
                onChange={(e) => handleMovieDataChange(index, 'name', e.target.value)}               
              />
              <TextField
                variant="outlined"
                placeholder="Additional Info"
                multiline
                rows={4}
                value={movie.additionalInfo}
                onChange={(e) => handleMovieDataChange(index, 'additionalInfo', e.target.value)}
              />
              <div className={classes.linkContainer}>
                {movie.links.map((link, linkIndex) => (
                  <div key={linkIndex} style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                    <TextField
                      variant="outlined"
                      placeholder="Link URL"
                      value={link}
                      onChange={(e) => handleLinkChange(index, linkIndex, e.target.value)}
                      style={{ width: '200px' }}
                    />
                    <IconButton onClick={() => removeLink(index, linkIndex)}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                ))}
                <Button size="small" startIcon={<AddIcon />} onClick={() => addLink(index)}>
                  Add Link
                </Button>
              </div>
              <FormControlLabel
                control={<Checkbox checked={movie.isPrivate} onChange={(e) => handleMovieDataChange(index, 'isPrivate', e.target.checked)} />}
                label="Private"
              />
              <IconButton onClick={() => handleDeleteMovie(index)}>
                <CloseIcon />
              </IconButton>
            </div>
          ))}
          <div className={classes.buttonGroup}>
            <Button variant="contained" color="primary" onClick={addNewMovie} disabled={isLoading}>
              Add Another Movie
            </Button>
            <Button variant="contained" color="secondary" onClick={handleUploadClick} disabled={isLoading}>
              Upload Movies
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: 'scroll', 
    height: '70vh',
    margin: theme.spacing(1),
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        width: '100%' // Full width on small screens
      },
    },
  },
  movieContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column', // Stack elements vertically on small screens
    },
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
  },
  linkItem: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column', // Stack elements vertically on small screens
    },
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
    color: 'green', // Choose a color that fits your design
  },
  redDot: {
    color: 'red',
    marginLeft: '2px',
  },
  buttonGroup: {
    display: 'flex',
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column', // Stack buttons vertically on small screens
    },
  },
}));