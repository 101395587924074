import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Divider } from '@mui/material';

interface DynamicEnergyDataTableProps {
  data: string | undefined;
  title?: string
}

const DynamicEnergyDataTable: React.FC<DynamicEnergyDataTableProps> = ({ data, title }) => {
  const [tableData, setTableData] = useState<Record<string, string>[]>([]);
  const [columns, setColumns] = useState<string[]>([]);

  useEffect(() => {
    checkIfDataValid();
  }, [data]);

  const checkIfDataValid = () => {
    try {
      if (!data) return;
      const newData = JSON.parse(data);
      setTableData(newData);
      const columnsData = newData.length > 0 ? Object.keys(newData[0]) : [];
      setColumns(columnsData);
    }
    catch (e) {
      return;
    }
  }

  return (
    <TableContainer component={Paper} sx={{ width: '100%', maxWidth: '600px', margin: 'auto', mt: 4, boxShadow: '0px 0px 10px rgba(0,0,0,0.1)', overflowX: 'auto' }}>
      {title && <Typography variant="body1" sx={{ fontSize: '0.8rem', textAlign: 'center', color: 'gray' }}>
        <Typography> {title}<Divider /></Typography>
      </Typography>}

      <Table aria-label="dynamic table">

        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell key={index} align={index > 0 ? "right" : "left"} sx={{ padding: '6px 10px', fontSize: '0.875rem' }}>
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {columns.map((column, colIndex) => (
                <TableCell key={colIndex} align={colIndex > 0 ? "right" : "left"} sx={{ padding: '6px 10px', fontSize: '0.875rem' }}>
                  {row[column]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default DynamicEnergyDataTable;
