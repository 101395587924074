

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { generateResultFromSearch, SearchSendData, getAllArchivesSearchForUser, getSearchBySessionId, deleteSearchBySessionIdAndUserId } from '../api/serverApis/searchMovieApi';
import { searchSeinfeld, SearchSeinfeldSendData } from '../api/serverApis/searchSeinfeldApi';
export interface searchResult {
  answerTitle: any;
  searchResult: any;
  loading: boolean;
  searchSeinfeld: any;
  error: string | null | undefined;
}

const initialState: searchResult = {
  answerTitle: '',
  searchResult: [],
  searchSeinfeld: [],
  loading: false,
  error: null,
};

export const fetchMediaForSeinfeld = createAsyncThunk('textAudio/fetchMediaForSeinfeld', async ({ text, sessionId, isImageSearch, audioName, mainCategory }: { text: string, sessionId: string, isImageSearch: boolean, audioName: string, mainCategory: string }) => {
  const sendData: SearchSendData = {
    sessionId,
    text: text,
  };
  const response = await searchSeinfeld(sendData);
  return response.data;
});
export const fetchSearchSeinfeld = createAsyncThunk('textAudio/fetchSearchSeinfeld', async ({ text, sessionId, isImageSearch, audioName, mainCategory }: { text: string, sessionId: string, isImageSearch: boolean, audioName: string, mainCategory: string }) => {
  const sendData: SearchSendData = {
    sessionId,
    text: text,
    isImageSearch: false,
    audioName: '',
    mainCategory: ''
  };
  const response = await generateResultFromSearch(sendData);
  return response.data;
});
export const fetchMediaSearch = createAsyncThunk('textAudio/fetchSearch', async ({ text, sessionId, isImageSearch, audioName, mainCategory }: { text: string, sessionId: string, isImageSearch: boolean, audioName: string, mainCategory: string }) => {
  const sendData: SearchSendData = {
    sessionId,
    text: text,
    isImageSearch,
    audioName,
    mainCategory
  };
  const response = await generateResultFromSearch(sendData);
  return response.data;
});

export const fetchMediaSearchAzureMainCategory = createAsyncThunk('textAudio/fetchMediaSearchAzureMainCategory', async ({ text, sessionId, isImageSearch, audioName, mainCategory }: { text: string, sessionId: string, isImageSearch: boolean, audioName: string, mainCategory: string }) => {
  const sendData: SearchSendData = {
    sessionId,
    text: text,
    isImageSearch,
    audioName,
    mainCategory: "azure"
  };
  const response = await generateResultFromSearch(sendData);
  return response.data;
});
export const fetchArchiveSearch = createAsyncThunk('textAudio/fetchArchiveSearch', async () => {
  const response = await getAllArchivesSearchForUser();
  return response.data;
});

export const fetchSearchBySessionId = createAsyncThunk('textAudio/fetchSearchBySessionId', async ({ sessionId }: { sessionId: string }) => {
  const response = await getSearchBySessionId(sessionId);
  return response.data;
});

export const fetchDeleteSearchBySessionIdAndUserId = createAsyncThunk('textAudio/deleteSearchBySessionIdAndUserId', async ({ sessionId }: { sessionId: string }) => {
  const response = await deleteSearchBySessionIdAndUserId(sessionId);
  return response.data;
});

const handleState = (action: any) => {
  return (builder: any) => {
    builder.
      addCase(action.pending, (state: any) => {
        state.loading = true;
      })
      .addCase(action.fulfilled, (state: any, action: PayloadAction<string>) => {
        state.loading = false;
        if (action.type === 'textAudio/fetchSearch/fulfilled') {
          state.searchResult = action.payload;
        }
        if (action.type === 'textAudio/fetchArchiveSearch/fulfilled') {
          state.answerTitle = action.payload;
        }
        if (action.type === 'textAudio/fetchSearchBySessionId/fulfilled') {
          state.searchResult = action.payload;
        }
        if (action.type === 'textAudio/deleteSearchBySessionIdAndUserId/fulfilled') {
          state.answerTitle = action.payload;
        }
        if (action.type === 'textAudio/fetchMediaSearchAzureMainCategory/fulfilled') {
          state.searchResult = action.payload;
        }
        if (action.type === 'textAudio/fetchMediaForSeinfeld/fulfilled') {
          state.searchSeinfeld = action.payload;
        }

      })
      .addCase(action.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
}

const searchMediaSlice = createSlice({
  name: 'textAudio',
  initialState,
  reducers: {
    clearSearchResult: (state) => {
      state.searchResult = [];
    }
  },
  extraReducers: (builder) => {
    handleState(fetchMediaSearch)(builder);
    handleState(fetchArchiveSearch)(builder);
    handleState(fetchSearchBySessionId)(builder);
    handleState(fetchDeleteSearchBySessionIdAndUserId)(builder);
    handleState(fetchMediaSearchAzureMainCategory)(builder);
    handleState(fetchMediaForSeinfeld)(builder);
  }
});
export const { clearSearchResult } = searchMediaSlice.actions;
export default searchMediaSlice.reducer;