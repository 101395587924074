import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { generateTextFromAudioText,generateAnswerFromTextQuestion,SendData, generateQandA,generatePresentationSlide, generateTableSummary } from '../api/serverApis/transcriptsApi';
import {generateResultFromSearch,SearchSendData} from '../api/serverApis/searchMovieApi';

export interface TableSummary {
  taskName: string,
  taskDescription: string
}
export interface SlideData {
  heading: string;
  body: string;
}
interface textAudioState {
  tableSummary: {
    summary: TableSummary[]
  }
  dataObject: {
    qAndAData: string
  };
  searchResult: any;
  presentation: {
    slides: SlideData[];
  };
  text: string;
  loading: boolean;
  error: string | null | undefined;
}


const initialState: textAudioState = {
  tableSummary: {
    summary: []
  },
  dataObject: {
    qAndAData: ''
  },
  presentation: {
    slides: []
  },
  searchResult: [],
  text: '',
  loading: false,
  error: null,
};

export const fetchTextThunk = createAsyncThunk('textAudio/fetchText', async ({ text, sessionId }: { text: string, sessionId: string }) => {
  const apiKey = localStorage.getItem('apiKey') as string;
  const response = await generateTextFromAudioText(text, sessionId, apiKey);
  return response.data;
});

export const fetchAnswerFromTextQuestion = createAsyncThunk('textAudio/fetchAnswerFromTextQuestion', async ({ text, sessionId }: { text: string, sessionId: string }) => {
  const sendData: SendData = {
    sessionId,
    apiKey: localStorage.getItem('apiKey') as string,
    text: text
  };
  const response = await generateAnswerFromTextQuestion(sendData);
  return response.data;
});
export const fetchQandA = createAsyncThunk('textAudio/fetchQandA', async ({ text, sessionId }: { text: string, sessionId: string }) => {
  const sendData: SendData = {
    sessionId,
    apiKey: localStorage.getItem('apiKey') as string,
    text: text
  };
  const response = await generateQandA(sendData);
  return response.data;
});
export const fetchSearch = createAsyncThunk('textAudio/fetchSearch', async ({ text, sessionId }: { text: string, sessionId: string }) => {
  const sendData: SendData = {
    sessionId,
    apiKey: '',
    text: text
  };
  const response = await generateResultFromSearch(sendData);
  return response.data;
});
export const fetchPresentation = createAsyncThunk('textAudio/fetchPresentation', async ({ text, slideNumber, sessionId }: { text: string, slideNumber: number, sessionId: string }) => {
  const sendData: SendData = {
    sessionId,
    apiKey: localStorage.getItem('apiKey') as string,
    text: text,
    slideNumber
  };
  const response = await generatePresentationSlide(sendData);
  return response.data;
});

export const fetchTableSummary = createAsyncThunk('textAudio/fetchTableSummary', async ({ text, sessionId }: { text: string, sessionId: string }) => {
  const sendData: SendData = {
    sessionId,
    apiKey: localStorage.getItem('apiKey') as string,
    text: text
  };
  const response = await generateTableSummary(sendData);
  return response.data;
});


const handleTextState = (action: any) => {
  return (builder: any) => {
    builder.
      addCase(action.pending, (state: any) => {
        state.loading = true;
      })
      .addCase(action.fulfilled, (state: any, action: PayloadAction<string>) => {
        if (action.type === 'textAudio/fetchQandA/fulfilled') {
          state.dataObject = action.payload;
        }
        if (action.type === 'textAudio/fetchAnswerFromTextQuestion/fulfilled') {
          state.text = action.payload;
        }
        if (action.type === 'textAudio/fetchSearch/fulfilled') {
          state.searchResult = action.payload;
        }
        if (action.type === 'textAudio/fetchPresentation/fulfilled') {
          state.presentation.slides = JSON.parse(action.payload).slides;
        }
        if (action.type === 'textAudio/fetchTableSummary/fulfilled') {
          state.tableSummary.summary = JSON.parse(action.payload).summary;
        }
        state.loading = false;

      }).addCase(action.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
}

const textAudioSlice = createSlice({
  name: 'textAudio',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    handleTextState(fetchTextThunk)(builder);
    handleTextState(fetchAnswerFromTextQuestion)(builder);
    handleTextState(fetchQandA)(builder);
    handleTextState(fetchSearch)(builder);
    handleTextState(fetchPresentation)(builder);
    handleTextState(fetchTableSummary)(builder);
  }
});

export default textAudioSlice.reducer;