import React, { useState, useEffect, useRef } from 'react';
import { Tooltip, Modal, Typography, Box, useTheme, Paper, Stack, IconButton, Button, Dialog, DialogTitle } from '@mui/material';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HtmlBoxInteract from './HtmlBoxInteract';

interface MainQuestionHtmlShowProps {
  content: string;
  type: 'question' | 'answer';
}

const MainQuestionHtmlShow: React.FC<MainQuestionHtmlShowProps> = ({ content, type }) => {
  const theme = useTheme();
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (iframeRef.current) {
      const doc = iframeRef.current.contentDocument;
      const iframe = iframeRef.current;
      const iframeDoc = iframe.contentDocument;
      if (doc) {
        doc.open();
        doc.write(content);
        doc.close();
      }
      if (iframeDoc) {
        const styleElement = iframeDoc?.createElement('style');
        styleElement.innerHTML = `body { background-color: white; margin: '20px';   }`;
        iframeDoc?.head.appendChild(styleElement);
      }
      handleIframeLoad();
    }
  }, [content]);

  const handleIframeLoad = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.style.height = iframe.contentWindow.document.documentElement.scrollHeight + 'px';
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <iframe ref={iframeRef} style={{ width: '100%', border: 'none', background: 'white' }} onLoad={handleIframeLoad} />
    </Box>
  );
};

export default MainQuestionHtmlShow;
