import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { signIn, login, permissions } from '../api/serverApis/userApi';

interface dataInterface {
  accessToken: string;
  refreshToken: string;
  permissions: { [key: string]: { [key: string]: any } };
  menuPermissions: { [key: string]: any };
}
interface UserState {
  menuPermissions: { [key: string]: any }
  permissions: { [key: string]: { [key: string]: any } }
  email: string;
  isAuthenticated: boolean;
  status: 'idle' | 'loading' | 'failed';
  error: string | null;
  data: dataInterface | null;
}
const initialState: UserState = {
  menuPermissions: {},
  permissions: {},
  email: '',
  isAuthenticated: false,
  status: 'idle',
  error: null,
  data: null,
};

export const SignInUser = createAsyncThunk('user/SignInUser',
  async (user: { email: string, password: string, userName: string, firstName: string, lastName: string, phone: string, isBusinessUser: boolean }) => {
    const data = await signIn(user);
    return { data };
  }
);
export const LogInUser = createAsyncThunk('user/LogInUser',
  async (user: { email: string, password: string }) => {
    const response = await login(user.email, user.password);
    const data = {
      accessToken: response.data.accessToken,
      refreshToken: response.data.refreshToken,
      permissions: response.data.permissions,
      menuPermissions: response.data.menuPermissions
    };
    return data;
  }
);
export const getPermissions = createAsyncThunk('user/getPermissions',
  async () => {
    const response = await permissions();
    return response.data.permission;
  }
);
const handleUserState = (action: any) => {
  return (builder: any) => {
    builder
      .addCase(action.pending, (state: any) => {
        state.status = 'loading';
      })
      .addCase(action.fulfilled, (state: any, action: any) => {
        state.status = 'idle';
        state.isAuthenticated = true;
        if (action.payload) {
          state.data = action.payload;
        } else {
          state.error = 'Data is undefined';
        }
      })
      .addCase(action.rejected, (state: any, action: any) => {
        state.status = 'failed';
        state.error = "Invalid email or password" as string;
      });
  };
}
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false;
      state.email = '';
      localStorage.removeItem('token');
      localStorage.removeItem('apiKey');
      localStorage.removeItem('userId');
    }
  },
  extraReducers: (builder) => {
    handleUserState(SignInUser)(builder);
    handleUserState(LogInUser)(builder);
  }
});

export const { logout } = userSlice.actions;
export default userSlice.reducer;