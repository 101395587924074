
import React from 'react';
import { Typography, Link, Box } from '@mui/material';
import { secondsToMinutes,scoreOfDistance,createYoutubeLinkWithTime } from './UtilityFunctions';

interface MovieLinkProps {
    result:any,
    style:any
}
const MovieLink:React.FC<MovieLinkProps> = ({result,style}) => {
    return (
        <Box >
            <Typography variant="subtitle2" style={{ color: style.lightPurple, cursor: 'pointer', marginTop: '5px' }}>
            
            <Link href={createYoutubeLinkWithTime(result.movieLink,result.startTime) || '#'} 
                key={result.movieUrl}
                target="_blank" 
                rel="noopener noreferrer" 
                sx={{ 
                    textDecoration: 'none', 
                    color: 'lightPurple', 
                    display: 'block',
                    textAlign: 'center',
                    marginTop: '0',
                    marginRight: '0',
                   
                }}
                >
                    {result.title}
                    {result.startTime && result.endTime && (
                        <span> | Part: {secondsToMinutes(result.startTime)} - {secondsToMinutes(result.endTime)}</span>
                    )}
                </Link>
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'left', marginTop:'5px'}}> {/* Centered horizontal row */}
                {result.audioLength  && renderLinkOfMetaData(true,result.movieLink,'0','Movie Length', "", "", "","",result.audioLength)}
                {result.largeStartTime && result.largeEndTime &&renderLinkOfMetaData(true,result.movieLink,result.largeStartTime,'Audio Deep Dive', result.largeStartTime, result.largeEndTime, result.largeDistance,"")}
                {result.videoFrameTime && renderLinkOfMetaData(true,result.movieLink,result.videoFrameTime,'Visual Frames', '', '', result.videoDistance,'','',result.videoFrameTime)}
                {result.description && renderLinkOfMetaData(false,'','','Video Description',"", '',"", result.description)}         
         </Box>
        </Box>
    );    
}
const renderLinkOfMetaData = (isLink:boolean,url:string,urlTime:string,title: string, startTime: string, endTime: string, score?: string, text?: string, audioLength?: string,videoFrameTime?:string ) => {
  if(isLink){
    return (
      <Link href={createYoutubeLinkWithTime(url?url:'',urlTime?urlTime:'')} target="_blank" rel="noopener noreferrer" sx={{textDecoration:'none',':hover': {
                          textDecoration: 'underline', // Hover effect
                      }}}  >
          {renderMetadata(title, startTime, endTime, score, text, audioLength,videoFrameTime)}
        </Link>
    );
  }
  if(!isLink){
    return (
      <span>
          {renderMetadata(title, startTime, endTime, score, text, audioLength,videoFrameTime)}
        </span>
    );
  }
}
const renderMetadata = (title: string, startTime: string, endTime: string, score?: string, text?: string, audioLength?: string,videoFrameTime?:string ) => {
  return (
    <span style={metadataStyle}>
          <span style={titleStyle}>{title}:</span> {/* Apply titleStyle to the title */}
          <span style={answerStyle}>{audioLength && ` ${secondsToMinutes(audioLength)}`}</span>
          <span style={answerStyle}>{startTime && endTime && ` ${secondsToMinutes(startTime)} - ${secondsToMinutes(endTime)}`}</span>
          <span style={answerStyle}>{videoFrameTime && ` ${secondsToMinutes(videoFrameTime)} `}</span>
          <span style={titleStyle}> {score && `Score:`} </span><span style={answerStyle}>{score &&` ${scoreOfDistance(score)}`}</span>
          <span style={answerStyle}>{text && ` ${text}`}</span>
      {' | '}
    </span>
  );
};


  const metadataStyle = {

    fontSize: '0.8rem', // Smaller font size
    color: 'white',
    marginRight: '2px', // Space between sections
  };
  const titleStyle = {
    fontSize: '0.8rem', // Larger font size
   // fontWeight: 'bold', // Bold font weight
    color: 'white',
    marginRight: '1px', // Space between sections
  };
  interface CustomStyle {
    color: string;
    fontWeight: string;
    wordBreak?: 'break-word' | 'keep-all' | 'normal' | 'break-all'; // Add other values as needed
}
const answerStyle: CustomStyle = {
    color: 'white',
    fontWeight: 'bold',
    wordBreak: 'break-word',
};
 

export default MovieLink;