

import axiosInstance from "../interceptor";
import { CHATGPT_API_URL, GATEWAY_API_URL } from "../api";

const api = '/api/generateQuestionsService/api/v1/generateQuestions';

export const getQuizByArticleId = async (articleId: string) => {
  return axiosInstance.get(`${GATEWAY_API_URL}${api}/getQuizByArticleId/${articleId}`);
}
export const getQuizByArticleIdAndTopicNumber = async (articleId: string, topicNumber: string) => {
  return axiosInstance.get(`${GATEWAY_API_URL}${api}/getQuizByArticleIdAndTopicNumber/${articleId}/${topicNumber}`);
}

