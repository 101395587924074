import React, { useRef, useEffect, useState, forwardRef } from 'react';

import { Box, Typography, Divider, IconButton, Card } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { Rnd } from 'react-rnd';
import HtmlContentComponent from './HtmlContentComponent';

interface VideoBoxInteractProps {
  question: string;
  answer: string;
  onClose: () => void;
}

const VideoBoxInteract = forwardRef<HTMLDivElement, VideoBoxInteractProps>(({ question, answer, onClose }, ref) => {
  const [iframeUrl, setIframeUrl] = useState('');
  const boxRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(600);
  const [height, setHeight] = useState(400);

  const renderContent = () => {

    const content = <HtmlContentComponent html={answer} />;

    return content;
  };

  const boxStyle = {
    cursor: 'move',
    width: '50%',
    height: '80%',
    backgroundColor: 'background.paper',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    right: '10rem',
    top: '3rem',
    borderRadius: '1rem',
    zIndex: 1000
  };

  const iframeBoxStyle = {
    width: '100%',
    height: '100%',
    margin: '1rem',

    borderRadius: '1rem',
  };

  const textStyle = {
    flex: 1,
    overflowY: 'auto',
    padding: '1rem'
  };
  const handleResize = (e: any) => {
    // Simple resize handling; you might want to replace this with a more robust solution
    if (!boxRef.current) return;
    const nextWidth = e.clientX - boxRef.current.getBoundingClientRect().left;
    const nextHeight = e.clientY - boxRef.current.getBoundingClientRect().top;
    if (nextWidth > 300 && nextHeight > 200) { // Minimum size constraints
      setWidth(nextWidth);
      setHeight(nextHeight);
    }
  };

  return (
    <Rnd
      default={{
        x: 400,
        y: 0,
        width: 800,
        height: 565,
      }}
      minWidth={850}
      minHeight={600}
      bounds="window"
      dragHandleClassName="drag-handle"

    >
      <Card sx={{ width: '100%', height: '100%', overflow: 'hidden', position: 'relative', borderRadius: '1rem', backgroundColor: 'lightgray', }}>
        <IconButton onClick={onClose} sx={{ position: 'absolute', top: 0, right: 0, }}>
          <CloseIcon />
        </IconButton>
        <Typography className="drag-handle" sx={{ cursor: 'move', padding: 1, backgroundColor: 'lightgray', fontSize: '0.8rem', width: '98%' }}>
          {question}
        </Typography>

        <Box sx={{ width: '100%', height: '100%', overflow: 'hidden' }}>
          {renderContent()}
        </Box>
      </Card>
    </Rnd>
  );
});

export default VideoBoxInteract;
