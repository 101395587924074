

import React, { useState, useEffect } from 'react';
import VideoSemanticForManual from './videoSemanticForManual';
import VideoAnswer from '../VideoAnswer';
import VideoIndexForManual from './VideoIndexForManual';
import DeleteIcon from '@mui/icons-material/Delete';
import { fetchSearchDeleteMovie } from '../../../features/combinationSearchSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../../store';
import { Box, Accordion, AccordionSummary, AccordionDetails, Typography, Tooltip, IconButton, Dialog, DialogContent, DialogActions, DialogContentText, DialogTitle, Button, Collapse, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Index, Semantic } from '../configuration/Interface';
import DynamicTable from '../../generalComponents/DynamicTable';
import VideoText from '../VideoText';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

interface MainSearchProps {
  data: any;
  onSearch: (searchTerm: string) => void;
  theme: any;
  onVideoSelect?: (videoUrl: string, searchData: any, videoData: any, title: string, level: number) => void;
  level: number;
  handlePdfClick: any;
}
const MainSearchForManual: React.FC<MainSearchProps> = ({ data, onSearch, theme, onVideoSelect, level, handlePdfClick }) => {
  const [openIndexes, setOpenIndexes] = useState<string[]>([]);
  const [openSemantics, setOpenSemantics] = useState<string[]>([]);
  const [expanded, setExpanded] = useState<string | false>(data && data.answerData && data.answerData.length > 0 ? `panel0` : false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(null);
  const lightColors = ['#F0F0F0', '#E0E0E0']; // Softer shades for light theme
  const darkColors = ['#333333', '#404040']; // Deeper shades for dark theme
  const colors = theme.palette.mode === 'dark' ? darkColors : lightColors;
  const dispatch = useDispatch<AppDispatch>();
  const [openQuestion, setOpenQuestion] = useState(false);

  const handleToggleIndex = (articleId: string) => {
    setOpenIndexes(prev => prev.includes(articleId) ? prev.filter(id => id !== articleId) : [...prev, articleId]);
  };
  const handleToggleSemantic = (articleId: string) => {
    setOpenSemantics(prev => prev.includes(articleId) ? prev.filter(id => id !== articleId) : [...prev, articleId]);
  };
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleMovieDelete = () => {
    dispatch(fetchSearchDeleteMovie({ courseId: data.courseId, searchId: data.searchId, answerDataId: deleteConfirmOpen }));
    setDeleteConfirmOpen(null);
  };
  const sortedAnswerData = data && data.answerData ? [...data.answerData].sort((a: any, b: any) => new Date(b.answer.date).getTime() - new Date(a.answer.date).getTime()) : [];
  useEffect(() => {
    if (sortedAnswerData.length > 0) {
      setExpanded(`panel0`); // Always open the first panel after sorting
    }
  }, [data.answerData]);

  return (
    <Box sx={{ margin: 'auto', mt: 4, width: '100%', padding: '20px', marginBlock: '3rem', marginLeft: '-1.5rem' }}>
      <Box sx={{ marginTop: '1rem' }}>
        {data && data.answerData && sortedAnswerData.map((item: any, index: any) => (
          <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)} key={index} sx={{ background: colors[index % colors.length], color: theme.palette.text.primary, marginTop: '0.1rem', borderRadius: '5px' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}bh-content`} id={`panel${index}bh-header`}>
              <Typography sx={{ width: '100%', flexShrink: 0 }}>
                Q: {item.answer.question}
                <Typography variant="caption" sx={{ display: 'block', textAlign: 'right', color: 'text.secondary', mt: 2 }}>
                  {new Date(item.answer.date).toLocaleDateString()} {new Date(item.answer.date).toLocaleTimeString()}
                </Typography>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <IconButton
                sx={{
                  position: 'relative',

                  right: theme.spacing(1),
                  color: theme.palette.info.main,
                  height: '0px',
                }}
                onClick={() => setDeleteConfirmOpen(item.answerDataId)}
              >
                <DeleteIcon sx={{ fontSize: '20px', color: theme.palette.mode === 'dark' ? '#e0ebeb' : 'gray' }} />
                <Typography sx={{ fontSize: '0.8rem', color: theme.palette.mode === 'dark' ? '#e0ebeb' : 'gray' }}>Remove all section</Typography>
              </IconButton>
              <Dialog
                open={deleteConfirmOpen ? true : false}
                onClose={() => setDeleteConfirmOpen(null)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                key={index}
              >
                <DialogTitle id="alert-dialog-title">
                  {"Confirm Deletion"}
                </DialogTitle>
                <DialogContent key={index}>
                  <DialogContentText id="alert-dialog-description" key={index}>
                    Are you sure you want to Remove this section?
                  </DialogContentText>
                </DialogContent>
                <DialogActions key={index}>
                  <Button onClick={() => setDeleteConfirmOpen(null)}>Cancel</Button>
                  <Button key={index} onClick={() => { handleMovieDelete() }} autoFocus>
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>

              <IconButton onClick={() => setOpenQuestion(!openQuestion)} sx={{ position: 'relative', left: '20rem' }} >
                <QuestionMarkIcon fontSize="small" sx={{ fontSize: '0.8rem' }} />
              </IconButton>

              <Collapse in={openQuestion}>
                <Divider sx={{ my: 2 }} />
                <VideoAnswer item={item.answer} data={data} />
              </Collapse>
              {item.movies && item.movies.map((movie: any, mIndex: number) => (
                <React.Fragment key={mIndex}>
                  {movie.semantic && movie.semantic.map((semantic: Semantic, sIndex: number) => (
                    <>
                      {/* {semantic.pdfTableText && <DynamicTable data={semantic.pdfTableText} title={"PDFs Table"} />} */}
                      {/* {semantic.pdfText && <VideoText data={semantic} title={"Original text from the PDF"} />} */}
                      {semantic.pdfIconText && <VideoText data={semantic} />}
                      <VideoSemanticForManual
                        key={sIndex}
                        data={semantic}
                        isOpen={false} // Since we are using accordion, isOpen is controlled differently
                        onVideoSelect={onVideoSelect}
                        level={level}
                        searchData={data}
                        handlePdfClick={handlePdfClick}
                        item={item}
                      />
                    </>
                  ))}
                  {movie.index && movie.index.map((indexItem: Index, iIndex: number) => (
                    <VideoIndexForManual
                      key={iIndex}
                      question={item.answer.question}
                      data={indexItem}
                      isOpen={false} // Since we are using accordion, isOpen is controlled differently
                      onVideoSelect={onVideoSelect}
                      level={level}
                      searchData={data}
                      handlePdfClick={handlePdfClick}
                      item={item}
                    />
                  ))}
                </React.Fragment>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  )

}

export default MainSearchForManual;
