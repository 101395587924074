import { TextField, Box, InputAdornment, Typography, Switch } from '@mui/material';
import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import MainSearchBox from './MainSearchBox';

interface MainSearchProps {
  onSearch: (searchQuery: string, isImageSearch: boolean, audioName: string, mainCategory: string, addon: string) => void;
  style: any;
  setIsLoading: any,
  setErrorMessage: any,
  audioName: string,
  mainCategory: string
  boxName: string
  addon: string
  toUseSwitch: boolean
}
const MainSearch: React.FC<MainSearchProps> = ({ onSearch, style, setIsLoading, setErrorMessage, audioName, mainCategory, boxName, addon, toUseSwitch }) => {
  const [isImageSearch, setIsImageSearch] = useState(false);

  const handleSearchClick = (searchQuery: string) => {
    onSearch(searchQuery, isImageSearch, audioName, mainCategory, addon);
    setIsLoading(true);
    setErrorMessage('');
  };


  const handleImageSearchToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsImageSearch(event.target.checked);
  };



  return (
    <Box sx={{ ...style.box, display: 'flex', marginLeft: '0.4rem', marginTop: '1rem' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
        {toUseSwitch && <Typography variant="body1" sx={{ marginRight: 1 }}>Image:</Typography>}
        {toUseSwitch && <Switch checked={isImageSearch} onChange={handleImageSearchToggle} />}
      </Box>
      <MainSearchBox onHandleSearchClick={handleSearchClick} boxName={boxName} />
    </Box>
  );
}

export default MainSearch;