
import React, { createContext, useContext, useState, ReactNode } from 'react';

interface VideoProgressContextProps {
  videoProgress: { [key: string]: { [key: string]: number } };
  setVideoProgress: (movieId: string, subVideoId: string, progress: number) => void;
  currentlyPlayingSubVideo: { movieId: string, subVideoId: string } | null;
  setCurrentlyPlayingSubVideo: (video: { movieId: string, subVideoId: string } | null) => void;
}

const VideoProgressContext = createContext<VideoProgressContextProps | undefined>(undefined);

export const VideoProgressProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [videoProgress, setVideoProgressState] = useState<{ [key: string]: { [key: string]: number } }>({});
  const [currentlyPlayingSubVideo, setCurrentlyPlayingSubVideoState] = useState<{ movieId: string, subVideoId: string } | null>(null);

  const setVideoProgress = (movieId: string, subVideoId: string, progress: number) => {
    setVideoProgressState(prevState => ({
      ...prevState,
      [movieId]: {
        ...(prevState[movieId] || {}),
        [subVideoId]: progress,
      },
    }));
  };
  const setCurrentlyPlayingSubVideo = (video: { movieId: string, subVideoId: string } | null) => {
    setCurrentlyPlayingSubVideoState(video);
  };
  return (
    <VideoProgressContext.Provider value={{ videoProgress, setVideoProgress, currentlyPlayingSubVideo, setCurrentlyPlayingSubVideo }}>
      {children}
    </VideoProgressContext.Provider>
  );
};

export const useVideoProgress = (): VideoProgressContextProps => {
  const context = useContext(VideoProgressContext);
  if (context === undefined) {
    throw new Error('useVideoProgress must be used within a VideoProgressProvider');
  }
  return context;
};
