


export const hasAccess = (pageName: string, componentName: string) => {
  const permissions = localStorage.getItem('permissions') ? JSON.parse(localStorage.getItem('permissions') as string) : null;
  const canAccess = permissions[pageName]?.[componentName]?.canAccess ?? false;
  return canAccess;
}

export const menuPermissions = () => {
  const permissions = localStorage.getItem('menuPermissions') ? JSON.parse(localStorage.getItem('menuPermissions') as string) : null;
  return permissions;
}