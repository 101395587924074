import { createSlice, createAsyncThunk, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { pictureGenerate, getPictureByUserId, getPictureByUserIdAndBookId } from '../api/serverApis/pictureApi';

interface imageState {
  prompt: string;
  loading: boolean;
  error: string | null | undefined;
}

export interface FetchResponsePayload {
  userId: string;
  bookId: string;
  pictureId: string;
  image: string;
  apiKey: string;
}

const initialState: imageState = {
  prompt: '',
  loading: false,
  error: null,
};

export const fetchPictureThunk = createAsyncThunk('book/fetchPicture', async ({ userId, prompt, apiKey, bookId, page, pictureId }: { userId: string, prompt: string, apiKey: string, bookId: string, page: number, pictureId: string }) => {
  const response = await pictureGenerate(userId, apiKey, bookId, page, pictureId, prompt);
  return response.data;
});
export const fetchPicturesByUserIdThunk = createAsyncThunk('book/fetchBookById', async ({ id }: { id: string }) => {
  const response = await getPictureByUserId({ id });
  return response.data;
});

export const fetchPicturesByUserIdAndBookIdThunk = createAsyncThunk('book/fetchPicturesByUserIdAndBookId', async ({ userId, bookId }: { userId: string, bookId: string }) => {
  const response = await getPictureByUserIdAndBookId({ userId, bookId });
  return response.data;
});

const handleChatState = (action: any) => {
  return (builder: any) => {
    builder
      .addCase(action.pending, (state: any) => {
        state.loading = true;
      })
      .addCase(action.fulfilled, (state: any, action: PayloadAction<string>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(action.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
}

const chatGptSlice = createSlice({
  name: 'chatGpt',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    handleChatState(fetchPictureThunk)(builder);
    handleChatState(fetchPicturesByUserIdThunk)(builder);
    handleChatState(fetchPicturesByUserIdAndBookIdThunk)(builder);
  },
});

export default chatGptSlice.reducer;