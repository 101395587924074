import React, { useState } from 'react';
import { makeStyles, MenuItem, FormControl, Select } from '@material-ui/core';

interface DropDownListProps {
  items: any[];
  selectedOption: string;
  setSelectedOption: (selectedOption: string) => void;
  handleChangesInAddons:(selectedOption:string)=>any;
}
const DropDownList: React.FC<DropDownListProps> = ({items,selectedOption,setSelectedOption,handleChangesInAddons}) => {
  const classes = useStyles();

  const handleDropdownChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedOption(event.target.value as string);
    handleChangesInAddons(event.target.value as string);  
  };

  return (
    <div>
      <h2 className={classes.header}>
          <span className={classes.thickLetter}>A</span>ddons
        </h2>
      <FormControl className={classes.formControl}>
          <Select
            id="demo-simple-select"       
          displayEmpty
          value={selectedOption}
          onChange={handleDropdownChange}
          className={classes.select}
          >
             <MenuItem className={classes.menuItem} value=""><em>None</em></MenuItem>
            {items.map((item) => (
              <MenuItem className={classes.menuItem} value={item}>{item}</MenuItem>
            ))}          
          </Select>
      </FormControl>
     </div>
);
}

export default DropDownList;

const useStyles = makeStyles((theme) => ({
  formControl: {    
    margin: theme.spacing(1),
    minWidth: 120,
    backgroundColor: 'black',
    borderRadius: theme.shape.borderRadius,
    color: 'white',
    border: '1px solid white',
    width: '180px',
    marginLeft: '15px',   
  },
  select: {
      color: 'white',
      paddingLeft: '10px',
      '&:before': {
        borderColor: 'white',
      },
      '&:after': {
        borderColor: 'white',
      },
      '& .MuiSvgIcon-root': {
        color: 'white',
      },
      '& .MuiSelect-select:focus': {
        background: 'transparent', // To avoid background change on focus
      },
  },
  menuItem: {
    color: 'white', // Text color
    backgroundColor: 'black', // Background color
    '&:hover': {
      backgroundColor: '#333', // Slightly different background color on hover
    }
  },
  header: {
    color: 'white',
    marginLeft: '15px', 
    position: 'relative',
    top:'30px',
    left: '40px', 
    fontSize: '20px', 
  },
  thickLetter: {
    fontWeight: 'bold',
    fontSize: '30px',
  }
}));