import { configureStore, current } from '@reduxjs/toolkit';
import userReducer from './features/userSlice';
import chatGptReducer from './features/chatGptSlice';
import mermaidReducer from './features/mermaidSlice';
import images from './features/imageSlice';
import bookSlice from './features/bookSlice';
import currentBookSlice from './features/currentBookSlice';
import audioSlice from './features/audioSlice';
import transcriptsSlice from './features/transcriptsSlice';
import searchMediaSlice from './features/searchMediaSlice';
import navbarSlice from './features/navbarSlice';
import managerSlice from './features/tableSlice';
import combinationSearchSlice from './features/combinationSearchSlice';
import coursesSlice from './features/coursesSlice';
import mediaInteractSlice from './features/mediaInteractSlice';
import manualSlice from './features/manualSlice';
import solverSearchSlice from './features/solverSearchSlice';
import playlistSlice from './features/playlistSlice';
import quizSlice from './features/quizSlice';
import monitoringSlice from './features/monitoringSlice';
import PlayerTocSlice from './features/PlayerTocSlice';
import blobFilesSlice from './features/blobFilesSlice';
import videoSlice from './features/videoSlice';
export const store = configureStore({
  reducer: {
    user: userReducer,
    chatGpt: chatGptReducer,
    mermaid: mermaidReducer,
    book: bookSlice,
    images: images,
    currentBook: currentBookSlice,
    audio: audioSlice,
    transcripts: transcriptsSlice,
    searchMedia: searchMediaSlice,
    mediaInteract: mediaInteractSlice,
    navbar: navbarSlice,
    manager: managerSlice,
    combinationSearch: combinationSearchSlice,
    courses: coursesSlice,
    manual: manualSlice,
    solverSearch: solverSearchSlice,
    playlist: playlistSlice,
    quiz: quizSlice,
    monitoring: monitoringSlice,
    playerToc: PlayerTocSlice,
    blobs: blobFilesSlice,
    video: videoSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;