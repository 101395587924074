import React, { useState, useRef, useEffect } from 'react';
import { Semantic, Index } from '../configuration/Interface';
import { Card, CardMedia, CardContent, Typography, Box, useTheme, Paper, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, IconButton, Modal, Tooltip, Divider, Collapse } from '@mui/material';

import VideoBoxInteract from '../VideoBoxInteract';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import UndoIcon from '@mui/icons-material/Undo';
import { handleTimes } from '../../searchComponents/UtilityFunctions';
import { getSearchByCourseId, fetchMovieDeleteMovie } from '../../../features/combinationSearchSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../../store';
import DeleteIcon from '@mui/icons-material/Delete';
import { getImagesFiles } from '../../../api/serverApis/filesApi';

interface IndexProps {
  data: Semantic | Index;
  isOpen: boolean;
  onVideoSelect?: (videoUrl: string, searchData: any, videoData: any, title: string, level: number, toPause: boolean) => void;
  level: number;
  question: string;
  searchData: any;
  handlePdfClick: any;
  item: any
}

const VideoIndexForManual: React.FC<IndexProps> = ({ data, isOpen, onVideoSelect, level, question, handlePdfClick, searchData, item }) => {
  const theme = useTheme();
  const [openVideoInteract, setOpenVideoInteract] = useState(false);
  const [hover, setHover] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [openText, setOpenText] = useState(false);
  const [openQuestion, setOpenQuestion] = useState(false);
  const [openIcon, setOpenIcon] = useState(false);
  const [openTable, setOpenTable] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const videoCardRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const fetchImage = async () => {
      if (!data.pdfImageLocation) return;
      try {
        const response = await getImagesFiles(data.pdfImageLocation);
        const imageBlob = new Blob([response.data], { type: 'image/jpeg' }); // Adjust the MIME type as necessary
        const imageUrl = URL.createObjectURL(imageBlob);
        setImageUrl(imageUrl);
      } catch (error) {
        console.error('Error fetching image:', error);
      }
    };

    fetchImage();
  }, [data.pdfImageLocation]);

  const handleSelection = () => {
    const searchLevels = localStorage.getItem('searchLevels');
    if (searchLevels) {
      const searchId = data.searchId;
      const searchLevelsArray = JSON.parse(searchLevels);
      searchLevelsArray[Number(level) + 1] = searchId;
      localStorage.setItem('searchLevels', JSON.stringify(searchLevelsArray));
    }
    if (onVideoSelect) {
      onVideoSelect(data.movieLink, "", data, data.title, level, true);

      dispatch(getSearchByCourseId({ courseId: data.courseId, searchId: data.searchId }));
    }
  };
  const handleImageClick = () => {
    handlePdfClick(data.movieLink, searchData, data, data.title, level, false);
  };

  const handleCloseVideoInteract = () => {
    setOpenVideoInteract(false);
  };
  const handleTimesLocally = () => {
    if (!data.pageNumber || data.pageNumber === '-1') {
      const { startTime, endTime, frameTime } = data;
      const sTime = startTime ? startTime.toString() : '';
      const eTime = endTime ? endTime.toString() : '';
      const fTime = frameTime ? frameTime.toString() : '';
      return handleTimes(sTime, eTime, fTime);
    }
    if (data.pageNumber || data.pageNumber !== '-1') {
      return `Page: ${data.pageNumber}`;
    }
  }

  const handleMovieDelete = () => {
    // Close confirmation dialog first
    setDeleteConfirmOpen(false);
    // Dispatch your delete action here
    dispatch(fetchMovieDeleteMovie({ courseId: data.courseId, searchId: data.searchIdMetaData, searchIdMovie: data.searchId, articleId: data.articleId }));
  };
  const getIconForFileType = () => {
    const isPDF = data.movieLink.indexOf('.pdf') != -1;
    return isPDF ? <PictureAsPdfIcon fontSize="large" /> : <PlayCircleOutlineIcon fontSize="large" />;
  };
  return (
    <>

      <Card
        sx={{
          display: isOpen ? 'none' : 'flex',
          flexDirection: 'row',
          mb: 2,
          height: 'auto',
          backgroundColor: theme.palette.background.default,
          margin: '1rem',
          borderRadius: '1rem',
          boxShadow: theme.shadows[2]
        }}
      >
        {/* Left Side: Image */}
        <CardMedia
          component="img"
          sx={{
            width: '60%',
            height: '100%',
            objectFit: 'cover',
            cursor: 'pointer',
            borderTopLeftRadius: '1rem',
            borderBottomLeftRadius: '1rem'
          }}
          image={imageUrl || '/path/to/default/image.png'}
          alt="Video thumbnail"
          onClick={handleImageClick}
        />

        {/* Right Side: Content */}
        <CardContent
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            p: 2,
            width: '40%'
          }}
        >
          {/* Upper Section: Summary */}
          <Box>
            <Typography sx={{ textAlign: 'left', fontSize: '0.6rem', marginBottom: '2%' }}>Video: {data.title}</Typography>
            <Typography variant="subtitle2">
              Exact Match: <Box sx={{ fontWeight: 'bold' }}>{question}</Box>
            </Typography>
          </Box>

          {/* Bottom Section: Additional Info and Delete Button */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
            <Typography fontSize={'0.8rem'} sx={{ marginTop: '6rem' }}>
              {handleTimesLocally()} | Score: {data.score}
            </Typography>
            <Box sx={{ marginTop: '5.4rem' }}>
              <Tooltip title="Delete">
                <IconButton onClick={handleMovieDelete}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default VideoIndexForManual;