

import axiosInstance from "../interceptor";
import { CHATGPT_API_URL, GATEWAY_API_URL } from "../api";

const api = '/api/monitoringService/api/v1/monitoring';

export const reportOnQuestion = async (articleId: string, topicNumber: string, questionId: string, newVoteType: string) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${api}/reportOnQuestion`, { articleId, topicNumber, questionId, newVoteType });
}
export const getAllQuizMonitoringDataAndQuizMonitoringUserData = async () => {
  return axiosInstance.get(`${GATEWAY_API_URL}${api}/getAllQuizMonitoringDataAndQuizMonitoringUserData`);
}


