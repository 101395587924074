import React from 'react';
import { Box, Link } from '@mui/material';
import { secondsToMinutes, scoreOfDistance, createYoutubeLinkWithTime } from '../searchComponents/UtilityFunctions'

interface MetaDataMediaProps {
  item: any;
}

const MetaDataMedia: React.FC<MetaDataMediaProps> = ({ item }) => {
  const renderLinkOfMetaData = (
    isLink: boolean,
    url: string,
    urlTime: string | number,
    title: string,
    startTime?: string,
    endTime?: string,
    score?: string,
    text?: string,
    audioLength?: string,
    videoFrameTime?: string,
    isPDF?: boolean
  ) => {
    if (urlTime === "0") return null;
    let linkHref = url;
    if (isPDF) {
      linkHref += `#page=${urlTime}`;  // Appending the page number for PDF links
    } else {
      linkHref = createYoutubeLinkWithTime(url, urlTime.toString());
    }

    return (
      <Link href={linkHref} target="_blank" rel="noopener noreferrer" sx={{
        textDecoration: 'none', ':hover': {
          textDecoration: 'underline', // Hover effect
        }
      }}>
        {renderMetadata(title, startTime, endTime, score, text, audioLength, videoFrameTime, urlTime, isPDF)}
      </Link>
    );
  };

  const renderMetadata = (
    title: string,
    startTime?: string,
    endTime?: string,
    score?: string,
    text?: string,
    audioLength?: string,
    videoFrameTime?: string,
    urlTime?: string | number,
    isPDF?: boolean
  ) => {
    return (
      <span style={metadataStyle}>
        <span style={titleStyle}>{title}:</span>
        <span style={answerStyle}>{audioLength && ` ${secondsToMinutes(audioLength)}`}</span>
        <span style={answerStyle}>{startTime && endTime && ` ${secondsToMinutes(startTime)} - ${secondsToMinutes(endTime)}`}</span>
        <span style={answerStyle}>{videoFrameTime && ` ${secondsToMinutes(videoFrameTime)}`}</span>
        <span style={titleStyle}> {score && `Score:`} </span>
        <span style={answerStyle}>{score && ` ${scoreOfDistance(score)}`}</span>
        <span style={answerStyle}>{text && ` ${text}`}</span>
        <span style={answerStyle}>{urlTime && isPDF && ` ${urlTime}`}</span>
        {' | '}
      </span>
    );
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', border: '1px solid white', borderRadius: '30px', marginTop: '20px' }}>
      {item.audioLength && renderLinkOfMetaData(true, item.movieUrl, '0', 'Movie Length', undefined, undefined, undefined, undefined, item.audioLength, undefined, true)}
      {item.startTime && item.endTime && renderLinkOfMetaData(true, item.movieUrl, item.startTime, 'Audio Quick Focus', item.startTime, item.endTime, item.focusDistance)}
      {item.largeStartTime && item.largeEndTime && renderLinkOfMetaData(true, item.movieUrl, item.largeStartTime, 'Audio Deep Dive', item.largeStartTime, item.largeEndTime, item.largeDistance)}
      {item.videoFrameTime && renderLinkOfMetaData(true, item.movieUrl, item.videoFrameTime, 'Visual Frames', undefined, undefined, item.videoDistance, undefined, undefined, item.videoFrameTime)}
      {item.description && renderLinkOfMetaData(false, item.movieUrl, '', 'Video Description', undefined, undefined, undefined, item.description)}
      {item.pdfImagesPageNumber && renderLinkOfMetaData(true, item.movieUrl, item.pdfImagesPageNumber, 'Image Page', undefined, undefined, undefined, undefined, undefined, undefined, true)}
      {item.pdfTablesPageNumber && renderLinkOfMetaData(true, item.movieUrl, item.pdfTablesPageNumber, 'Table Page', undefined, undefined, undefined, undefined, undefined, undefined, true)}
      {item.pdfTextPageNumber && renderLinkOfMetaData(true, item.movieUrl, item.pdfTextPageNumber, 'Text Page', undefined, undefined, undefined, undefined, undefined, undefined, true)}
    </Box>
  );
};

export default MetaDataMedia;

const metadataStyle = {
  fontSize: '1rem',
  color: 'white',
  marginRight: '2px',
};

const titleStyle = {
  fontSize: '0.9rem',
  color: 'white',
  marginRight: '2px',
};

const answerStyle = {
  color: 'white',
  fontWeight: 'bold',
};
