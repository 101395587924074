

import {createSlice, PayloadAction} from '@reduxjs/toolkit';


interface NavbarState {
    activeComponent: string;
    currentComponent: string;
    clickedIconBarState: boolean[];
    selectedSessionId:string|undefined;
}

const initialState: NavbarState = {
    currentComponent: 'search',
    activeComponent: 'search',
    clickedIconBarState: [true, false, false, false, false, false, false, false, false],
    selectedSessionId:''
};

export const navbarSlice = createSlice({
    name: 'navbar',
    initialState,
    reducers: {
        setActiveComponent: (state, action: PayloadAction<string>) => {
            state.activeComponent = action.payload;
        },
        setCurrentComponent: (state, action: PayloadAction<string>) => {
            state.currentComponent = action.payload;
        },
        setClickedIconBarState: (state, action: PayloadAction<boolean[]>) => {
            state.clickedIconBarState = action.payload;
        },
        setSelectedSessionId: (state, action: PayloadAction<string|undefined>) => {
            state.selectedSessionId = action.payload;
        },
        handleButtonClick: (state, action: PayloadAction<{ index: number, componentName: string,selectedSessionId:string|undefined }>) => {
            state.selectedSessionId = action.payload.selectedSessionId;
            state.activeComponent = action.payload.componentName;
            state.clickedIconBarState = state.clickedIconBarState.map((_, idx) => idx === action.payload.index);
          }
    },
});

export const {setActiveComponent, setClickedIconBarState, handleButtonClick,setSelectedSessionId,setCurrentComponent} = navbarSlice.actions;

export default navbarSlice.reducer;