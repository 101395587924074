interface Column {
  id: string;
  label: string;
  minWidth: number;
  align: "right" | "center" | "left";
  onClick: (value: any) => void;
}

interface RowTransformationFunction {
  (row: any): any;
}

interface TableConfiguration {
  displayName: string;
  getColumns: (data: any[]) => Column[];
  transformRow: RowTransformationFunction;
}

export const tableMetadata: Record<string, TableConfiguration> = {
  users: {
    displayName: 'Users',
    getColumns: (data) => data[0] ? Object.keys(data[0]).map(key => ({
      id: key,
      label: key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1'),
      minWidth: 90,
      align: "center",
      onClick: (value: any) => console.log(`${key}: ${value}`)
    })) : [],
    transformRow: (user) => ({
      ...user,
      isBusinessUser: user.isBusinessUser ? 'Yes' : 'No',
      isActivate: user.isActivate ? 'Yes' : 'No',
      creationDate: new Date(user.creationDate).toLocaleDateString()
    })
  },
  customers: {
    displayName: 'Customers',
    getColumns: (data) => data[0] ? Object.keys(data[0]).map(key => ({
      id: key,
      label: key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1'),
      minWidth: 170,
      align: "center",
      onClick: (value: any) => console.log(`${key}: ${value}`)
    })) : [],
    transformRow: (customer) => ({
      ...customer,
      updateDate: new Date(customer.updateDate).toLocaleDateString()
    })
  },
  roles: {
    displayName: 'Roles',
    getColumns: (data) => data[0] ? Object.keys(data[0]).map(key => ({
      id: key,
      label: key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1'),
      minWidth: 170,
      align: "center",
      onClick: (value: any) => console.log(`${key}: ${value}`)
    })) : [],
    transformRow: (role) => ({
      ...role,
      // Assuming no special transformations are needed beyond what getColumns provides
    })
  },

  roleRules: {
    displayName: 'Role Rules',
    getColumns: (data) => data[0] ? Object.keys(data[0]).map(key => ({
      id: key,
      label: key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1'),
      minWidth: 170,
      align: "center",
      onClick: (value: any) => console.log(`${key}: ${value}`)
    })) : [],
    transformRow: (roleRule) => ({
      ...roleRule,
      // Assuming no special transformations are needed
    })
  },

  rules: {
    displayName: 'Rules',
    getColumns: (data) => data[0] ? Object.keys(data[0]).map(key => ({
      id: key,
      label: key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1'),
      minWidth: 170,
      align: "center",
      onClick: (value: any) => console.log(`${key}: ${value}`)
    })) : [],
    transformRow: (rule) => ({
      ...rule,
      isRead: rule.isRead ? 'Yes' : 'No',
      isWrite: rule.isWrite ? 'Yes' : 'No',
      canAccess: rule.canAccess ? 'Yes' : 'No',
      // Additional transformations can be added here
    })
  },

  ruleActions: {
    displayName: 'Rule Actions',
    getColumns: (data) => data[0] ? Object.keys(data[0]).map(key => ({
      id: key,
      label: key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1'),
      minWidth: 170,
      align: "center",
      onClick: (value: any) => console.log(`${key}: ${value}`)
    })) : [],
    transformRow: (ruleAction) => ({
      ...ruleAction,
      // Assuming no special transformations are needed
    })
  }
};

export type { Column, RowTransformationFunction, TableConfiguration };