import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface AccessPolicyProps {
  pageName: string;
  componentName: string;
  ComponentRead: React.ReactElement;
  ComponentWrite: React.ReactElement;
}

const RoleWarper: React.FC<AccessPolicyProps> = ({
  pageName,
  componentName,
  ComponentRead,
  ComponentWrite,
}) => {



  const permissions = localStorage.getItem('permissions') ? JSON.parse(localStorage.getItem('permissions') as string) : null;
  if (!permissions) return null;
  if (!permissions[pageName]) return null;
  if (!permissions[pageName][componentName]) return null;
  const canRead = permissions[pageName][componentName]?.isRead ?? false;
  const canWrite = permissions[pageName][componentName]?.isWrite ?? false;
  const canAccess = permissions[pageName][componentName]?.canAccess ?? false;

  if (!canAccess) {
    return null;
  }

  // Decide which component to render based on permissions
  // Directly return the component since it already includes its props
  if (canWrite) {
    return ComponentWrite;
  }
  else if (canRead) {
    return ComponentRead;
  }
  else {
    return null; // or any fallback component
  }
};

export default RoleWarper;