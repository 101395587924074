

import React from "react";

interface SearchResultBottomProps {
    qaPair:any
}
const SearchResultBottom:React.FC<SearchResultBottomProps> = ({qaPair}) => {
    if (!qaPair.links || qaPair.links.length === 0) return null;
    return (
      <div>
        <h4>User Links:</h4>
        <ul>
          {qaPair.links.map((link:any, linkIndex:any) => (
            <li key={linkIndex}>
              <a href={link} target="_blank" rel="noopener noreferrer">
                {linkIndex + 1}. {link}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
}
export default SearchResultBottom;