// DiagramVisualization.tsx
import React from 'react';
import ReactFlow, { MiniMap, Controls, Background, Node, Edge, MarkerType } from 'react-flow-renderer';

interface User {
  userId: string;
  userName: string;
  // ... other user properties
  roleId: string;
}

interface Role {
  roleId: string;
  roleName: string;
  // ... other role properties
}

interface Rule {
  ruleId: string;
  ruleName: string;
  // ... other rule properties
  roleId: string;
}

interface RuleAction {
  ruleIdActionId: string;
  ruleActionName: string;
  // ... other rule action properties
  ruleId: string;
}

interface Customer {
  customerId: string;
  customerName: string;
  // ... other customer properties
}
interface RoleRule {
  roleId: string;
  ruleId: string;
}
interface DiagramVisualizationProps {
  users: any[];
  roles: any[];
  rules: any[];
  ruleActions: any[];
  customers: any[];
  roleRules: any[];
}
interface HierarchyNode {
  id: string;
  parentId: string | null; // Allow parentId to be string or null
  type: string;
  data: any;
}


const DiagramVisualization: React.FC<DiagramVisualizationProps> = ({ users, roles, rules, ruleActions, customers, roleRules }) => {
  const nodes: Node[] = [];
  const edges: Edge[] = [];

  let userY = 25;
  const userX = 50;
  const customerX = 250;
  const roleYOffset = 150;
  const roleX = 50;
  const ruleYStart = userY + roleYOffset + 100; // Position rules below roles
  const ruleXStart = 50;
  const ruleXOffset = 200;
  const actionYOffset = 100;

  // Users and Customers
  users?.forEach((user, index) => {
    // Users
    const userNodeId = `user-${user.userId}`;
    nodes.push({
      id: userNodeId,
      type: 'default',
      data: { label: `User: ${user.email}, businessUser: ${user.isBusinessUser}` },
      position: { x: userX, y: userY }
    });

    // Customers
    const customer = customers?.find(c => c.customerId === user.customerId);
    if (customer) {
      const customerNodeId = `customer-${customer.customerId}`;
      nodes.push({
        id: customerNodeId,
        data: { label: `Customer: ${customer.customerName}` },
        position: { x: customerX, y: userY }
      });
      edges.push({ id: `e-${userNodeId}-${customerNodeId}`, source: userNodeId, target: customerNodeId });
    }

    userY += 150; // Increment for the next user
  });

  // Roles, Rules, and RuleActions
  roles?.forEach((role, roleIndex) => {
    const roleNodeId = `role-${role.roleId}`;
    nodes.push({
      id: roleNodeId,
      data: { label: `Role: ${role.roleName}` },
      position: { x: roleX, y: ruleYStart + (roleIndex * roleYOffset) }
    });
    // Connect each role to its corresponding user
    users?.filter(user => user.roleId === role.roleId).forEach(user => {
      edges.push({
        id: `e-user-role-${user.userId}-${role.roleId}`,
        source: `user-${user.userId}`,
        target: roleNodeId,
        type: 'smoothstep', // Use the smoothstep edge for a curved connection
        markerEnd: {
          type: MarkerType.ArrowClosed,
        },
      });
    });
    // Find rules associated with the role through roleRules
    roleRules?.filter(rr => rr.roleId === role.roleId).forEach((rr, rrIndex) => {
      const rule = rules?.find(r => r.ruleId === rr.ruleId);
      if (rule) {
        const ruleNodeId = `rule-${rule.ruleId}`;
        nodes.push({
          id: ruleNodeId,
          data: { label: `Rule: ${rule.ruleName}, isRead: ${rule.isRead}, isWrite: ${rule.isWrite}, canAccess: ${rule.canAccess} , entityObject: ${rule.entityObject}, entityName: ${rule.entityName} ` },
          position: { x: ruleXStart + (rrIndex * ruleXOffset), y: ruleYStart + (roleIndex * roleYOffset) + 50 }
        });
        edges.push({ id: `e-${roleNodeId}-${ruleNodeId}`, source: roleNodeId, target: ruleNodeId });

        // RuleActions for each Rule
        ruleActions?.filter(action => action.ruleIdActionId === rule.ruleId).forEach((action, actionIndex) => {
          const actionNodeId = `action-${action.ruleIdActionId}-${action.subRuleId}`;
          nodes.push({
            id: actionNodeId,
            data: { label: `Action: ${action.ruleActionName}` },
            position: { x: ruleXStart + (rrIndex * ruleXOffset), y: ruleYStart + (roleIndex * roleYOffset) + 50 + (actionYOffset * (actionIndex + 1)) }
          });
          edges.push({ id: `e-${ruleNodeId}-${actionNodeId}`, source: ruleNodeId, target: actionNodeId });
        });
      }
    });
  });

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      style={{ height: '100vh' }}
      fitView
    >
      <MiniMap />
      <Controls />
      <Background color="#aaa" gap={16} />
    </ReactFlow>
  );
};

export default DiagramVisualization;