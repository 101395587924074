import React, { useRef, useEffect, useState } from 'react';
import { Box, Typography, IconButton, useTheme } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
interface BreadcrumbItem {
  title: string;
  level: number;
  onClick: () => void;
}

interface BreadcrumbsComponentProps {
  breadcrumbs: BreadcrumbItem[];
  onSelect: (index: number) => void;
  currentLevel: number;
  setCurrentLevel: any;
}

const BreadcrumbsComponent: React.FC<BreadcrumbsComponentProps> = ({
  breadcrumbs,
  onSelect,
  currentLevel,
  setCurrentLevel,
}) => {
  const theme = useTheme();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [scrollable, setScrollable] = useState({ left: false, right: false });

  const checkScrollable = () => {
    if (!scrollRef.current) return;
    const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
    setScrollable({
      left: scrollLeft > 0,
      right: scrollLeft < scrollWidth - clientWidth,
    });
  };

  const scrollToEnd = () => {
    if (!scrollRef.current) return;
    scrollRef.current.scrollLeft = scrollRef.current.scrollWidth;
  };

  const scroll = (direction: 'left' | 'right') => {
    if (!scrollRef.current) return;
    const scrollAmount = direction === 'left' ? -150 : 150;
    scrollRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    checkScrollable();
  };

  useEffect(() => {
    checkScrollable();
    window.addEventListener('resize', checkScrollable);
    return () => window.removeEventListener('resize', checkScrollable);
  }, [breadcrumbs]);

  useEffect(() => {
    scrollToEnd();
  }, [breadcrumbs]);

  // Adjusting breadcrumb background color based on theme mode
  const getBackgroundColor = (index: number) => {
    if (index === currentLevel) {
      return theme.palette.primary.main;
    } else {
      // Lighter for dark mode, darker for light mode
      return theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)';
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden', gap: 1 }}>
      {scrollable.left && (
        <IconButton onClick={() => scroll('left')}>
          <NavigateBeforeIcon />
        </IconButton>
      )}

      <Box
        ref={scrollRef}
        sx={{
          display: 'flex',
          gap: 1,
          overflowX: 'auto',
          '&::-webkit-scrollbar': { display: 'none' },
        }}
      >
        {breadcrumbs.map((crumb, index) => (
          <Box
            key={index}
            onClick={() => onSelect(index)}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              p: 1,

              backgroundColor: index === breadcrumbs.length - 1 ? theme.palette.background.paper : theme.palette.background.default,
              color: theme.palette.text.primary,
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
                cursor: 'pointer',
              },
              ...(index === currentLevel && {
                boxShadow: `0 0 10px ${theme.palette.primary.main}`,
              }),
            }}
          >
            <VideoLibraryIcon />
            <Typography>{index + 1}</Typography>
            <Typography sx={{ fontSize: '0.5rem' }}>{crumb.title}</Typography>
          </Box>
        ))}
      </Box>

      {scrollable.right && (
        <IconButton onClick={() => scroll('right')}>
          <NavigateNextIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default BreadcrumbsComponent;
