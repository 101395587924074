import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { RootState, AppDispatch } from '../store';
import { fetchContentOfCourse, setSelectedVideoDetails } from '../features/coursesSlice';
import { resetCombinationData, resetQuestionsArchive } from '../features/combinationSearchSlice';
import { resetSolverData, resetQuestionsSolverArchive } from '../features/solverSearchSlice';
import { resetSelectedVideo } from '../features/coursesSlice';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, ThemeProvider, styled, TextField, AppBar, Toolbar, IconButton, Paper } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import getTheme from '../components/combinationSearchComponents/configuration/Themes';
import Header from '../components/combinationSearchComponents/Header';
import { handleTimes } from '../components/searchComponents/UtilityFunctions';

const CustomAccordion = styled(Accordion)({
  marginBottom: 2,
  '&:before': { display: 'none' },
});
const handleTimesLocally = (subVideo: any) => {
  if (!subVideo.startPageNumber || subVideo.startPageNumber === '-1') {
    const { startTime, endTime, frameTime } = subVideo;
    const sTime = startTime ? startTime.toString() : '';
    const eTime = endTime ? endTime.toString() : '';
    const fTime = frameTime ? frameTime.toString() : '';
    return handleTimes(sTime, eTime, fTime);
  }
  if (subVideo.startPageNumber || subVideo.startPageNumber !== '-1') {
    return `Page: ${subVideo.startPageNumber}`;
  }
}
const SubVideoBox = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(1),
  cursor: 'pointer',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[8],
  },
}));

const CourseContent: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const contentOfCourse = useSelector((state: RootState) => state.courses.contentOfCourse);
  const [themeMode, setThemeMode] = useState<'light' | 'dark'>('light');
  const [filter, setFilter] = useState('');
  const theme = getTheme(themeMode);
  const { courseId } = useParams();

  useEffect(() => {
    if (courseId) {
      dispatch(resetQuestionsArchive());
      dispatch(resetCombinationData());
      dispatch(resetSolverData());
      dispatch(resetQuestionsSolverArchive());
      dispatch(resetSelectedVideo());
      dispatch(fetchContentOfCourse(courseId));

    }
  }, [courseId, dispatch]);

  // Adjusted filter function
  const filteredContent = useMemo(() => {
    if (!contentOfCourse) return [];
    if (contentOfCourse.length <= 1) return contentOfCourse;
    const filtered = contentOfCourse.filter(video =>
      video.subject.toLowerCase().includes(filter.toLowerCase()) ||
      video.content.some((subVideo: any) => subVideo.subject.toLowerCase().includes(filter.toLowerCase()))
    );
    return filtered.sort((a, b) => a.subject.localeCompare(b.subject));
  }, [filter, contentOfCourse]);

  const handleVideoSelect = (movieId: string, subVideoId?: string) => {
    dispatch(setSelectedVideoDetails({ movieId, id: subVideoId || "", courseId: courseId || "" }));
    navigate(`/course`);
  };
  const isHebrew = (text: string) => /[\u0590-\u05FF\uFB1D-\uFB4F\uFB50-\uFDFF]/.test(text);
  interface StyledAccordionSummaryProps {
    isHebrew: boolean;  // Your custom prop
  }
  const StyledAccordionSummary = styled(AccordionSummary)<StyledAccordionSummaryProps>(({ theme, isHebrew }) => ({
    direction: isHebrew ? 'rtl' : 'ltr',
    '& .MuiAccordionSummary-content': {
    },
    '& .MuiAccordionSummary-expandIconWrapper': {

      marginRight: isHebrew ? 'auto' : '',
      marginLeft: isHebrew ? 'auto' : ''
    }
  }));
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ pb: 7 }}>
        <Header header={"Media-Morph Courses"} themeMode={themeMode} toggleTheme={() => setThemeMode(prevMode => prevMode === 'light' ? 'dark' : 'light')} />
        <Box sx={{ width: '50%', marginLeft: '25%' }}>
          {filteredContent.map((mainVideo, index) => (
            <CustomAccordion key={index} defaultExpanded={false}>
              <StyledAccordionSummary expandIcon={<ExpandMoreIcon />} isHebrew={isHebrew(mainVideo.subject)} >
                {/* need to remove: onClick={() => handleVideoSelect(mainVideo.movieId, "")} */}
                <Typography variant="h6" sx={{ cursor: 'pointer', textAlign: isHebrew(mainVideo.subject) ? 'right' : 'left' }} onClick={() => handleVideoSelect(mainVideo.movieId, "")}>
                  {`${index + 1}. ${mainVideo.subject}`}
                </Typography>
              </StyledAccordionSummary>
              <AccordionDetails>
                <CustomAccordion defaultExpanded={true}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Description</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={{ direction: isHebrew(mainVideo.summary) ? 'rtl' : 'ltr' }}>{mainVideo.summary}</Typography>
                  </AccordionDetails>
                </CustomAccordion>
                {mainVideo.content.map((subVideo: any, subIndex: any) => (
                  <SubVideoBox key={subIndex} onClick={() => handleVideoSelect(mainVideo.movieId, subVideo.id)}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', direction: isHebrew(subVideo.subject) ? 'rtl' : 'ltr' }}>{`${index + 1}.${subIndex + 1} ${subVideo.subject}`}</Typography>
                    {/* <Typography>Topic Summary:</Typography> */}
                    <Typography variant="body2" sx={{ direction: isHebrew(subVideo.summary) ? 'rtl' : 'ltr' }}>{subVideo.summary}</Typography>
                    <Box sx={{ margin: '10px' }}>
                      {subVideo.subSubject && subVideo.subSubject.split(',').map((sentence: string, sentenceIndex: number) => (
                        <Typography
                          key={sentenceIndex}
                          variant="subtitle2"
                          sx={{
                            direction: isHebrew(sentence) ? 'rtl' : 'ltr',
                            marginLeft: isHebrew(sentence) ? '' : '20px',
                            marginRight: isHebrew(sentence) ? '20px' : '',
                          }}

                        >
                          {`${sentenceIndex + 1}. ${sentence.trim()}`}
                        </Typography>
                      ))}
                    </Box>
                    {/* <Box sx={{ ml: 2 }}>
                    {isHebrew(subVideo.topics[0]) ? <Typography sx={{ direction: 'rtl', marginRight: isHebrew(subVideo.topics[0]) ? '10px' : '', marginLeft: isHebrew(subVideo.topics[0]) ? '' : '10px' }}>נקודות מרכזיות בסרט:</Typography> : <Typography>Main Key Point:</Typography>}
                    {subVideo.topics && subVideo.topics.map((topic: string, index: number) => (
                      <Typography key={index} sx={{ fontSize: '0.9rem', direction: isHebrew(topic) ? 'rtl' : 'ltr', marginRight: isHebrew(topic) ? '50px' : '', marginLeft: isHebrew(topic) ? '' : '30px' }} variant="body2" color="text.secondary">{index + 1}. {topic}</Typography>
                    ))}
                  </Box> */}
                    <Typography variant="body2" sx={{}}>{`${handleTimesLocally(subVideo)}`}</Typography>
                  </SubVideoBox>
                ))}
              </AccordionDetails>
            </CustomAccordion>
          ))}
        </Box>
      </Box>
      <AppBar position="fixed" color="default" sx={{ top: 'auto', bottom: 0 }}>
        <Toolbar>
          <IconButton sx={{ mr: 1 }}>
            <SearchIcon />
          </IconButton>
          <TextField
            fullWidth
            placeholder="Filter courses..."
            variant="outlined"
            size="small"
            onChange={(e) => setFilter(e.target.value)}
          />
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};

export default CourseContent;
