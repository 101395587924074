import React from 'react';
import { Box } from '@mui/material';


interface RenderSnapshotImageProps {
    item:any,
    result:any,
    movieDetails:any,
    onMediaInteraction : (item:any,movieData:any) => void;
}
const RenderSnapshotImage:React.FC<RenderSnapshotImageProps> = ({item,result,movieDetails,onMediaInteraction}) => {
    return (
        <Box>
            <Box sx={{
                border: '2px solid blue',
                borderRadius: '10px',
                overflow: 'hidden',
                width: '200px',
                minHeight: '120px',
                height: '120px',
            }}>         
                <img
                    src={`${result.pageImg}`}
                    alt={`${result.title}`}
                    width="200"
                    height="120"                    
                    style={{ display: 'block', width: '100%', height: '100%' }}
                />
            </Box>
          </Box>
      );
}

export default RenderSnapshotImage;