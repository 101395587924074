

export const scoreOfDistance = (score: string) => {
    const scoreNum = parseFloat(score);
    if (scoreNum > 0.87) {
        return "Very High";
    }
    if (scoreNum > 0.83) {
        return "High";
    }
    if (scoreNum > 0.8) {
        return "Average";
    }
    return "Below average";
}
export const convertToYoutubeTime = (time: string) => {
    const parts = time.split(":");
    if (parts.length !== 2) {
        return "Invalid time format"; // Guard clause for invalid input
    }
    return parts[0] + "m" + parts[1] + "s";
}

export const secondsToMinutes = (secondsString: string): string => {
    const totalSeconds = parseFloat(secondsString);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = Math.round(totalSeconds % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
}
export const cleanTheDot = (time: string) => {
    try {
        if (!time) {
            return "";
        }
        return time.toString()?.split(".")[0];
    }
    catch (error) {
        console.error('Error in cleanTheDot: ' + error);
        return "";
    }
}
export const roundUpTheTime = (time: string) => {
    try {
        if (!time) {
            return "";
        }
        const timeSplit = time?.split(".")[0];
        return Number(timeSplit) + Number(1);
    }
    catch (error) {
        console.error('Error in roundUpTheTime: ' + error);
        return "";
    }
}
export const checkFromYoutube = (url: string) => {
    if (url) {
        return url.indexOf("youtube.com") !== -1 || url.indexOf("youtu.be") !== -1;
    }
    return false;
};

export const createYoutubeLinkWithTime = (url: string, time: string) => {
    if (checkFromYoutube(url)) {
        return url + "&t=" + convertToYoutubeTime(secondsToMinutes(time));
    }
    return url;
}


export const convertNumberToTime = (time: number) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    // Format minutes and seconds to always have two digits
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes.toString();
    const formattedSeconds = seconds < 10 ? `0${cleanTheDot(seconds.toString())}` : cleanTheDot(seconds.toString());

    let result = "";
    if (hours > 0) {
        result += `${hours}:`;
    }
    result += `${formattedMinutes}:${formattedSeconds}`;

    return result;
}
export const calculateTimeDifference = (startTime: number, endTime: number): string => {
    // Calculate the difference in seconds
    const timeDifference = endTime - startTime;

    // Convert the difference to minutes and seconds
    const minutes = Math.floor(timeDifference / 60);
    const seconds = Math.round(timeDifference % 60);

    // Format minutes and seconds to always have two digits
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes.toString();
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds.toString();

    return `${formattedMinutes}:${formattedSeconds}`;
};

export const handleTimes = (startTime: string, endTime: string, frameTime: string, duration?: boolean) => {

    const sTime = startTime ? startTime.toString() : '';
    const eTime = endTime ? endTime.toString() : '';
    const fTime = frameTime ? frameTime.toString() : '';

    if (fTime.toString() !== '-1' && fTime.toString().length > 0) {
        return 'Frame: ' + convertNumberToTime(Number(fTime));
    }
    if (duration) {
        const length = calculateTimeDifference(Number(sTime), Number(eTime));
        return `${length}`;
    }
    if (sTime && eTime) {
        const startToEnd = `${convertNumberToTime(Number(sTime))} - ${convertNumberToTime(Number(eTime))}`;
        const length = calculateTimeDifference(Number(sTime), Number(eTime));
        return `${startToEnd} |  Duration: ${length}`;
    }
    else {
        return '';
    }
}