
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import { Box, TextField, Button, Typography, IconButton, Divider } from '@mui/material';
import SearchSpinner from './searchComponentSpinner';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'; // for the play button
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { fetchQuestionAnswerInteractResult, fetchArchiveQuestionAnswerInteractResult } from '../../features/mediaInteractSlice';
import { v4 as uuidv4 } from 'uuid';
import SearchResultMiddleBottom from '../searchComponents/SearchResultMiddleBottom';
import SearchResultMiddleProps from '../searchComponents/SearchResultMiddle';
import QuestionAnswerSearchResult from '../searchComponents/QuestionAnswerSearchResult';
import MainSearchBox from '../searchComponents/MainSearchBox';
import MetaDataMedia from '../searchComponents/MetaDataMedia';
import { secondsToMinutes } from '../searchComponents/UtilityFunctions'
interface MediaInteractProps {
  movieData: any;
}
const MediaInteract: React.FC<MediaInteractProps> = ({ movieData }) => {

  const [answerQuestion, setAnswerQuestion] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [interactSessionId, setInteractSessionId] = useState('');
  const dispatch = useDispatch<AppDispatch>();
  const mediaInteract = useSelector((state: RootState) => state.mediaInteract);

  useEffect(() => {
    if (mediaInteract.interactResult && mediaInteract.interactResult.answerQuestion) {
      setInteractSessionId(mediaInteract.interactResult.interactSessionId);
      setAnswerQuestion(mediaInteract.interactResult.answerQuestion);
      setIsLoading(false);
    }
  }, [mediaInteract.interactResult, mediaInteract.error]);

  useEffect(() => {
    setIsLoading(false);
    setAnswerQuestion([]);
    dispatch(fetchArchiveQuestionAnswerInteractResult({ movieData }));
  }, []);


  const handleAskQuestion = async (searchQuery: string) => {
    let interactSession = '';
    if (interactSessionId) {
      interactSession = interactSessionId;
    }
    if (!interactSessionId) {
      const token = uuidv4();
      interactSession = token;
      setInteractSessionId(interactSession);
    }

    setIsLoading(true);
    const uploadData = {
      movieId: movieData.movieId ? movieData.movieId : movieData.articleId,
      sessionId: movieData.sessionId,
      existData: movieData.search + "\n\n." + movieData.answer,
      originalSearch: movieData.search,
      originalAnswer: movieData.answer,
      movieUrl: movieData.movieUrl ? movieData.movieUrl : movieData.url,
      // searchAnswerId: movieData.movieId? movieData.movieId: movieData.articleId,
      questionId: uuidv4(),
    }
    setIsLoading(true);
    dispatch(fetchQuestionAnswerInteractResult({ interactSessionId: interactSession, movieData: uploadData, question: searchQuery }))
  };


  const renderQuestionAndAnswerSection = () => (
    <Box sx={{
      width: '600px', overflowY: 'auto', maxHeight: '500px', marginTop: '4rem', '&::-webkit-scrollbar': {
        width: '8px',
        backgroundColor: 'transparent', // Transparent background
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(255, 255, 255, 0.2)', // Slightly visible scrollbar thumb
        borderRadius: '4px',
      }
    }}>
      {answerQuestion.map((qa: any, index: any) => (
        <Box key={index} sx={{ marginBottom: '20px' }}>

          <MetaDataMedia item={qa} />
          <br />
          <Typography sx={{ color: 'white', fontWeight: 'bold', textAlign: 'center', marginBottom: '10px' }}>{qa.question}
            <span style={{ color: 'white', fontSize: '10px' }}> {new Date(qa.date).toLocaleDateString()}</span>
          </Typography>
          <Typography>{qa.answer}</Typography>
          {index < answerQuestion.length - 1 && <Divider sx={{ my: 1 }} />}
        </Box>
      ))}
    </Box>
  );

  return (
    <Box sx={{ display: 'flex', alignItems: 'start', overflowY: 'hidden', height: '85vh', padding: '1rem', overflowX: 'hidden' }}>
      <Box sx={{ flex: '1', marginRight: '1rem' }}>
        <MainSearchBox onHandleSearchClick={handleAskQuestion} boxName='Ask Questions' />
        {isLoading && <SearchSpinner />}
        {renderQuestionAndAnswerSection()}
      </Box>
      <Box sx={{ flex: '1' }}>
        <SearchResultMiddleProps item={movieData} />
        <SearchResultMiddleBottom item={movieData} />
        <Box sx={{
          color: 'white', mt: 1, width: '550px', height: '180px', overflowY: 'auto', marginTop: '1rem', marginBottom: '2rem', '&::-webkit-scrollbar': {
            width: '8px',
            backgroundColor: 'transparent', // Transparent background
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(255, 255, 255, 0.2)', // Slightly visible scrollbar thumb
            borderRadius: '4px',
          }
        }}>
          <QuestionAnswerSearchResult item={movieData} />
        </Box>
      </Box>
    </Box>
  );
}


export default MediaInteract;