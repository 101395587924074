
import React from 'react';
import { Menu,MenuItem } from '@material-ui/core';
import {fetchDeleteSearchBySessionIdAndUserId} from '../../features/searchMediaSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';

interface SmallMenuProps {
    anchorEl: null | HTMLElement;
    setAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
    currentSessionId: string | null;
    setCurrentSessionId: React.Dispatch<React.SetStateAction<string | null>>;
   // handleButtonClick:any;
    item:any;
}

const SmallMenu:React.FC<SmallMenuProps>= ({anchorEl,setAnchorEl,currentSessionId,setCurrentSessionId,item}) => {
    const dispatch = useDispatch<AppDispatch>();

    const handleClose = () => {
        setAnchorEl(null);
        setCurrentSessionId(null);
    };

    const handleRenameClick = (sessionId: string) => {
        // Logic for renaming the item
        console.log('Rename item with Session ID:', sessionId);
        // Add your rename logic here
    };

    const handleDeleteClick = (sessionId: string) => {
        dispatch(fetchDeleteSearchBySessionIdAndUserId({sessionId}))
       // handleButtonClick(0, 'search',undefined)    
    };

    return (
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl) && currentSessionId === item.sessionId}
            onClose={handleClose}
            >
                <MenuItem onClick={() => handleRenameClick(item.sessionId)}>Rename</MenuItem>
                <MenuItem onClick={() => handleDeleteClick(item.sessionId)}>Delete</MenuItem>
        </Menu>
    );
}

export default SmallMenu;