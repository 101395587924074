import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CurrentBookState {
  bookId: string;
  pages: Array<String>;
  arrayIndex: number;
}

const initialState: CurrentBookState = {
  bookId: '',
  pages: [],
  arrayIndex: 0,
};

export const currentBookSlice = createSlice({
  name: "currentBook",
  initialState,
  reducers: {
    setCurrentBook: (state, action: PayloadAction<CurrentBookState>) => {
      state.bookId = action.payload.bookId;
      state.pages = action.payload.pages;
      state.arrayIndex = action.payload.arrayIndex;
    }
  }
});

export const { setCurrentBook } = currentBookSlice.actions;
export default currentBookSlice.reducer;