import React, { useState } from 'react';
import { Avatar, Menu, MenuItem, Tooltip, IconButton, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { menuPermissions } from '../middlewareComponents/AuthPolicy'; // Ensure the correct import path

// Create a styled avatar component for custom styling
const CustomAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: '#5D61AA',
  border: '2px solid #8a8db7',
  color: 'white',
  '&:hover': {
    backgroundColor: '#8a8db7',
    color: 'black',
    border: '2px solid #5D61AA'
  },
}));

const AvatarMenuComponent: React.FC<{}> = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const permissions = menuPermissions(); // Fetch permissions from localStorage

  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuOptionClick = (path: string) => {
    handleClose();
    if (path === 'logout') {
      console.log('Logout');
      navigate('/login');
    } else {
      navigate(path);
    }
  };

  const userEmail = "user@example.com";
  const userInitial = userEmail.charAt(0).toUpperCase();

  // Map permissions to menu items
  const menuItems = permissions ? Object.keys(permissions).map(pageName => (
    <MenuItem key={pageName} onClick={() => handleMenuOptionClick(`/${pageName.toLowerCase()}`)}>{permissions[pageName]}</MenuItem>
  )) : [];

  return (
    <>
      <Tooltip title={userEmail}>
        <IconButton onClick={handleAvatarClick}>
          <CustomAvatar>{userInitial}</CustomAvatar>
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems}
        <MenuItem onClick={() => handleMenuOptionClick('logout')}>Sign Out</MenuItem>
      </Menu>
    </>
  );
}

export default AvatarMenuComponent;
