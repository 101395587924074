
import React from 'react';
import { Typography, Box } from '@mui/material';

interface QuestionAnswerSearchResultProps {
    item: any;
    }

const QuestionAnswerSearchResult:React.FC<QuestionAnswerSearchResultProps> = ({item}) => {
    return(
        <Box>
            <Typography variant="h6" style={{ marginLeft: '1rem', color: 'white', fontFamily: 'Arial, sans-serif', fontWeight: 'normal', textAlign:'center' }}>
                 {item.search}
            </Typography>
            <Typography paddingTop={'10px'} style={{ marginLeft: '1rem',fontSize:'15px', marginBottom: '20px', color: 'white',  fontFamily: 'Arial, sans-serif',fontWeight: 'normal' }}>
                 {item.answer}
            </Typography>
      </Box>
    )
}

export default QuestionAnswerSearchResult;