

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { saveMovieToPlaylist, getPlaylist, deleteSemanticSearchFromPlaylist } from '../api/serverApis/playlistApi';

export interface TabContent {
  [key: string]: any[];
}

export interface playlistState {
  data: any;
  loading: boolean;
  error: string | null | undefined;
  tabsContent: TabContent;
}

const initialState: playlistState = {
  data: null,
  loading: false,
  error: null,
  tabsContent: {},
};

export const saveMovieToPlaylistSlice = createAsyncThunk('playlist/saveMovieToPlaylistSlice', async ({ playlistData }: { playlistData: any }) => {
  const response = await saveMovieToPlaylist(playlistData);
  return response.data;
});
export const getPlaylistSlice = createAsyncThunk('playlist/getPlaylistSlice', async ({ playlistId, courseId }: { playlistId: string, courseId: string }) => {
  const response = await getPlaylist(playlistId, courseId);
  return response.data;
});
export const deleteSemanticSearchFromPlaylistSlice = createAsyncThunk('playlist/deleteSemanticSearchFromPlaylistSlice', async ({ playlistId, courseId, _id }: { playlistId: string, courseId: string, _id: string }) => {
  const response = await deleteSemanticSearchFromPlaylist(playlistId, courseId, _id);
  return response.data;
});

const handleState = (action: any) => {
  return (builder: any) => {
    builder.
      addCase(action.pending, (state: any) => {
        state.loading = true;
      })
      .addCase(action.fulfilled, (state: any, action: PayloadAction<any>) => {
        state.loading = false;
        if (action.type === 'playlist/getPlaylistSlice/fulfilled') {
          state.data = action.payload;
        }
        if (action.type === 'playlist/saveMovieToPlaylistSlice/fulfilled') {
          state.data = action.payload;
        }
        if (action.type === 'playlist/deleteSemanticSearchFromPlaylistSlice/fulfilled') {
          state.data = action.payload;
        }
      })
      .addCase(action.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.error.message;
      });
  };
}

const playlistSlice = createSlice({
  name: 'playlistSlice',
  initialState: initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    handleState(saveMovieToPlaylistSlice)(builder);
    handleState(getPlaylistSlice)(builder);
    handleState(deleteSemanticSearchFromPlaylistSlice)(builder);
  }
});

export default playlistSlice.reducer;