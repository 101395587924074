import React from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

interface Course {
  courseId: string;
  courseName: string;
}

interface CourseDropdownProps {
  courses: Course[];
  selectedCourseId: string;
  onCourseChange: (courseId: string) => void;
}

const CourseDropdown: React.FC<CourseDropdownProps> = ({ courses, selectedCourseId, onCourseChange }) => {

  return (
    <FormControl fullWidth>
      <InputLabel id="course-select-label">Course</InputLabel>
      <Select
        labelId="course-select-label"
        value={selectedCourseId}
        onChange={(e) => onCourseChange(e.target.value as string)}
        label="Course"
      >
        {courses.map((course) => (
          <MenuItem key={course.courseId} value={course.courseId}>
            {course.courseName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CourseDropdown;