import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, OutlinedInput } from '@mui/material';
import GenericTable, { Column } from '../genericComponents/GenericTable';
import { tableMetadata } from './configurationTable';
import { RootState, AppDispatch } from '../../store';
import { fetchTableFromManager } from '../../features/tableSlice'; // Adjust import paths



interface UserRoleData {
  users: any[];
  roles: any[];
  rules: any[];
  ruleActions: any[];
  customers: any[];
  roleRules: any[];
}
const ManagerTables: React.FC = () => {
  const [selectedTables, setSelectedTables] = useState<string[]>([]);
  const dispatch = useDispatch<AppDispatch>();

  // Assuming your state structure holds data for each table under manager.entities
  const tableData = useSelector((state: RootState) => state.manager.entities);

  useEffect(() => {
    // Fetch data for all selected tables
    selectedTables.forEach(tableName => {
      dispatch(fetchTableFromManager({ tableName }));
    });
  }, [dispatch, selectedTables]);

  const handleChange = (event: any) => {
    setSelectedTables(event.target.value as string[]);
  };



  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="select-multiple-checkbox-label">Tables</InputLabel>
        <Select
          labelId="select-multiple-checkbox-label"
          id="select-multiple-checkbox"
          multiple
          value={selectedTables}
          onChange={handleChange}
          input={<OutlinedInput label="Tables" />}
          renderValue={(selected) => selected.join(', ')}
        >
          {Object.keys(tableMetadata).map((tableName) => (
            <MenuItem key={tableName} value={tableName}>
              <Checkbox checked={selectedTables.indexOf(tableName) > -1} />
              <ListItemText primary={tableMetadata[tableName].displayName} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedTables.map((tableName) => {
        const data = tableData[tableName] || [];
        const config = tableMetadata[tableName];
        const columns = config.getColumns(data);
        const transformedRows = config.transformRow;

        return (

          <GenericTable
            key={tableName}
            columns={columns}
            transformRow={transformedRows}
            title={config.displayName}
            entityName={tableName}
          />
        );
      })}
    </div>
  );
};

export default ManagerTables;