import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../../store';
import { Box, Typography, Grid, Paper, useTheme, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import { fetchContentOfCourse, fetchAllCourses, fetchCourseSpecificTopicsKeyPointsAndSentences } from '../../../features/coursesSlice';
import { convertNumberToTime, handleTimes } from '../../searchComponents/UtilityFunctions';
import CourseDropdown from './CourseDropdown';
import TopicDetailsTable from './TopicDetailsTable';

interface Video {
  id: string;
  subject: string;
  summary: string;
  length: string;
  startTime: number;
  endTime: number;
}

interface MainVideo extends Video {
  subVideos: Video[];
}

const ManagementTocPage2 = () => {
  const dispatch = useDispatch<AppDispatch>();
  const contentOfCourse = useSelector((state: RootState) => state.courses.contentOfCourse);
  const allCourses = useSelector((state: RootState) => state.courses.courses);
  const courseSpecificTopicsKeyPointsAndSentences = useSelector((state: RootState) => state.courses.courseSpecificTopicsKeyPointsAndSentences);
  const theme = useTheme();
  const [selectedCourseId, setSelectedCourseId] = useState<string>('');
  const [selectedVideoDetails, setSelectedVideoDetails] = useState<any>(null);
  const [selectedMovieId, setSelectedMovieId] = useState<string>('');

  useEffect(() => {
    dispatch(fetchAllCourses());
  }, [dispatch]);

  useEffect(() => {
    if (selectedCourseId) {
      dispatch(fetchContentOfCourse(selectedCourseId));
    }
  }, [selectedCourseId, dispatch]);

  useEffect(() => {
    if (courseSpecificTopicsKeyPointsAndSentences) {
      setSelectedVideoDetails(courseSpecificTopicsKeyPointsAndSentences);
    }
  }, [courseSpecificTopicsKeyPointsAndSentences]);

  const isHebrew = (text: string) => /[\u0590-\u05FF\uFB1D-\uFB4F\uFB50-\uFDFF]/.test(text);

  // Sort contentOfCourse by subject
  const sortedContentOfCourse = contentOfCourse ? [...contentOfCourse].sort((a, b) => a.subject.localeCompare(b.subject)) : [];

  const handleCourseChange = (courseId: string) => {
    setSelectedCourseId(courseId);
  };

  const handleSubVideoSelect = (movieId: string, id: string, event: React.MouseEvent) => {
    event.stopPropagation();
    setSelectedMovieId(movieId);

    dispatch(fetchCourseSpecificTopicsKeyPointsAndSentences({ movieId, topicId: id }));
  };

  const handleSaveChanges = (updatedDetails: any) => {
    // Handle saving the changes, e.g., send them to the server
    console.log("Updated Details:", updatedDetails);
    // Example: dispatch an action to save the changes to the server
    // dispatch(saveUpdatedDetails(updatedDetails));
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: '2%', width: '100%' }}>
      <Box sx={{ flexGrow: 1, p: 1, width: selectedVideoDetails ? '30%' : '100%', height: '80vh', overflowY: 'auto' }}>
        <CourseDropdown
          courses={allCourses}
          selectedCourseId={selectedCourseId}
          onCourseChange={handleCourseChange}
        />

        <Grid container spacing={1}>
          {sortedContentOfCourse.map((mainVideo) => (
            <Grid item xs={12} key={mainVideo.id}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: '97%', // Adjust the width as needed
                  }}
                >
                  <Paper
                    sx={{
                      width: '100%',
                      p: 1,
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      backgroundColor: theme.palette.background.paper,
                    }}
                    onClick={() => { }}
                  >
                    <VideoLibraryIcon color="action" />
                    <Box sx={{ ml: 1 }}>
                      <Typography sx={{ fontSize: '0.9rem', direction: isHebrew(mainVideo.subject) ? 'rtl' : 'ltr' }} variant="body1">{mainVideo.subject}</Typography>
                      <Box sx={{ maxHeight: '5rem', overflowY: 'auto' }}>
                        <Typography sx={{ fontSize: '0.7rem', direction: isHebrew(mainVideo.summary) ? 'rtl' : 'ltr' }} variant="body2" color="text.secondary">{mainVideo.summary}</Typography>
                      </Box>
                      <Typography sx={{ fontWeight: 'bold', fontSize: '0.7rem' }} variant="body2" color="text.secondary">Length: {convertNumberToTime(mainVideo.length)}</Typography>
                    </Box>
                  </Paper>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ width: '100%' }}>
                    {mainVideo.content.map((subVideo: any) => (
                      <Paper
                        key={subVideo.id}
                        sx={{
                          width: '100%',
                          p: 1,
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                          my: 1,
                          ':hover': { transform: 'translateX(10px)', transition: 'transform 0.3s ease-in-out' },
                          backgroundColor: theme.palette.background.default, // Default theme background
                        }}
                        onClick={(e) => handleSubVideoSelect(mainVideo.movieId, subVideo.id, e)}
                      >
                        <VideoLibraryIcon color="action" />
                        <Box sx={{ ml: 1 }}>
                          <Typography sx={{ fontSize: '0.9rem', direction: isHebrew(subVideo.subject) ? 'rtl' : 'ltr' }} variant="body1">{subVideo.subject}</Typography>
                          <Typography sx={{ fontSize: '0.7rem', direction: isHebrew(subVideo.summary) ? 'rtl' : 'ltr' }} variant="body2" color="text.secondary">{subVideo.summary}</Typography>
                          <Box sx={{ margin: '10px' }}>
                            {subVideo.subSubject && subVideo.subSubject.split(',').map((sentence: string, sentenceIndex: number) => (
                              <Typography
                                key={sentenceIndex}
                                variant="subtitle2"
                                sx={{
                                  direction: isHebrew(sentence) ? 'rtl' : 'ltr',
                                  marginLeft: isHebrew(sentence) ? '' : '20px',
                                  marginRight: isHebrew(sentence) ? '20px' : '',
                                }}
                              >
                                {`${sentenceIndex + 1}. ${sentence.trim()}`}
                              </Typography>
                            ))}
                          </Box>
                          <Box sx={{ ml: 2 }}>
                            <Typography>Main Key Point:</Typography>
                            {subVideo.topics && subVideo.topics.map((topic: string, index: number) => (
                              <Typography key={index} sx={{ fontSize: '0.7rem', direction: isHebrew(topic) ? 'rtl' : 'ltr' }} variant="body2" color="text.secondary">{index + 1}.{topic}</Typography>
                            ))}
                          </Box>
                          <Typography sx={{ fontWeight: 'bold', fontSize: '0.7rem', direction: isHebrew(subVideo.subject) ? 'rtl' : 'ltr' }} variant="body2" color="text.secondary">{handleTimes(subVideo.startTime, subVideo.endTime, "-1")} </Typography>
                        </Box>
                      </Paper>
                    ))}
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
        </Grid>
      </Box>
      {selectedVideoDetails && selectedVideoDetails.getAllTopicFromMediaTopicKeyPoints && (
        <Box sx={{ width: '70%', marginLeft: '2%', height: '80vh', overflowY: 'auto' }}>
          <TopicDetailsTable details={selectedVideoDetails} onSaveChanges={handleSaveChanges} movieId={selectedMovieId} />
        </Box>
      )}
    </Box>
  );
};

export default ManagementTocPage2;
