
import axiosInstance from "../interceptor";
import { CHATGPT_API_URL, GATEWAY_API_URL } from "../api";

//const API = "/api/v1/interactMedia";
const API = '/api/movieSearchService/api/v1/interactMedia';

export interface InteractMediaSendData {
  interactSessionId?: string;
  movieData: string;
  question?: string;
}
export interface ArchiveMediaSendData {
  sessionId?: string;
  movieId?: string;
}
// export const getResultWithQuestionAnswer = async (sendData: InteractMediaSendData) => {
//   return axiosInstance.post(`${CHATGPT_API_URL}${API}/interactQuestionAnswer`, sendData);
// }
// export const getArchiveQuestionAnswer = async (sendData: ArchiveMediaSendData) => {
//   return axiosInstance.post(`${CHATGPT_API_URL}${API}/getArchiveQuestionAnswer`, sendData);
// }

export const getResultWithQuestionAnswer = async (sendData: InteractMediaSendData) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${API}/interactQuestionAnswer`, sendData);
}
export const getArchiveQuestionAnswer = async (sendData: ArchiveMediaSendData) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${API}/getArchiveQuestionAnswer`, sendData);
}