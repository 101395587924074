import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Box, Typography } from '@mui/material';
import { convertNumberToTime } from '../../searchComponents/UtilityFunctions';

interface DraggableItem {
  index: number;
  id: number;
  type: string;
}

interface Sentence {
  id: number;
  audioFileName: string;
  articleId: string;
  paragraphId: number;
  timeStart: string;
  timeEnd: string;
  sentence: string;
}

const DraggableBoxSentence: React.FC<{ sentence: Sentence; index: number; moveSentence: (item: DraggableItem) => void; type: string }> = ({ sentence, index, moveSentence, type }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [, drop] = useDrop({
    accept: type,
    hover(item: DraggableItem) {
      if (!ref.current) {
        return;
      }
      if (item.index === index) {
        return;
      }
      moveSentence(item);
      item.index = index;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: type,
    item: { type: type, id: sentence.id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <Box
      ref={ref}
      sx={{
        opacity: isDragging ? 0.5 : 1,
        backgroundColor: isDragging ? 'lightgreen' : 'inherit',
        padding: '10px',
        margin: '5px',
        border: '1px solid gray',
      }}
    >
      <Typography variant="body2">{`Audio File: ${sentence.audioFileName}`}</Typography>
      <Typography variant="body2">{`Paragraph ID: ${sentence.paragraphId}`}</Typography>
      <Typography sx={{ fontWeight: 'bold' }}>{`Sentence: ${sentence.sentence}`}</Typography>
      <Typography sx={{ color: 'red' }}>{`Time Start: ${convertNumberToTime(Number.parseFloat(sentence.timeStart))}`}</Typography>
      <Typography sx={{ color: 'red' }}>{`Time End: ${convertNumberToTime(Number.parseFloat(sentence.timeEnd))}`}</Typography>
    </Box>
  );
};

export default DraggableBoxSentence;
