import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

interface LoadingDotsProps {
  message?: string;
  isEnabled?: boolean;
  messages?: string[];
}

const LoadingDots: React.FC<LoadingDotsProps> = ({ message = 'Loading', isEnabled = false, messages = [] }) => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  useEffect(() => {
    if (messages.length > 0 && currentMessageIndex < messages.length - 1) {
      const interval = setInterval(() => {
        setCurrentMessageIndex((prevIndex) => {
          if (prevIndex < messages.length - 1) {
            return prevIndex + 1;
          } else {
            clearInterval(interval); // Stop the interval when reaching the last message
            return prevIndex;
          }
        });
      }, 5000); // Change message every 3 seconds
      return () => clearInterval(interval); // Clean up interval on component unmount
    }
  }, [messages, currentMessageIndex]);

  const displayMessage = messages.length > 0 ? messages[currentMessageIndex] : message;

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      {/* Display the message and only one set of loading dots */}
      <Typography variant="h6" align="center" sx={{ mt: 4 }}>
        {displayMessage}
        <Box component="span" sx={{
          display: 'inline-block',
          width: '0.5em',
          height: '0.5em',
          borderRadius: '50%',
          backgroundColor: 'currentColor',
          animation: 'loading 1.4s infinite',
          marginLeft: '0.2em',
          '@keyframes loading': {
            '0%': { opacity: 0 },
            '20%': { opacity: 1 },
            '100%': { opacity: 0 },
          },
        }}></Box>
        <Box component="span" sx={{
          display: 'inline-block',
          width: '0.5em',
          height: '0.5em',
          borderRadius: '50%',
          backgroundColor: 'currentColor',
          animation: 'loading 1.4s infinite 0.2s',
          marginLeft: '0.2em',
          '@keyframes loading': {
            '0%': { opacity: 0 },
            '20%': { opacity: 1 },
            '100%': { opacity: 0 },
          },
        }}></Box>
        <Box component="span" sx={{
          display: 'inline-block',
          width: '0.5em',
          height: '0.5em',
          borderRadius: '50%',
          backgroundColor: 'currentColor',
          animation: 'loading 1.4s infinite 0.4s',
          marginLeft: '0.2em',
          '@keyframes loading': {
            '0%': { opacity: 0 },
            '20%': { opacity: 1 },
            '100%': { opacity: 0 },
          },
        }}></Box>
      </Typography>

      {/* If enabled, show additional content without duplicating the dots */}

    </Box>
  );
};

export default LoadingDots;
