

import axiosInstance from "../interceptor";
import { CHATGPT_API_URL, GATEWAY_API_URL } from "../api";


//const API = "/api/v1/searchMovie";
const API = '/api/movieSearchService/api/v1/searchMovie';

export interface SearchSendData {
  sessionId: string;
  text: string;
  isImageSearch?: boolean;
  audioName?: string;
  mainCategory?: string;
}

// export const generateResultFromSearch = async (sendData: SearchSendData) => {
//     return axiosInstance.post(`${CHATGPT_API_URL}${API}/searchAnswering`, sendData);
//   }

// export const getAllArchivesSearchForUser = async () => {
//     return axiosInstance.get(`${CHATGPT_API_URL}${API}/archiveSearchPerUser`);
//   }
// export const getSearchBySessionId = async (sessionId:string) => {
//     return axiosInstance.post(`${CHATGPT_API_URL}${API}/getSearchBySessionId`,{params:{sessionId}});
//   }
// export const deleteSearchBySessionIdAndUserId = async (sessionId:string) => {
//     return axiosInstance.post(`${CHATGPT_API_URL}${API}/deleteSearchBySessionIdAndUserId`,{params:{sessionId}});
//   }
// export const  searchSpecificMovie = async (searchTerm:string) => {
//     return axiosInstance.post(`${CHATGPT_API_URL}${API}/searchSpecificMovie`,{params:{searchTerm}});
// }
// export const searchForMainCategory = async (searchTerm:string) => {
//     return axiosInstance.post(`${CHATGPT_API_URL}${API}/searchForMainCategory`,{params:{searchTerm}});
// }
// export const searchSeinfeld = async (sendData: SearchSendData) => {
//     return axiosInstance.post(`${CHATGPT_API_URL}${API}/searchSeinfeld`,{params:{sendData}});
// }

export const generateResultFromSearch = async (sendData: SearchSendData) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${API}/searchAnswering`, sendData);
}

export const getAllArchivesSearchForUser = async () => {
  return axiosInstance.get(`${GATEWAY_API_URL}${API}/archiveSearchPerUser`);
}
export const getSearchBySessionId = async (sessionId: string) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${API}/getSearchBySessionId`, { params: { sessionId } });
}
export const deleteSearchBySessionIdAndUserId = async (sessionId: string) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${API}/deleteSearchBySessionIdAndUserId`, { params: { sessionId } });
}
export const searchSpecificMovie = async (searchTerm: string) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${API}/searchSpecificMovie`, { params: { searchTerm } });
}
export const searchForMainCategory = async (searchTerm: string) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${API}/searchForMainCategory`, { params: { searchTerm } });
}
export const searchSeinfeld = async (sendData: SearchSendData) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${API}/searchSeinfeld`, { params: { sendData } });
}