
import React, { useState } from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup, FormLabel } from '@mui/material';
import AutocompleteSearchBox from './AutocompleteSearchBox'; //
const CourseOptions = () => {

  const [value, setValue] = useState('all');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };
  const handleAutocompleteSelect = (event: any, newValue: any) => {
    // You can perform actions based on the selected value here
    console.log(newValue); // For example, log the selected suggestion
  };
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">Course Options</FormLabel>
      <RadioGroup row aria-label="course-options" name="course-options" value={value} onChange={handleChange}>
        <FormControlLabel value="all" control={<Radio />} label="All Courses" />
        <FormControlLabel value="specific-course" control={<Radio />} label="Specific Course" />
        <FormControlLabel value="specific-video" control={<Radio />} label="Specific Video" />
        <FormControlLabel value="personal-videos" control={<Radio />} label="Personal Videos" />
      </RadioGroup>
      {(value === 'specific-course' || value === 'specific-video' || value === 'personal-videos') && (
        <AutocompleteSearchBox onSearchSelect={handleAutocompleteSelect} />
      )}
    </FormControl>
  );
};

export default CourseOptions;
