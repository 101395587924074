
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getResultWithQuestionAnswer, InteractMediaSendData,getArchiveQuestionAnswer,ArchiveMediaSendData } from '../api/serverApis/mediaInteractApi';

export interface interactResult{
    interactResult:any;
    loading: boolean;
    error: string | null | undefined;
}

const initialState: interactResult = {
    interactResult: [],
    loading: false,
    error: null,
};

export const fetchQuestionAnswerInteractResult = createAsyncThunk('textAudio/fetchQuestionAnswerInteractResult', async ({ interactSessionId, movieData, question }: { interactSessionId: string, movieData: any, question: string }) => {
    const sendData: InteractMediaSendData = {
        interactSessionId,
        movieData,
        question
    };
    const response = await getResultWithQuestionAnswer(sendData);
    return response.data;
});

export const fetchArchiveQuestionAnswerInteractResult = createAsyncThunk('textAudio/fetchArchiveQuestionAnswerInteractResult', async ({ movieData }: { movieData: any}) => {
    const sendData: ArchiveMediaSendData = {
      sessionId: movieData.sessionId,
        movieId: movieData.movieId
    };
    const response = await getArchiveQuestionAnswer(sendData);
    return response.data;
});

const handleState = (action: any) => {
    return (builder: any) => {
        builder.
            addCase(action.pending, (state: any) => {
                state.loading = true;
            })
            .addCase(action.fulfilled, (state: any, action: PayloadAction<string>) => {
                state.loading = false;
                if (action.type === 'textAudio/fetchQuestionAnswerInteractResult/fulfilled') {
                    state.interactResult = action.payload;
                }
                if (action.type === 'textAudio/fetchArchiveQuestionAnswerInteractResult/fulfilled') {
                    state.interactResult = action.payload;
                }
            })
            .addCase(action.rejected, (state: any, action: any) => {
                state.loading = false;
                state.error = action.error.message;
            })
    }
}

const interactSlice = createSlice({
    name: 'interact',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        handleState(fetchQuestionAnswerInteractResult)(builder);
        handleState(fetchArchiveQuestionAnswerInteractResult)(builder);
    }
});

export default interactSlice.reducer;