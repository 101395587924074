
import React, { useEffect, useState } from 'react';
import AutocompleteComponent from '../searchComponents/AutocompleteComponent';
import DiagramVisualization from '../managerComponents/DiagramVisualization';
import { getAllUsersIsAndEmail, getRoleCustomerAndRuleDataFromUserEmail } from '../../api/serverApis/managerApi';

interface UserRoleData {
  users: any[];
  roles: any[];
  rules: any[];
  ruleActions: any[];
  customers: any[];
  roleRules: any[];
}

const ManagerDiagram: React.FC = () => {
  const [userData, setUserData] = useState("");
  const [roleCustomerAndRuleData, setRoleCustomerAndRuleData] = useState<UserRoleData>({ users: [], roles: [], rules: [], ruleActions: [], customers: [], roleRules: [] });

  useEffect(() => {
    if (userData.length > 7) {
      const fetchData = async () => {
        const newData = await getRoleCustomerAndRuleDataFromUserEmail(userData);
        console.log(newData.data);
        setRoleCustomerAndRuleData(newData.data);
      }
      fetchData();
    };
  }, [userData]);

  return (
    <div>
      <AutocompleteComponent setMainCategory={() => { }} setAudioName={setUserData} apiCall={getAllUsersIsAndEmail} anyColor={'black'} />
      <DiagramVisualization users={roleCustomerAndRuleData.users} roles={roleCustomerAndRuleData.roles} rules={roleCustomerAndRuleData.rules} ruleActions={roleCustomerAndRuleData.ruleActions} customers={roleCustomerAndRuleData.customers} roleRules={roleCustomerAndRuleData.roleRules} />
    </div>
  );
}

export default ManagerDiagram;