// src/store/slices/blobSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getVideoFiles, getPdfFiles } from '../api/serverApis/filesApi';




interface BlobState {
  savedUrls: { [key: string]: string };
  loading: boolean;
  error: string | null;
}

const initialState: BlobState = {
  savedUrls: {},
  loading: false,
  error: null,
};


// Thunks for fetching blobs and saving the local URL
export const fetchBlob = createAsyncThunk('blobs/fetchBlob', async (url: string, { getState, dispatch }) => {
  const state = getState() as any; // Adjust this type to match your RootState
  const existingUrl = state.blobs.savedUrls[url];

  if (existingUrl) {
    return existingUrl;
  }

  let response;
  if (url.includes('.mp4')) {
    response = await getVideoFiles(url);
  }
  else if (url.includes('.pdf')) {
    response = await getPdfFiles(url);
  }
  else {
    throw new Error('Unsupported file type');
  }

  const blob = new Blob([response.data], {
    type: url.includes('.mp4') ? 'video/mp4' : 'application/pdf',
  });

  const localUrl = URL.createObjectURL(blob);

  dispatch(saveUrl({ url, localUrl }));
  return localUrl;
}
);

const blobSlice = createSlice({
  name: 'blobs',
  initialState,
  reducers: {
    saveUrl: (state, action: PayloadAction<{ url: string; localUrl: string }>) => {
      state.savedUrls[action.payload.url] = action.payload.localUrl;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBlob.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBlob.fulfilled, (state, action) => {
        state.loading = false;
        state.savedUrls[action.meta.arg] = action.payload as string;
      })
      .addCase(fetchBlob.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch blob';
      });
  },
});

export const { saveUrl } = blobSlice.actions;

export const selectUrlByBlob = (state: any, url: string) => state.blobs.savedUrls[url];

export default blobSlice.reducer;
