import axiosInstance from "../interceptor";
import { CHATGPT_API_URL } from "../api";
import { GenerateSvgPayload } from "../../features/mermaidSlice";

const API = "/api/v1/mermaid";
const getMermaidSvg = async ({ userId, diagramCode, chatDiagramId }: GenerateSvgPayload) => {
  return axiosInstance.post(`${CHATGPT_API_URL}${API}/mermaid/user/${userId}/generate`, {
    diagramCode,
    chatDiagramId
  });
};

export { getMermaidSvg }