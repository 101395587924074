import axiosInstance from "../interceptor";
import { CHATGPT_API_URL } from "../api";


const API = "/api/v1/book";
export const bookChat = async (userId: string, text: string, apiKey: string) => {
  return axiosInstance.post(`${CHATGPT_API_URL}${API}/extract`, {
    userId,
    text,
    apiKey,
  });
};


export const getBooksByUserId = async ({ id }: { id: string }) => {
  return axiosInstance.get(`${CHATGPT_API_URL}${API}/${id}`);
}