
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getTable, TableSendData, getFieldsFromTables } from '../api/serverApis/managerApi';

export interface managerState {
  entitiesKeys: {
    [key: string]: string[]; // Dynamic key for entity name, storing an array of any type
  }
  entities: {
    [key: string]: any[]; // Dynamic key for entity name, storing an array of any type
  };
  loading: boolean;
  error: string | null | undefined;
}

const initialState: managerState = {
  entitiesKeys: {},
  entities: {},
  loading: false,
  error: null,
};
interface FetchTablePayload {
  tableName: string;
  data: any[];
}

export const fetchTableFromManager = createAsyncThunk('manager/fetchTableFromManager', async ({ tableName }: { tableName: string }) => {
  const sendData: TableSendData = {
    tableName
  };
  const response = await getTable(sendData);
  return { tableName, data: response.data };
});
export const fetchAllTableNamesEntityTypes = createAsyncThunk('manager/fetchAllTableNamesEntityTypes', async ({ tablesNames }: { tablesNames: string[] }) => {
  const sendData: TableSendData = {
    tablesNames
  };
  const response = await getFieldsFromTables(sendData);
  return response.data;
});

const handleState = (action: any) => {
  return (builder: any) => {
    builder.
      addCase(action.pending, (state: any) => {
        state.loading = true;
      })
      .addCase(action.fulfilled, (state: any, action: PayloadAction<FetchTablePayload>) => {
        state.loading = false;
        if (action.type === 'manager/fetchTableFromManager/fulfilled') {
          const { tableName, data } = action.payload;
          state.entities[tableName] = data;
        }
        else if (action.type === 'manager/fetchAllTableNamesEntityTypes/fulfilled') {
          state.entitiesKeys = action.payload;
        }
      })
      .addCase(action.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
}

const tableSlice = createSlice({
  name: 'manager',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    handleState(fetchTableFromManager)(builder);
    handleState(fetchAllTableNamesEntityTypes)(builder);
  }
});

export default tableSlice.reducer;

