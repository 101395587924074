
import React,{useState} from 'react';

import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

interface MainSearchBoxProps {
    onHandleSearchClick: (searchQuery: string) => void,
    boxName:string
}
const MainSearchBox:React.FC<MainSearchBoxProps> = ({onHandleSearchClick,boxName}) => {
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
      };
    const handleSearchClick = () => {
        onHandleSearchClick(searchQuery);
    }
    return(
            <TextField
                label={boxName}
                placeholder={boxName}
                value={searchQuery}
                onChange={handleSearchChange}
                variant="outlined"
                size="small"
                fullWidth
                autoComplete="off" 
                InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                    <SearchIcon onClick={handleSearchClick} style={{cursor:'pointer'}}/>
                    </InputAdornment>
                ),
                style: { borderRadius: '20px' }
                }}
            />    
       )
}

export default MainSearchBox;