import React, { useEffect } from 'react';
import { auth } from './api/serverApis/userApi';
import { RootState } from './store';
import Router from './Router';

const App = () => {
  const isAuth = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      const res = await auth(token)
      if (res.data.data !== 'success') {
        // window.location.href = location.href;
        goToLogin();
      }
    }
    else {
      goToLogin();
    }
  }
  const goToLogin = () => {
    // if (window.location.pathname !== '/login') {
    //   window.location.href = '/login';
    // }
  }
  useEffect(() => {
    // isAuth();
  }, []);

  return (
    <Router />
  );
}

export default App;
