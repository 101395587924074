

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { solverSearch, SolverSearchSendData, searchBySessionId, searchByCourseId, getQuestionArchive, deleteMovie, deleteSearch, deleteAnswerAndQuestionByQuestionId, searchByCourseIdWithAnswerDataId } from '../api/serverApis/solverSearchApi';

export interface TabContent {
  [key: string]: any[];
}

export interface solverSearchState {
  questionsArchive: any;
  data2: any;
  data: any;
  loading: boolean;
  error: string | null | undefined;
  tabsContent: TabContent;
}

const initialState: solverSearchState = {
  questionsArchive: null,
  data2: null,
  data: null,
  loading: false,
  error: null,
  tabsContent: {},
};

export const fetchSolverSearch = createAsyncThunk('solverSearch/fetchSolverSearch', async ({ searchTerm, sessionId, courseId, audioName, onlySearch, searchId, time, articleId, customer, modelName, numberOfSearchResults, bestResultMethod, longContext }: { searchTerm: string, sessionId: string | null, courseId: string | undefined, audioName: string | undefined, onlySearch: string | undefined, searchId: string | undefined, time: string | undefined, articleId: string | undefined, customer: string | null, modelName: string | null, numberOfSearchResults: string | null, bestResultMethod: string | null, longContext: string | null }) => {
  const sendData: SolverSearchSendData = {
    searchTerm,
    sessionId,
    courseId,
    audioName,
    onlySearch,
    searchId,
    time,
    articleId,
    customer,
    modelName,
    numberOfSearchResults,
    bestResultMethod,
    longContext
  };
  const response = await solverSearch(sendData);
  return response.data;
});
export const fetchSolverQuestionDelete = createAsyncThunk('solverSearch/fetchQuestionDelete', async ({ courseId, searchId, questionId }: { searchId: string, courseId: string, questionId: string }) => {
  const response = await deleteAnswerAndQuestionByQuestionId(courseId, searchId, questionId);
  return response.data;
});
export const fetchSolverSearchDeleteMovie = createAsyncThunk('solverSearch/fetchSearchDeleteMovie', async ({ searchId, courseId, answerDataId }: { searchId: string, courseId: string, answerDataId: string | null }) => {
  const response = await deleteSearch(courseId, searchId, answerDataId);
  return response.data;

});
export const fetchSolverMovieDeleteMovie = createAsyncThunk('solverSearch/fetchMovieDeleteMovie', async ({ courseId, searchId, searchIdMovie, articleId }: { searchId: string, searchIdMovie: string, articleId: string, courseId: string }) => {
  const response = await deleteMovie(courseId, searchId, searchIdMovie, articleId);
  return response.data;

});
// export const getAllArchivesSearchForUser = createAsyncThunk('solverSearch/getAllArchivesSearchForUser', async (userId: string) => {
//   const response = await solverSearch({ userId });
//   return response.data;
// });
export const getSolverSearchBySessionId = createAsyncThunk('solverSearch/getSearchBySessionId', async (sessionId: string) => {
  const response = await searchBySessionId(sessionId);
  return response.data;
});
export const getSolverSearchByCourseId = createAsyncThunk('solverSearch/getSearchByCourseId', async ({ courseId, searchId }: { courseId: string, searchId: string }) => {
  const response = await searchByCourseId(courseId, searchId);
  return response.data;
});
export const getSolverSearchByCourseIdAndAnswerDataId = createAsyncThunk('combinationSearch/getSolverSearchByCourseIdAndAnswerDataId', async ({ courseId, searchId, answerDataId }: { courseId: string, searchId: string, answerDataId: string }) => {
  const response = await searchByCourseIdWithAnswerDataId(courseId, searchId, answerDataId);
  return response.data;
});
export const getSolverQuestionArchiveByCourseId = createAsyncThunk('solverSearch/getQuestionArchiveByCourseId', async (courseId: string) => {
  const response = await getQuestionArchive(courseId);
  return response.data;
});
const handleState = (action: any) => {
  return (builder: any) => {
    builder.
      addCase(action.pending, (state: any) => {
        state.loading = true;
      })
      .addCase(action.fulfilled, (state: any, action: PayloadAction<any>) => {
        state.loading = false;
        if (action.type === 'solverSearch/fetchSolverSearch/fulfilled') {
          state.data = action.payload;
        }
        if (action.type === 'solverSearch/fetchSolverSearch2/fulfilled') {
          state.data2 = action.payload;
        }
        if (action.type === 'solverSearch/getSearchBySessionId/fulfilled') {
          state.data = action.payload;
        }
        if (action.type === 'solverSearch/getSearchByCourseId/fulfilled') {
          state.data = action.payload;
        }
        if (action.type === 'combinationSearch/getSolverSearchByCourseIdAndAnswerDataId/fulfilled') {
          state.data = action.payload;
        }
        if (action.type === 'solverSearch/getQuestionArchiveByCourseId/fulfilled') {
          state.questionsArchive = action.payload;
        }
        if (action.type === 'solverSearch/fetchSearchDeleteMovie/fulfilled') {
          state.data = action.payload;
        }
        if (action.type === 'solverSearch/fetchMovieDeleteMovie/fulfilled') {
          state.data = action.payload;
        }
        if (action.type === 'solverSearch/fetchQuestionDelete/fulfilled') {
          state.data = action.payload;
        }
      })
      .addCase(action.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.error.message;
      });
  };
}

const solverSearchSlice = createSlice({
  name: 'solverSearch',
  initialState: initialState,
  reducers: {
    updateTabContent: (state, action: PayloadAction<{ key: string; content: any[] }>) => {
      const { key, content } = action.payload;
      state.tabsContent[key] = content;
    },
    // Action to remove a tab from a specific key
    removeTabContent: (state, action: PayloadAction<{ key: string; tabIndex: number }>) => {
      const { key, tabIndex } = action.payload;
      const updatedTabs = state.tabsContent[key].filter((_, index) => index !== tabIndex);
      if (updatedTabs.length > 0) {
        state.tabsContent[key] = updatedTabs;
      } else {
        delete state.tabsContent[key]; // Remove the key if no tabs left
      }
    },
    resetSolverData: (state) => {
      state.data = [];
    },
    resetQuestionsSolverArchive: (state) => {
      state.questionsArchive = null;
    }
  },
  extraReducers: (builder) => {
    handleState(fetchSolverSearch)(builder);
    handleState(getSolverSearchBySessionId)(builder);
    handleState(getSolverSearchByCourseId)(builder);
    handleState(getSolverSearchByCourseIdAndAnswerDataId)(builder);
    handleState(getSolverQuestionArchiveByCourseId)(builder);
    handleState(fetchSolverSearchDeleteMovie)(builder);
    handleState(fetchSolverMovieDeleteMovie)(builder);
    handleState(fetchSolverQuestionDelete)(builder);
  }
});
export const { updateTabContent, removeTabContent, resetSolverData, resetQuestionsSolverArchive } = solverSearchSlice.actions;
export default solverSearchSlice.reducer;