import { createSlice, createAsyncThunk, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { chatGptChat, getChatGptById } from '../api/serverApis/chatGptApi';

interface chatGptState {
  questions: string[];
  loading: boolean;
  error: string | null | undefined;
}

export interface FetchResponsePayload {
  userId: string;
  question: string;
  apiKey: string;
}

const initialState: chatGptState = {
  questions: [],
  loading: false,
  error: null,
};

export const fetchChatGptThunk = createAsyncThunk('chatGpt/fetchChatGpt', async ({ userId, question, apiKey }: FetchResponsePayload) => {
  const response = await chatGptChat({ userId, question, apiKey });
  return response.data;
});
export const fetchChatGptByIdThunk = createAsyncThunk('chatGpt/fetchChatGptById', async ({ id }: { id: string }) => {
  const response = await getChatGptById({ id });
  return response.data;
});

const handleChatState = (action: any) => {
  return (builder: any) => {
    builder
      .addCase(action.pending, (state: any) => {
        state.loading = true;
      })
      .addCase(action.fulfilled, (state: any, action: PayloadAction<string>) => {
        state.loading = false;
        state.questions.push(action.payload);
      })
      .addCase(action.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
}

const chatGptSlice = createSlice({
  name: 'chatGpt',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    handleChatState(fetchChatGptThunk)(builder);
    handleChatState(fetchChatGptByIdThunk)(builder);
  },
});

export default chatGptSlice.reducer;