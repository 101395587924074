



import axiosInstance from "../interceptor";
import { CHATGPT_API_URL, GATEWAY_API_URL } from "../api";

const api = '/api/imageService/';

export const getImagesFiles = async (directoryAndFile: string) => {
  return axiosInstance.get(`${GATEWAY_API_URL}${api}images/${directoryAndFile}`, { responseType: 'blob' });
}
export const getVideoFiles = async (directoryAndFile: string) => {
  return axiosInstance.get(`${GATEWAY_API_URL}${api}videos/${directoryAndFile}`, { responseType: 'blob' });
}
export const getPdfFiles = async (directoryAndFile: string) => {
  return axiosInstance.get(`${GATEWAY_API_URL}${api}pdf/${directoryAndFile}`, { responseType: 'blob' });
}


