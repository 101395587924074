import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  TextField,
  Button
} from '@mui/material';
import { useDrag, useDrop } from 'react-dnd';
import { Topic } from './tableTypes';

interface TableOfContentDataProps {
  topics: any[];
  onTopicNameChange: (topicId: number, newName: string) => void;
  onSummaryChange: (topicId: number, newSummary: string) => void;
  onKeyPointChange: (topicNumber: number, index: number, newKeyPoint: string) => void;
  onKeyPointTopicNumberChange: (topicNumber: number, index: number, newTopicNumber: number) => void;
  moveSentence: (sentenceId: string, targetTopicNumber: number) => void;
  sortSentencesInRow: (topicNumber: number) => void;
  reorderSentencesInRow: (topicNumber: number, dragIndex: number, hoverIndex: number) => void;
}

const convertTimeToMinutes = (time: string) => {
  const seconds = parseFloat(time);
  return (seconds / 60).toFixed(2);
};

const TableOfContentData: React.FC<TableOfContentDataProps> = ({
  topics,
  onTopicNameChange,
  onSummaryChange,
  onKeyPointChange,
  onKeyPointTopicNumberChange,
  moveSentence,
  sortSentencesInRow,
  reorderSentencesInRow
}) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ fontSize: '5px', width: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell>Topic Number</TableCell>
            <TableCell>Start Time (mins)</TableCell>
            <TableCell>End Time (mins)</TableCell>
            <TableCell>Topic Name</TableCell>
            <TableCell>Summary</TableCell>
            <TableCell>Key Points</TableCell>
            <TableCell>Sentences</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {topics.map((topic) => (
            <TopicRow
              key={topic.topicNumber}
              topic={topic}
              onTopicNameChange={onTopicNameChange}
              onSummaryChange={onSummaryChange}
              onKeyPointChange={onKeyPointChange}
              onKeyPointTopicNumberChange={onKeyPointTopicNumberChange}
              moveSentence={moveSentence}
              sortSentencesInRow={sortSentencesInRow}
              reorderSentencesInRow={reorderSentencesInRow}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const TopicRow: React.FC<{
  topic: Topic;
  onTopicNameChange: (topicId: number, newName: string) => void;
  onSummaryChange: (topicId: number, newSummary: string) => void;
  onKeyPointChange: (topicNumber: number, index: number, newKeyPoint: string) => void;
  onKeyPointTopicNumberChange: (topicNumber: number, index: number, newTopicNumber: number) => void;
  moveSentence: (sentenceId: string, targetTopicNumber: number) => void;
  sortSentencesInRow: (topicNumber: number) => void;
  reorderSentencesInRow: (topicNumber: number, dragIndex: number, hoverIndex: number) => void;
}> = ({
  topic,
  onTopicNameChange,
  onSummaryChange,
  onKeyPointChange,
  onKeyPointTopicNumberChange,
  moveSentence,
  sortSentencesInRow,
  reorderSentencesInRow
}) => {
    const [{ isOver }, drop] = useDrop({
      accept: 'sentence',
      drop: (item: { id: string }) => moveSentence(item.id, topic.topicNumber),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    });

    return (
      <TableRow ref={drop} style={{ backgroundColor: isOver ? '#f0f0f0' : 'inherit' }} sx={{ fontSize: '12px' }}>
        <TableCell sx={{ verticalAlign: 'top' }}>{topic.topicNumber}</TableCell>
        <TableCell sx={{ verticalAlign: 'top' }}>{convertTimeToMinutes(topic.startTopicTime)}</TableCell>
        <TableCell sx={{ verticalAlign: 'top' }}>{convertTimeToMinutes(topic.endTopicTime)}</TableCell>
        <TableCell sx={{ verticalAlign: 'top' }}>
          <TextField
            value={topic.topic}
            onChange={(e) => onTopicNameChange(topic.topicNumber, e.target.value)}
            multiline
            sx={{ width: '100px' }}
            InputProps={{ style: { fontSize: '9px' } }}
          />
        </TableCell>
        <TableCell sx={{ verticalAlign: 'top' }}>
          <TextField
            value={topic.summary}
            onChange={(e) => onSummaryChange(topic.topicNumber, e.target.value)}
            multiline
            sx={{ width: '300px' }}
            InputProps={{ style: { fontSize: '9px' } }}
          />
        </TableCell>
        <TableCell sx={{ verticalAlign: 'top' }}>
          {topic.keyPoints.map((kp, index) => (
            <Box key={index} sx={{ display: 'inline' }}>
              <TextField
                value={kp.keyPoint}
                onChange={(e) =>
                  onKeyPointChange(topic.topicNumber, index, e.target.value)
                }
                multiline
                sx={{ width: '300px' }}
                InputProps={{ style: { fontSize: '9px' } }}
              />
              <TextField
                value={kp.topicNumber}
                onChange={(e) =>
                  onKeyPointTopicNumberChange(topic.topicNumber, index, parseInt(e.target.value, 10))
                }
                sx={{ width: '50px' }}
                InputProps={{ style: { fontSize: '9px' } }}
              />
            </Box>
          ))}
        </TableCell>
        <TableCell sx={{ verticalAlign: 'top', }}>
          {topic.Sentence && topic.Sentence.map((sentence, index) => (
            <SentenceBox
              key={sentence.id}
              index={index}
              sentence={sentence}
              topicNumber={topic.topicNumber}
              reorderSentencesInRow={reorderSentencesInRow}
            />
          ))}
        </TableCell>
        <TableCell>
          <Button variant="contained" size="small" onClick={() => sortSentencesInRow(topic.topicNumber)}>
            Sort Sentences
          </Button>
        </TableCell>
      </TableRow>
    );
  };

const SentenceBox: React.FC<{ sentence: any, index: number, topicNumber: number, reorderSentencesInRow: (topicNumber: number, dragIndex: number, hoverIndex: number) => void }> = ({ sentence, index, topicNumber, reorderSentencesInRow }) => {
  const [, drag] = useDrag({
    type: 'sentence',
    item: { id: sentence.id, index },
  });

  const [, drop] = useDrop({
    accept: 'sentence',
    hover: (item: { id: string, index: number }) => {
      if (item.index !== index) {
        reorderSentencesInRow(topicNumber, item.index, index);
        item.index = index;
      }
    },
  });

  return (
    <Box ref={(node: any) => drag(drop(node))} sx={{ marginRight: '60px', marginBottom: 1, padding: 1, border: '1px solid #ccc', fontSize: '10px', width: '200px' }}>
      <div>{`Start: ${convertTimeToMinutes(sentence.timeStart)} mins, End: ${convertTimeToMinutes(sentence.timeEnd)} mins`}</div>
      <div>{sentence.sentence}</div>
    </Box>
  );
};

export default TableOfContentData;
