import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { checkAuthenticated } from './api/serverApis/userApi';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  // const [isAuthChecked, setIsAuthChecked] = useState(false);
  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthChecked, setIsAuthChecked] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  const authenticate = async () => {
    try {
      const response = await checkAuthenticated(); // Assuming this function exists and returns a promise
      if (response.data.data === 'success') {
        setIsAuthenticated(true);
      } else {
        setIsAuthChecked(true);
        setIsAuthenticated(false);
      }
    } catch (error) {
      console.error(error);
      setIsAuthChecked(true);
      setIsAuthenticated(false);
    }
    setIsAuthChecked(true);
  };

  // useEffect(() => {


  //   authenticate();
  // }, []);
  authenticate();

  if (!isAuthChecked) {
    // Optionally render a loading indicator while checking auth
    return <div>Checking authentication...</div>;
  }

  if (!isAuthenticated) {
    // Redirect to login page if not authenticated
    return <Navigate to="/login" replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;