

import axiosInstance from "../interceptor";
import { CHATGPT_API_URL, GATEWAY_API_URL } from "../api";

const api = '/api/playlistService/api/v1/playlist';



export const saveMovieToPlaylist = async (playlist: any) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${api}/saveMovieToPlaylist`, { playlist: playlist });
}
export const getPlaylist = async (playlistId: string, courseId: string) => {
  return axiosInstance.get(`${GATEWAY_API_URL}${api}/getPlaylist/${playlistId}/${courseId}`);
}
export const deleteSemanticSearchFromPlaylist = async (playlistId: string, courseId: string, _id: string) => {
  return axiosInstance.delete(`${GATEWAY_API_URL}${api}/deleteSemanticSearchFromPlaylist/${playlistId}/${courseId}/${_id}`);
}
